import React from 'react';
// import Nav from 'components/Nav';
// import './index.css';

const IE = () => (
    <div className="ie-detect container-fixed">
        {/* <Nav className="dark-light" /> */}
        <div className="container">
            <div className="section section1">
                <i
                    style={{ color: 'red', fontSize: 70 }}
                    className="fal fa-exclamation-triangle"
                />

                {/* <i class="fal fa-exclamation-triangle" /> */}
            </div>
            <div className="section section2">
                <h2 className="h2">Error</h2>
            </div>
            <div className="section section3">
                <h3
                    className="h3"
                    style={{ textAlign: 'center', lineHeight: '26px' }}
                >
                    해당 브라우저를 지원하지 않습니다.
                    <br />
                    <br />
                    인터넷 익스플로러 10이상 또는
                    <br />
                    크롬을 사용해주십시오.
                </h3>
            </div>
        </div>
    </div>
);

export default IE;
