import React from 'react';
import { Link } from 'react-router-dom';
import Nav from 'components/Nav';
// import './index.css';

const NotFound = () => (
    <div className="not-found container-fixed">
        <Nav className="dark-light" />
        <div className="container">
            <div className="section section1">
                <i
                    style={{ color: 'red', fontSize: 70 }}
                    className="fal fa-exclamation-triangle"
                />

                {/* <i class="fal fa-exclamation-triangle" /> */}
            </div>
            <div className="section section2">
                <h2 className="h2">404</h2>
            </div>
            <div className="section section3">
                <h3 className="h3">페이지를 찾을 수 없습니다.</h3>
            </div>
            <Link to="/">
                <button className="btn block basic">돌아가기</button>
            </Link>
        </div>
    </div>
);

export default NotFound;
