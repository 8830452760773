import ExpiredStorage from 'expired-storage';

const expiredStorage = new ExpiredStorage(sessionStorage);

export function getItem(key) {
    // return sessionStorage.getItem(key);
    return expiredStorage.getItem(key);
}

export function setItem(key, data, expiration) {
    // sessionStorage.setItem(
    //     key,
    //     typeof data === 'string' ? data : JSON.stringify(data),
    // );

    expiredStorage.setItem(
        key,
        typeof data === 'string' ? data : JSON.stringify(data),
        expiration * 60,
    ); // expiration은 second
}

export function removeItem(key) {
    expiredStorage.removeItem(key);
    // sessionStorage.removeItem(key);
}
