import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Sentry from '@sentry/browser';
import Formsy, { addValidationRule } from 'formsy-react';
import { EMAIL_CHECK, EMAIL_CHECK_URL } from 'constants/paths';
// redux
import { apiRequest } from 'redux/actions/api/utils';
import authActions from 'redux/actions/auth';
// sub components
import Input from 'components/Input';
// utils
import { hasNoSpace, hasNumber, hasLetter } from 'utils/validate';
import { getItem } from 'utils/handleStorage';
// custom validations
addValidationRule('hasNoSpace', hasNoSpace);
addValidationRule('hasNumber', hasNumber);
addValidationRule('hasLetter', hasLetter);

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            canSubmit: false,
            isPristine: true,
            isEmailFound: undefined,
        };

        this.formRef = React.createRef();

        Sentry.withScope(scope => {
            scope.setExtra('Z2Token', getItem('Z2Token'));
            scope.setExtra('tempData', getItem('Z2TempData'));
            Sentry.captureMessage('Login');
        });
    }

    componentWillMount() {
        if (getItem('Z2Token')) {
            this.props.history.replace('/my-page');
        }
    }

    handleSubmit = data => {
        const { email, password } = data;
        this.props.login({ email, password, redirect: true }).catch(e => {
            Z2.log('ee', e);
            // this.setState({
            //     hasError: true,
            // });
        });
    };

    goNext = () => {
        this.props.history.push('/product/register-1');
    };

    enableButton = () => {
        this.setState({ canSubmit: true });
    };

    disableButton = () => {
        this.setState({ canSubmit: false });
    };

    notifyFormError = data => {
        Z2.log('Form error:', data);
    };

    handleFormChange = currentValues => {
        this.setState({
            isPristine: false,
            isEmailFound: undefined,
            email: currentValues.email,
        });
    };

    openModal = modalName => {
        this.setState({
            [`is${modalName}Open`]: !this.state[`is${modalName}Open`],
        });
    };

    closeModal = modalName => {
        return () => {
            this.setState({
                [`is${modalName}Open`]: !this.state[`is${modalName}Open`],
            });
        };
    };

    closeAuthModal = () => {
        this.closeModal('AuthModal');
    };

    checkEmail = () => {
        if (!this.emailRef.props.isValid()) return;
        this.props
            .apiRequest({
                type: EMAIL_CHECK,
                path: EMAIL_CHECK_URL,
                data: {
                    email: this.state.email,
                },
            })
            .then(response => {
                this.setState({
                    isEmailFound: response && response.code ? true : false,
                });
            })
            .catch(e => {});
    };
    render() {
        const { isPristine, canSubmit } = this.state;

        return (
            <div style={{ margin: '0 auto' }}>
                <Formsy
                    onValidSubmit={this.handleSubmit}
                    onValid={this.enableButton}
                    onInvalid={this.disableButton}
                    onInvalidSubmit={this.notifyFormError}
                    onChange={this.handleFormChange}
                    className={`form login-form ${
                        isPristine ? 'is-pristine' : ''
                    }`}
                    ref={this.formRef}
                >
                    {' '}
                    <h2 className="h2">직토에 오신 것을 환영합니다.</h2>
                    <div style={{ marginBottom: 30 }}>
                        이메일 또는 휴대폰 번호로 로그인 해주세요.
                        <br />
                        <b>(더챌린지 회원 로그인 가능)</b>
                    </div>
                    <Input
                        required
                        name="email"
                        title="이메일"
                        validations="isEmail"
                        validationError="정확한 이메일 주소를 입력하세요."
                        className="inline"
                        inputClassName="block"
                        placeholder="이메일 주소를 입력하세요."
                        onBlur={this.checkEmail}
                        innerRef={c => {
                            this.emailRef = c;
                        }}
                    />
                    {/* {this.state.isEmailFound === true && (
                            <div
                                style={{
                                    textAlign: 'right',
                                    fontSize: 10,
                                    marginTop: -15,
                                    marginBottom: 10,
                                    color: '#000',
                                }}
                            >
                                더챌린지 회원이십니다.
                            </div>
                        )}
                        {this.state.isEmailFound === false && (
                            <div
                                style={{
                                    textAlign: 'right',
                                    fontSize: 10,
                                    marginTop: -15,
                                    marginBottom: 10,
                                    color: '#000',
                                }}
                            >
                                가입가능한 이메일 주소입니다.
                            </div>
                        )} */}
                    <Input
                        required
                        type="password"
                        name="password"
                        title="비밀번호"
                        validations="minLength:8,maxLength:35,hasNumber,hasLetter,hasNoSpace"
                        validationError="8-35자의 문자,숫자를 조합하여 설정하세요."
                        validationErrors={{
                            hasNoSpace: '공백없이 입력하세요.',
                        }}
                        className="inline mb-25"
                        inputClassName="block"
                        placeholder="비밀번호를 입력하세요."
                    />
                    <Link to="/password-reset" className="block">
                        <button
                            type="button"
                            className="btn block footnote underline text-right pr-0"
                            style={{ marginTop: 20 }}
                        >
                            비밀번호 재설정
                        </button>
                    </Link>
                    <div style={{ marginTop: 30, marginBottom: 20 }}>
                        <button
                            type="submit"
                            className="btn block basic"
                            disabled={!canSubmit}
                        >
                            로그인
                        </button>
                    </div>
                    <Link to="/mobile-phone-login">
                        <button type="button" className="btn outline block">
                            휴대폰 번호 로그인
                        </button>
                    </Link>
                    <div className="login-footer">
                        <div className="txt">
                            <div>
                                트래블포인트를
                                <br />
                                적립하신 적이 있나요?
                            </div>
                        </div>
                        <Link
                            to="/my-travel-point"
                            style={{ flex: 'none', width: 175 }}
                        >
                            <button type="button" className="btn outline block">
                                트래블포인트 확인
                            </button>
                        </Link>
                    </div>
                    <div
                        className="login-footer"
                        style={{ marginTop: 0, border: 0 }}
                    >
                        <div className="txt">
                            <div>처음 오셨나요?</div>
                        </div>
                        <Link to="/signup" style={{ flex: 'none', width: 175 }}>
                            <button type="button" className="btn outline block">
                                간편 회원가입
                            </button>
                        </Link>
                    </div>
                </Formsy>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    tempData: state.temp,
});

const mapDispatchToProps = dispatch => ({
    apiRequest: bindActionCreators(apiRequest, dispatch),
    login: bindActionCreators(authActions.login, dispatch),
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Login),
);
