import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import createRootReducer from 'redux/reducers';

export const history = createBrowserHistory();

export default function configureStore(preloadedState) {
    const composeEnhancer =
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const store = createStore(
        createRootReducer(history), // root reducer with router state
        preloadedState,
        process.env.REACT_APP_ENV === 'test'
            ? composeEnhancer(
                  applyMiddleware(routerMiddleware(history), thunkMiddleware),
              )
            : compose(
                  applyMiddleware(routerMiddleware(history), thunkMiddleware),
              ),
    );

    return store;
}
