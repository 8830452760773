import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
// sub components
import Modal from 'components/Modal';
// utils
import {
    formatYYYY_MM_DD,
    formatYYYY_MM_DD_HH,
    formatToLocalString,
} from 'utils/format';

class InsuranceDetail extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isCancelConfirmModalOpen: false,
            isValidCancelTime: this.validateCancelTime(),
        };
    }

    validateCancelTime() {
        // 보험개시 15분전부터 가입해지가 불가.
        const startDateStr = `${formatYYYY_MM_DD(
            this.props.startDate,
        )} ${this.props.startDate.substring(8, 10)}:00`;
        const _15MinBeforeStart = moment(startDateStr).subtract(15, 'minutes');
        return !moment().isAfter(_15MinBeforeStart);
    }

    renderCancelConfirmModal = () => {
        return (
            <div>
                <div className="header">보험 가입 해지 신청 완료</div>
                <div className="contents">
                    <div className="mb-20">
                        보험 가입 해지 신청이 완료되었습니다.
                        <br />
                        해지 환급금 확인 후 결제하신 카드로 환불처리됩니다.
                    </div>
                    <div className="mb-20">이용해주셔서 감사합니다.</div>
                </div>
            </div>
        );
    };

    render() {
        console.log('isValidCancelTime', this.state.isValidCancelTime);
        return (
            <div className="sheet0" style={{ marginBottom: 20 }}>
                <div className="flex">
                    <div
                        className="flex"
                        style={{
                            width: 70,
                        }}
                    >
                        <b>보험신청</b>
                    </div>
                    <div className="flex flex-1 justify-content-flex-end align-items-center">
                        {moment(this.props.contactDate).format(
                            'YYYY-MM-DD HH:mm',
                        )}
                    </div>
                </div>
                <div className="flex">
                    <div className="flex" style={{ width: 70 }}>
                        <b>보험기간</b>
                    </div>
                    <div className="flex flex-1 flex-column justify-content-center  align-items-flex-end">
                        <div>
                            {formatYYYY_MM_DD_HH(this.props.startDate)}시 부터
                        </div>
                        <div>
                            {formatYYYY_MM_DD_HH(this.props.endDate)}시 까지
                        </div>
                    </div>
                </div>
                <div className="flex flex-column">
                    <div className="flex">
                        <div className="flex" style={{ width: 70 }}>
                            <b>가입자</b>
                        </div>
                        <div className="flex flex-1 justify-content-flex-end align-items-center">
                            {this.props.name} (
                            {this.props.birthday.substring(2)})
                        </div>
                    </div>
                    {this.props.companions.map((c, i) => (
                        <div className="flex" key={`c-${i}`}>
                            <div
                                className="flex"
                                style={{
                                    width: 70,
                                }}
                            >
                                <b />
                            </div>
                            <div className="flex flex-1 justify-content-flex-end align-items-center">
                                {c.name} ({c.birthday.substring(2)})
                            </div>
                        </div>
                    ))}
                </div>
                {this.props.phoneNumber && (
                    <div className="flex">
                        <div className="flex" style={{ width: 70 }}>
                            <b>휴대폰 번호</b>
                        </div>
                        <div className="flex flex-1 justify-content-flex-end align-items-center">
                            {this.props.phoneNumber}
                        </div>
                    </div>
                )}
                {this.props.email && (
                    <div className="flex">
                        <div className="flex" style={{ width: 70 }}>
                            <b>이메일</b>
                        </div>
                        <div className="flex flex-1 justify-content-flex-end align-items-center">
                            {this.props.email}
                        </div>
                    </div>
                )}
                <div className="flex">
                    <div className="flex" style={{ width: 70 }}>
                        <b>보장 플랜</b>
                    </div>
                    <div className="flex flex-1 justify-content-flex-end align-items-center">
                        {this.props.type === 'standard'
                            ? '스탠다드'
                            : '프리미엄'}
                    </div>
                </div>
                <div className="flex" style={{ marginBottom: 0 }}>
                    <div className="flex" style={{ width: 70 }}>
                        <b>결제 보험료</b>
                    </div>
                    <div className="flex flex-1 justify-content-flex-end align-items-center">
                        {formatToLocalString(this.props.price)} 원
                    </div>
                </div>
                <div className="top-line">
                    <div className="flex" style={{ marginBottom: 10 }}>
                        <div className="flex" style={{ width: 70 }}>
                            <b>진행 상태</b>
                        </div>
                        <div className="flex flex-1 justify-content-flex-end align-items-center">
                            <b className="highlight2">
                                {this.props.paymentStatus &&
                                    this.props.paymentStatus.indexOf('환불') >
                                        -1 && (
                                        <span>{this.props.paymentStatus}</span>
                                    )}
                                {(!this.props.paymentStatus ||
                                    (this.props.paymentStatus &&
                                        this.props.paymentStatus.indexOf(
                                            '환불',
                                        ) < 0)) && (
                                    <span>{this.props.status}</span>
                                )}
                            </b>
                        </div>
                    </div>
                    <div className="flex" style={{ marginBottom: 0 }}>
                        <div className="flex" style={{ width: 70 }}>
                            {this.props.cancelableNo &&
                                this.state.isValidCancelTime &&
                                this.props.cancelableStatus === '가입완료' &&
                                this.props.paymentStatus === '결제완료' && (
                                    <button
                                        className="btn"
                                        style={{
                                            fontSize: 12,
                                            color: '#9c9c9c',
                                            paddingLeft: 0,
                                            textDecoration: 'underline',
                                        }}
                                        onClick={this.props.onCancelStart}
                                    >
                                        가입해지
                                    </button>
                                )}
                        </div>
                        <div className="flex flex-1 justify-content-flex-end align-items-center">
                            <b className="footnote sm text-right">
                                보장내용, 보험금 청구 문의
                                <br />
                                AIG손해보험 고객센터 1544-2792
                            </b>
                        </div>
                    </div>
                </div>

                {this.state.isCancelConfirmModalOpen && (
                    <Modal
                        content={this.renderCancelConfirmModal()}
                        onDismiss={() => {
                            this.setState({ isCancelConfirmModalOpen: false });
                        }}
                    />
                )}
            </div>
        );
    }
}

export default InsuranceDetail;

InsuranceDetail.propTypes = {
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    name: PropTypes.string,
    birthday: PropTypes.string,
    type: PropTypes.string,
    status: PropTypes.string,
    price: PropTypes.number,
    onCancelStart: PropTypes.func,
    companions: PropTypes.array,
    cancelableStatus: PropTypes.string,
    cancelableNo: PropTypes.string,
};

InsuranceDetail.defaultProps = {
    startDate: '',
    endDate: '',
    name: '',
    birthday: '',
    type: 'standard',
    status: '',
    price: 0,
    onCancelStart: () => {},
    companions: [],
    cancelableStatus: '',
    cancelableNo: '',
};
