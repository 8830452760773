import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import queryString from 'query-string';
import { isNil } from 'lodash';
import Formsy, { addValidationRule } from 'formsy-react';
import qs from 'qs';
// constants
import {
    ZIKTO_TERMS_PDF_URL,
    EASY_SIGNUP,
    EASY_SIGNUP_URL,
    USER_TRAVEL_POINT,
    USER_TRAVEL_POINT_URL,
} from 'constants/paths';
// redux
import { apiRequest } from 'redux/actions/api/utils';
import authActions from 'redux/actions/auth';
import tempActions from 'redux/actions/temp';
// utils
import { isMobile } from 'utils/validate';
import { errorHandler } from 'utils/errorHandler';
import { getItem } from 'utils/handleStorage';
// sub component
import Nav from 'components/Nav';
import PhoneVerification from 'components/PhoneVerification/Number';
import Input from 'components/Input';
import Checkbox from 'components/Checkbox';
import Modal from 'components/Modal';
import Axios from 'axios';
// custom validations
addValidationRule('isMobile', isMobile);

class TravelPointAIGSave extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            phoneNumber: '',
            isAuthCodeConfirmed: false,
            code: '',
            codeErrorMessage: false,
            isPristine: true,
            travelPoint: '0',
            checkSumValidCoupon: false,
        };
        this.formRef = React.createRef();

        this.qs = qs.parse(window.location.search, {
            ignoreQueryPrefix: true,
        });
        this.couponId = this.qs.coupon_id;

        console.log('this.couponId', this.couponId, this.travelPoint);
    }

    componentDidMount() {
        const { coupon } = queryString.parse(this.props.location.search);

        if (!isNil(coupon)) {
            this.setState({
                code: coupon,
            });
        }
    }

    handleChange = keyName => {
        return value => {
            this.setState({
                [keyName]: value,
            });
            this.formRef.current.validateForm();
        };
    };

    openModal = modalName => {
        this.setState({
            [`is${modalName}Open`]: !this.state[`is${modalName}Open`],
        });
    };

    async handleTravelPointSaveUp(e) {
        const params = {
            couponId: this.state.code,
            amount: this.state.travelPoint,
        };
        try {
            await this.props.apiRequest({
                path: '/api/travelPoint/member/saveup',
                method: 'post',
                data: params,
            });
        } catch (e) {
            console.log(e);
        }
        return this.props.history.replace('/purchase/step1');
    }

    handleFormChange = currentValues => {
        console.log(currentValues.code);
        this.setState(
            {
                isPristine: false,
                phoneNumber: currentValues.phoneNumber,
                agreeTerms: currentValues.agreeTerms,
                code: currentValues.code,
            },
            () => {
                this.verifyCode();
                //     !currentValues.agreeTerms && this.openPdf();
            },
        );
    };

    openPdf = () => {
        window.open(ZIKTO_TERMS_PDF_URL, '_blank');
    };

    verifyCode = () => {
        // 코드 검증
        this.setState({ codeErrorMessage: false });

        this.props
            .apiRequest({
                path: `api/insurance/product/travelAIG/coupon/${this.state.code}`,
                method: 'get',
            })
            .then(response => {
                console.log(response.couponResult);
                const couponAmount = response.couponResult.amount;
                if (response.couponResult && response.couponUsed === 0) {
                    if (response.couponResult.checkSumValid) {
                        this.setState({
                            travelPoint: response.couponResult.amount,
                            checkSumValidCoupon: true,
                        });
                    } else {
                        this.setState({
                            codeErrorMessage: true,
                            checkSumValidCoupon: false,
                        });
                    }
                } else {
                    this.setState({
                        codeErrorMessage: true,
                        checkSumValidCoupon: false,
                    });
                }
            })
            .catch(e => {
                this.setState({
                    codeErrorMessage: true,
                    checkSumValidCoupon: false,
                });
            });
    };

    handlePhoneAuthFail = (phoneNumber, authNumber) => {
        this.setState(
            {
                // phoneAuthModalOpen: true,
                isAuthCodeConfirmed: true,
            },
            () => {
                this.props.saveTempData({ phoneNumber });
                this.handleEasySignup(phoneNumber, authNumber);
            },
        );
    };

    handleEasySignup = async (phoneNumber, authCodeNumber) => {
        if (this.props.auth.level) return;
        const params = {
            phoneNumber,
            code: authCodeNumber, // 인증번호
        };
        if (localStorage.getItem('development_secret')) {
            params.env = localStorage.getItem('development_secret');
        }

        try {
            await this.props
                .apiRequest({
                    type: EASY_SIGNUP,
                    path: EASY_SIGNUP_URL,
                    data: params,
                    config: {
                        headers: {
                            Authorization: null,
                        },
                    },
                })
                .then(response => {
                    if (response.code !== 200)
                        return Promise.reject(new Error(response.message));
                    // 성공시
                    this.setState(
                        {
                            hasAuth: true,
                        },
                        () => {
                            this.props.simpleLogin(response.accessToken, 1);
                        },
                    );
                })
                .catch(e => {
                    this.setState({
                        isErrorModalOpen: true,
                        errorMessage: e.message,
                    });
                    return Promise.reject(e);
                });
        } catch (e) {
            errorHandler(e, EASY_SIGNUP_URL, this.props.tempData.userId);
        }
    };

    handlePhoneAuthSuccess = (phoneNumber, authLevel) => {
        if (authLevel === 1 || authLevel === 2) {
            this.fetchUserInfo();
        }
        if (authLevel) {
            return this.setState(
                {
                    hasAuth: true,
                },
                () => {
                    this.props.saveTempData({ phoneNumber });
                    return this.fetchTravelPoint(phoneNumber);
                },
            );
        }

        this.setState({
            phoneAuthModalOpen: true,
            hasAuth: true,
            isEmailFound: undefined,
        });
    };

    fetchTravelPoint = async phoneNumber => {
        try {
            await this.props
                .apiRequest({
                    type: USER_TRAVEL_POINT,
                    path: USER_TRAVEL_POINT_URL,
                    method: 'get',
                })
                .then(response => {
                    this.props.saveTempData({
                        travelPoint: Number(
                            response && response.code === 200
                                ? response.balance
                                : 0,
                        ),
                    });
                    this._mounted &&
                        this.setState({
                            isWelcomeModalOpen: true,
                        });
                })
                .catch(e => {
                    return Promise.reject(e);
                });
        } catch (e) {
            errorHandler(e, USER_TRAVEL_POINT_URL, this.props.tempData.userId);
        }
    };

    renderAuthCodeGetModal = () => {
        return (
            <div>
                <div className="header">인증번호가 전송되었습니다.</div>
                <div className="contents">
                    <div style={{ marginBottom: 20 }}>
                        입력하신 번호로 인증번호가 전송되었습니다.
                        <br />
                        받으신 인증번호를 입력해주세요.
                    </div>
                    <div style={{ marginBottom: 20 }}>
                        인증번호를 받지 못하셨다면 <br />
                        휴대폰 번호를 확인 후 다시 입력해주세요.
                    </div>
                </div>
            </div>
        );
    };

    renderAuthCodeConfirmModal = phoneNumber => {
        const user = phoneNumber.slice(-4);
        return (
            <div>
                <div className="header">휴대폰 번호 인증 성공</div>
                <div className="contents">
                    <div style={{ marginBottom: 20 }}>
                        포인트 적립이 완료되었습니다.
                    </div>
                    <div
                        style={{
                            marginBottom: 20,
                            width: 500,
                            paddingTop: 20,
                            marginLeft: '-30px',
                            backgroundColor: '#f4f3f0',
                        }}
                    >
                        <div style={{ marginBottom: 10 }}>
                            {user}님의 트래블 포인트
                        </div>
                        <div style={{ paddingBottom: 20 }}>
                            <b className="digit" style={{ fontSize: 20 }}>
                                {this.state.travelPoint.toLocaleString()}
                            </b>{' '}
                            Point
                        </div>
                    </div>
                    <div>
                        확인버튼을 누르시면, 해외여행보험 페이지로 이동합니다.
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const {
            phoneNumber,
            isAuthCodeConfirmed,
            isPristine,
            codeErrorMessage,
            agreeTerms,
            code,
            checkSumValidCoupon,
        } = this.state;

        return (
            <div className="product travel-point-save container-fixed">
                <Nav className="dark-light" />
                <div className="mobile-title">
                    <span>트래블포인트</span>
                </div>
                <div className="container">
                    <div className="page-header">
                        <h1>트래블포인트 적립</h1>
                    </div>
                    <div className="wrap">
                        <div className="wrap-area">
                            <div className="section">
                                <div className="content">
                                    <h2 className="h2">
                                        AIG 해외여행보험에서
                                        <br /> 결제하신 금액의 10%를
                                        <br />
                                        트래블포인트로 적립하세요.
                                    </h2>
                                    <div
                                        style={{
                                            marginTop: 20,
                                            marginBottom: 30,
                                        }}
                                    >
                                        간편한 휴대폰 번호 인증으로 적립이
                                        가능합니다.
                                    </div>
                                </div>
                            </div>
                            <div
                                className="section section-colored"
                                style={{ marginBottom: 30 }}
                            >
                                <div
                                    className="content"
                                    style={{ textAlign: 'center' }}
                                >
                                    <b>적립 예정 포인트</b>
                                    <div style={{ marginTop: 10 }}>
                                        <b className="digit">
                                            {this.state.travelPoint.toLocaleString()}
                                        </b>
                                        <span
                                            className="unit"
                                            style={{ marginLeft: 5 }}
                                        >
                                            Point
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <Formsy
                                style={{ width: 335, margin: '0 auto' }}
                                className={`form ${
                                    isPristine ? 'is-pristine' : ''
                                }`}
                                // onValidSubmit={this.handleSubmit}
                                // onValid={this.enableButton}
                                // onInvalid={this.disableButton}
                                // onInvalidSubmit={this.notifyFormError}
                                onChange={this.handleFormChange}
                                // className="form signup-form"
                                ref={this.formRef}
                            >
                                <div>
                                    <Input
                                        required
                                        name="code"
                                        title="입력 코드"
                                        validations="isAlphanumeric"
                                        validationError="유효하지 않은 코드 입니다."
                                        inputClassName="block"
                                        error={this.state.codeErrorMessage}
                                        value={this.state.code}
                                        disabled={
                                            this.state.checkSumValidCoupon
                                        }
                                    />
                                </div>
                                {this.state.checkSumValidCoupon && (
                                    <div>
                                        <div className="top-line terms-agree">
                                            <button
                                                type="button"
                                                className="btn"
                                                style={{
                                                    padding: 0,
                                                    textAlign: 'left',
                                                }}
                                                onClick={this.openPdf}
                                            >
                                                <span className="txt">
                                                    서비스 이용약관 동의
                                                    <br />
                                                    <span
                                                        style={{
                                                            fontWeight: 400,
                                                            fontSize: 12,
                                                        }}
                                                    >
                                                        (알림 수신 동의 포함)
                                                    </span>
                                                    <i
                                                        className="fal fa-chevron-right"
                                                        style={{
                                                            paddingLeft: 40,
                                                            verticalAlign:
                                                                'middle',
                                                            marginTop: -15,
                                                        }}
                                                    />
                                                </span>
                                            </button>

                                            <Checkbox
                                                name="agreeTerms"
                                                value={this.state.agreeTerms}
                                                required
                                                className="term-checkbox"
                                                validationError="서비스 이용약관에 동의해주세요."
                                                innerRef={c => {
                                                    this.checkboxRef = c;
                                                }}
                                            />
                                        </div>
                                        <div className="top-line">
                                            <PhoneVerification
                                                onFail={
                                                    this.handlePhoneAuthFail
                                                }
                                                onSuccess={
                                                    this.handlePhoneAuthSuccess
                                                }
                                            />
                                        </div>
                                        <div
                                            className="footnote"
                                            style={{ marginTop: 5 }}
                                        >
                                            적립된 포인트는 양도가 불가능합니다.
                                        </div>
                                    </div>
                                )}
                            </Formsy>
                        </div>
                    </div>
                </div>

                {this.state.isAuthCodeConfirmed && (
                    <Modal
                        content={this.renderAuthCodeConfirmModal(phoneNumber)}
                        onDismiss={this.handleTravelPointSaveUp.bind(this)}
                        style={{ width: 500, height: 344 }}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    api: state.api,
});

const mapDispatchToProps = dispatch => ({
    apiRequest: bindActionCreators(apiRequest, dispatch),
    saveTempData: bindActionCreators(tempActions.saveTempData, dispatch),
    simpleLogin: bindActionCreators(authActions.simpleLogin, dispatch),
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(TravelPointAIGSave),
);
