import React, { Component } from 'react';
import Nav from 'components/Nav';
import { IS_MOBILE } from 'constants/device';

class Home extends Component {
    constructor() {
        super();

        this.state = {
            hover: false,
        };
    }
    mouseOver() {
        this.setState({ hover: true });
    }
    mouseOut() {
        this.setState({ hover: false });
    }

    render() {
        const dummy = {
            products: [
                {
                    desc: 'COVID-19',
                },
                {
                    desc: '해외여행보험',
                },
                {
                    desc: '비행기지연 보상서비스',
                },
                {
                  desc: '활동량 연동 보험',
                },
                {
                  desc: '날씨 보상서비스',
                },
            ],
        };
        const businessList = {
            products: [
                {
                    desc: 'COVID-19',
                },
                {
                    desc: '해외여행보험',
                },
                {
                    desc: '비행기지연 보상서비스',
                },
                {
                  desc: '활동량 연동 보험',
                },
                {
                  desc: '날씨 보상서비스',
                },
            ],
        };
        return (
            <div className="home container-fixed">
                <Nav className="dark-light" />
                <div className="container">
                    <header className="header">
                        세계의 리스크로 부상한 기후변화와 바이러스
                        <b>블록체인과 AI로 위험을 헷징</b>
                        <h1 className="h2">직토는 준비되어 있습니다.</h1>
                    </header>
                    <div className="flex flex-row links">
                        <ul>
                            <li className="link-area">
                                <a
                                    onMouseOver={this.mouseOver.bind(this)}
                                    onMouseOut={this.mouseOut.bind(this)}
                                    href="/personal-product"
                                    className="link flex flex-row"
                                    style={{ marginLeft: 0 }}
                                >
                                    개인 보험
                                </a>
                                {this.state.hover && !IS_MOBILE && (
                                    <ul className="hover-area">
                                        {dummy.products.map((el, i) => (
                                            <li key={`products-${i}`}>
                                                <b>{el.desc}</b>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </li>
                            <li className="link-area">
                                <a href="/business-product" className="link">
                                    기업 보험
                                </a>
                            </li>
                            <li className="link-area">
                                <a href="/About" className="link">
                                    Contact
                                </a>
                            </li>
                        </ul>
                        {/* <a href="/change" className="link">
              공항 환전
            </a> */}
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;
