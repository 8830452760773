import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Formsy from 'formsy-react';
// constants
import {
    PASSWORD_RESET_REQUEST,
    PASSWORD_RESET_REQUEST_URL,
} from 'constants/paths';
// redux
import { apiRequest } from 'redux/actions/api/utils';
// sub components
import Modal from 'components/Modal';
import Nav from 'components/Nav';
import Input from 'components/Input';
import ErrorModal from 'components/ErrorModal';
// utils
import { errorHandler } from 'utils/errorHandler';

class PasswordReset extends Component {
    constructor(props) {
        super(props);

        this.state = {
            canSubmit: false,
            isPristine: true,
            isModalOpen: false,
            hasError: false,
            isDone: false,
        };

        this.formRef = React.createRef();
    }

    resetForm = () => {
        this.setState({ hasError: false }, () => {
            this.formRef.current.reset();
        });
    };

    handleSubmit = async data => {
        try {
            await this.props
                .apiRequest({
                    type: PASSWORD_RESET_REQUEST,
                    path: PASSWORD_RESET_REQUEST_URL,
                    data: {
                        email: data.email,
                        from: 'web',
                    },
                    config: {
                        headers: {
                            Authorization: null,
                        },
                    },
                })
                .then(response => {
                    if (response.code !== 200) return;
                    this.toggleModal();
                })
                .catch(e => {
                    this.setState({
                        hasError: true,
                    });
                    return Promise.reject(e);
                });
        } catch (e) {
            errorHandler(
                e,
                PASSWORD_RESET_REQUEST_URL,
                this.props.tempData.userId,
            );
        }
    };

    handleFormChange = currentValues => {
        // 휴대폰 번호가 유효하면 인증번호 받기 버튼 활성화
        this.setState({
            isPristine: false,
        });
    };

    enableButton = () => {
        this.setState({ canSubmit: true });
    };

    disableButton = () => {
        this.setState({ canSubmit: false });
    };

    notifyFormError = data => {
        Z2.log('Form error:', data);
    };

    toggleModal = () => {
        this.setState({
            isModalOpen: !this.state.isModalOpen,
        });
    };

    onReset = () => {
        this.setState({
            isModalOpen: false,
            isDone: true,
        });
    };

    renderModal = () => {
        return (
            <div>
                <div className="header">
                    비밀번호 재설정 메일이 <br />
                    발송되었습니다.
                </div>
                <div className="contents">
                    <div>잠시후 메일함을 확인해주세요.</div>
                    <div style={{ marginBottom: 20 }}>
                        메일을 받지 못하셨다면, 입력하신 이메일 주소와 <br />
                        스팸메일함을 다시한번 확인해주세요.
                    </div>
                    <div style={{ marginBottom: 30 }}>
                        그래도 못 받으셨다면, <br />
                        비밀번호 재설정 메일 발송을 다시 시도해주세요.
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { canSubmit, isPristine, isDone } = this.state;

        return (
            <div className="account login container-fixed">
                <Nav className="light" />

                <div className="container">
                    <div className="page-header">
                        <h1>비밀번호 재설정</h1>
                    </div>
                    <div className="wrap">
                        <div className="mobile-title">
                            <span>비밀번호 재설정</span>
                        </div>
                        {/* <div className="wrap-area"> */}
                        <Formsy
                            onValidSubmit={this.handleSubmit}
                            onValid={this.enableButton}
                            onInvalid={this.disableButton}
                            onInvalidSubmit={this.notifyFormError}
                            onChange={this.handleFormChange}
                            className={`form login-form ${
                                isPristine ? 'is-pristine' : ''
                            }`}
                            ref={this.formRef}
                        >
                            <h2 className="h2">비밀번호를 잊어버리셨나요?</h2>
                            <div style={{ marginTop: 20, marginBottom: 30 }}>
                                가입시 입력하셨던 이메일 주소로 <br />
                                비밀번호 재설정 메일을 보내드립니다.
                            </div>
                            <Input
                                required
                                name="email"
                                title="이메일"
                                validations="isEmail"
                                validationError="정확한 이메일 주소를 입력하세요."
                                className="inline"
                                inputClassName="block"
                                placeholder="이메일 주소를 입력하세요."
                                disabled={isDone}
                            />

                            <div className="form-group">
                                <button
                                    type="submit"
                                    className="btn block basic"
                                    disabled={!canSubmit || isDone}
                                >
                                    비밀번호 재설정
                                </button>
                            </div>
                        </Formsy>
                        {/* </div> */}
                    </div>
                    {this.state.isModalOpen && (
                        <Modal
                            content={this.renderModal()}
                            onDismiss={this.onReset}
                            style={{ width: 500, height: 349 }}
                        />
                    )}
                    {this.state.hasError && (
                        <ErrorModal onDismiss={this.resetForm} />
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    tempData: state.temp,
});

const mapDispatchToProps = dispatch => ({
    apiRequest: bindActionCreators(apiRequest, dispatch),
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(PasswordReset),
);
