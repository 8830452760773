// @flow
export const API = 'API';
export const API_START = 'API_START';
export const API_END = 'API_END';
export const ACCESS_DENIED = 'ACCESS_DENIED';
export const API_ERROR = 'API_ERROR';
export const API_RESET = 'API_RESET';

export const apiStart = name => {
  return {
    type: API_START,
    payload: name,
  };
};

export const apiEnd = name => ({
  type: API_END,
  payload: name,
});

export const accessDenied = (name, url) => ({
  type: ACCESS_DENIED,
  payload: { name, url },
});

export const apiError = (type, error) => {
  return {
    type: API_ERROR,
    apiType: type,
    message: error.message,
    code: error.code,
  };
};

export const apiReset = () => {
  return { type: API_RESET };
};
