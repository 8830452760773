import React from 'react';
import { Route } from 'react-router-dom';
import Container from 'container';
// import Protected from 'pages/Protected';
import MyPageInfo from './Info';
import MyPageInsurance from './Insurance';
// import MyPageInsurances from './Insurances';
// import MySettings from './Settings';

const MyPage = ({ match }) => {
    return (
        <div>
            <Route
                exact
                path={`${match.url}`}
                component={Container({
                    Component: MyPageInfo,
                    isProtected: true,
                    title: '마이페이지',
                    theme: 'dark-light',
                    className: 'my-page',
                })}
            />
            <Route
                path={`${match.path}/insurances/:orderId`}
                component={Container({
                    Component: MyPageInsurance,
                    isProtected: true,
                    title: '마이페이지',
                    theme: 'dark-light',
                    className: 'my-page',
                })}
            />
            {/* <Route
                exact
                path={`${match.path}/insurances`}
                component={Protected(MyPageInsurances)}
            /> */}
            {/* <Route
                exact
                path={`${match.path}/settings`}
                component={Protected(MySettings)}
            /> */}
        </div>
    );
};

export default MyPage;
