/*
 * formsy-react의 addValidationRule에서 사용하여 커스텀 validation 만든다.
 * https://github.com/formsy/formsy-react/blob/master/API.md#addValidationRule
 * 여기서 인자는 (values, value) 반드시 이다.
 */

import getAge from 'utils/getAge';
export const isMobile = (values, value) => {
    //return /^\d{10,12}$/.test(value) || /^\d{3}-\d{3,4}-\d{4}$/.test(value);
    return /^\d{10,12}$/.test(value);
};

export const isValidEmailFormat = (values, value) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(value);
};

export const isValidPassword = (values, value) => {
    const { password, passwordConfirm } = values;
    return password === passwordConfirm;
};

export const isBirthday = (values, value) => {
    const regExp = /^\d{6}$/;
    if (!regExp.test(value)) return false;
    const month = Number(value.substring(2, 4));
    const day = Number(value.substring(4, 6));

    return month > 0 && month < 13 && (day > 0 && day < 32);
};

export const isValidAge = (values, value) => {
    const age = getAge(value);
    return age > 0 && age < 80; // 만 1세부터 만 79세
};

export const isAdult = (values, value) => {
    const age = getAge(value);
    return age > 18 && age < 80; // 만 19세부터 만 79세
};

export const isUserName = (values, value) => {
    const isNotValidNamesFormat = v =>
        String(v).search(/[\d~!@#\$%\^&\*\(\)_\|\+\\\-=\?;:'".<>\{\}\[\]]/) !==
        -1;

    return !isNotValidNamesFormat(value);
};

export const isValidSSN = (values, sid) => {
    const isValidSexNumber = v => /[1-4]/.test(v);
    const isValidMonth = v => parseInt(v) >= 1 && parseInt(v, 10) <= 12;
    const isValidDay = v => parseInt(v, 10) >= 1 && parseInt(v, 10) <= 31;
    const Last = v => v[v.length - 1];
    const isEmpty = v => [undefined, null, ''].some(v => !!v);
    const v = String(sid)
        .trim()
        .replace(/[^\d]/g, '');
    if (isEmpty(v) || String(v).length !== 13) return false;

    const SID = String(v).split('');
    const checker = Number(Last(SID));
    const month = v.substr(2, 2);
    const day = v.substr(4, 2);
    /**
     * 1900년대 1 2,
     * 2000년대 3 4,
     * 1900년대 외국인 5 6,
     * 2000년대 외국인 7 8
     */
    const sex = SID[6];
    const variadicJ = v => (v >= 8 ? (v % 8) + 2 : v + 2);
    const sum = SID.slice(0, 12)
        .map((el, idx) => el * variadicJ(idx))
        .reduce((acc, value) => acc + value, 0);
    const checker2 = (11 - (sum % 11)) % 10;
    return [
        checker === checker2,
        checker !== (checker2 + 2) % 10,
        isValidMonth(month),
        isValidDay(day),
        isValidSexNumber(sex),
    ].every(v => !!v);
};

export const hasNoSpace = (values, value) => {
    return String(value).search(/\s/) === -1;
};

export const hasNumber = (values, value) => {
    return String(value).search(/\d/) !== -1;
};

export const hasLetter = (values, value) => {
    return (
        String(value).search(
            /[~!@#\$%\^&\*\(\)_\|\+\\\-=\?;:'".<>\{\}\[\]|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-z|A-Z]/,
        ) !== -1
    );
};
