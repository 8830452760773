import React, { Component } from 'react';
import { propTypes, withFormsy } from 'formsy-react';
import { IS_MOBILE_DEVICE } from 'constants/device';

class Input extends Component {
    changeValue = event => {
        const valueType = this.props.type === 'checkbox' ? 'checked' : 'value';
        const currentValue = event.currentTarget[valueType].trim();
        // Z2.log('changeValue', currentValue, typeof currentValue);
        this.props.setValue(currentValue);
        this.props.exportValue && this.props.exportValue(currentValue);
    };

    handleBlur = () => {
        this.props.onBlur && this.props.onBlur();
    };

    render() {
        const customClassName = this.props.className
            ? ` ${this.props.className}`
            : '';
        // 필수값이 비어있을 때 showRequired()가 참이다.
        // 값이 유효하지 않으면 showError()가 참이다.
        const {
            showRequired,
            showError,
            getValue,
            name,
            title,
            // isPristine,
            type,
            hasValue,
            getErrorMessage,
            validationError,
            required,
            disabled,
            maxValue,
            error,
        } = this.props;
        const hasError = showError() || (required && !hasValue()) || error;

        const className = `form-group${customClassName}${
            showRequired() ? ' required' : ''
        }${hasError ? ' error' : ''}`;
        // 컴퍼넌트가 유효하지 않거나 서버가 에러 메시지를 보낼 때, 에러 메시지가 반환된다.

        const errorMsg = getErrorMessage() || validationError || '';
        const errorMessage = hasError && errorMsg;
        const inputType =
            !IS_MOBILE_DEVICE && type === 'number' ? 'text' : type || 'text';
        const currentValue =
            typeof maxValue !== 'undefined'
                ? Math.min(
                      maxValue,
                      isNaN(getValue()) ? 0 : Number(getValue()),
                  ) + ''
                : getValue();
        // Z2.log('@@@', name, currentValue, hasValue());
        return (
            <div className={className}>
                <label htmlFor={name} className="label">
                    {title}
                </label>
                <input
                    onChange={this.changeValue}
                    name={name}
                    id={name}
                    type={inputType}
                    value={currentValue || ''}
                    className={`input ${
                        this.props.inputClassName
                            ? this.props.inputClassName
                            : ''
                    }`}
                    placeholder={this.props.placeholder}
                    disabled={disabled}
                    ref={this.props.innerRef}
                    onBlur={this.handleBlur}
                    autoComplete={this.props.autoComplete || 'on'}
                />
                <span className="validation-error">{errorMessage}</span>
            </div>
        );
    }
}

Input.propTypes = {
    ...propTypes,
};

export default withFormsy(Input);
