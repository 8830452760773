import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import * as Sentry from '@sentry/browser';
// constants
import {
    MY_ORDER,
    MY_ORDER_URL,
    USER_INFO,
    USER_INFO_URL,
    USER_TRAVEL_POINT,
    USER_TRAVEL_POINT_URL,
    MY_ZIKTO_CASH,
    MY_ZIKTO_CASH_URL,
    MY_ORDERS,
    MY_ORDERS_URL,
} from 'constants/paths';
// redux
import { apiRequest } from 'redux/actions/api/utils';
import tempActions from 'redux/actions/temp';
// sub components
import DisplayTravelPoint from '../DisplayTravelPoint';
import DisplayZiktoCash from '../DisplayZiktoCash';
import DisplayInsuranceDetail from 'components/DisplayInsuranceDetail';
import ErrorModal from 'components/ErrorModal';
// utils
import getAge from 'utils/getAge';
import { formatYYYY_MM_DD_HH, formatToLocalString } from 'utils/format';
import { errorHandler } from 'utils/errorHandler';
import getTravelDays from 'utils/getTravelDays';

class ProductConfirm extends Component {
    constructor(props) {
        super(props);
        this.orderId = this.props.match.params.orderId;
        this.orderDetails = null;

        this.state = {
            isLoaded: false,
            hasNoOrder: false,
            startDate: null,
            endDate: null,
        };
    }

    componentDidMount() {
        this._mounted = true;

        Promise.all([
            this.fetchMyInsurances(),
            this.fetchUserInfo(),
            this.fetchMyTravelPoint(),
            this.fetchMyZiktoCash(),
            this.fetchMyOrder(),
        ])
            .then(() => {
                this._mounted &&
                    this.setState({
                        isLoaded: true,
                    });

                Sentry.withScope(scope => {
                    scope.setExtra('tempData', this.props.tempData);
                    Sentry.captureMessage('보험가입결과화면 did mount');
                });
            })
            .catch(e => {});
    }

    componentWillUnmount() {
        this._mounted = false;
        this.props.removeTempData();
    }

    fetchMyOrder = async () => {
        try {
            await this.props
                .apiRequest({
                    type: MY_ORDERS,
                    path: MY_ORDERS_URL,
                    method: 'get',
                })
                .then(response => {
                    Z2.log('response', response);
                    if (response.code !== 200 || response.order.length < 1) {
                        return false;
                    }
                    const thisOrder = response.order.find(
                        el => el.id === Number(this.orderId),
                    );
                    Z2.log('thisOrder', thisOrder, this.orderId);
                    if (!thisOrder) return;

                    this._mounted &&
                        this.setState({
                            email: thisOrder.email,
                        });
                })
                .catch(e => {
                    return Promise.reject(e);
                });
        } catch (e) {
            errorHandler(e, MY_ORDERS_URL, this.props.tempData.userId);
        }
    };

    fetchUserInfo = async () => {
        try {
            await this.props
                .apiRequest({
                    type: USER_INFO,
                    path: USER_INFO_URL,
                })
                .then(response => {
                    Z2.log('response', response);
                    this._mounted &&
                        this.setState({
                            email: response.email,
                            gender: response.gender,
                            phoneNumber: response.phoneNumber,
                            username: response.name,
                            userId: response.id,
                        });
                })
                .catch(e => {
                    return Promise.reject(e);
                });
        } catch (e) {
            errorHandler(e, USER_INFO_URL, this.props.tempData.userId);
        }
    };

    fetchMyInsurances = async () => {
        try {
            await this.props
                .apiRequest({
                    type: MY_ORDER,
                    path: `${MY_ORDER_URL}/${this.orderId}`,
                    method: 'get',
                })
                .then(response => {
                    if (response.code !== 200 || response.order.length < 1) {
                        return (
                            this._mounted &&
                            this.setState({
                                hasNoOrder: true,
                            })
                        );
                    }

                    this.orderDetails = response.order;
                    const myOrder = response.order[0];
                    const birthString = myOrder.birthday.substring(2);
                    this._mounted &&
                        this.setState(
                            {
                                startDate: formatYYYY_MM_DD_HH(
                                    myOrder.startDate,
                                ),
                                endDate: formatYYYY_MM_DD_HH(myOrder.endDate),
                                age: getAge(birthString),
                                contractor: myOrder.name,
                                companions: response.order.slice(1),
                            },
                            () => {
                                this.props.saveTempData({
                                    birthday: birthString,
                                    orderId: myOrder.orderId,
                                    price: response.order.reduce(
                                        (acc, val) => acc + val.amount,
                                        0,
                                    ),
                                    contactDate: myOrder.createdAt,
                                    insuType: myOrder.type,
                                    period: getTravelDays({
                                        startDate: moment(
                                            myOrder.startDate.substring(0, 8),
                                        ).toDate(),
                                        endDate: moment(
                                            myOrder.endDate.substring(0, 8),
                                        ).toDate(),
                                        startTime: Number(
                                            myOrder.startDate.substring(8),
                                        ),
                                        endTime: Number(
                                            myOrder.endDate.substring(8),
                                        ),
                                    }),
                                });
                            },
                        );
                })
                .catch(e => {
                    return Promise.reject(e);
                });
        } catch (e) {
            errorHandler(
                e,
                `${MY_ORDER_URL}/${this.orderId}`,
                this.props.tempData.userId,
            );
        }
    };

    fetchMyTravelPoint = async () => {
        if (!this._mounted) return;
        try {
            await this.props
                .apiRequest({
                    type: MY_ZIKTO_CASH,
                    path: MY_ZIKTO_CASH_URL,
                    method: 'get',
                })
                .then(response => {
                    this._mounted &&
                        this.setState({
                            ziktoCash: Number(
                                response.code === 200 ? response.balance : 0,
                            ),
                        });
                })
                .catch(e => {
                    return Promise.reject(e);
                });
        } catch (e) {
            errorHandler(e, MY_ZIKTO_CASH_URL, this.props.tempData.userId);
        }
    };

    fetchMyZiktoCash = async () => {
        if (!this._mounted) return;
        try {
            await this.props
                .apiRequest({
                    type: USER_TRAVEL_POINT,
                    path: USER_TRAVEL_POINT_URL,
                    method: 'get',
                })
                .then(response => {
                    this._mounted &&
                        this.setState({
                            travelPoint: Number(
                                response.code === 200 ? response.balance : 0,
                            ),
                        });
                })
                .catch(e => {
                    return Promise.reject(e);
                });
        } catch (e) {
            errorHandler(e, USER_TRAVEL_POINT_URL, this.props.tempData.userId);
        }
    };

    goToMyPage = () => {
        this.props.history.replace('/');
    };

    render() {
        const {
            isLoaded,
            hasNoOrder,
            age,
            email,
            phoneNumber,
            contractor,
            username,
            ziktoCash,
            travelPoint,
        } = this.state;
        const {
            birthday,
            insuType,
            price,
            contactDate,
            period,
        } = this.props.tempData;

        return (
            <div className="wrap-area">
                {!isLoaded && <div className="loader" />}
                {isLoaded && !hasNoOrder && (
                    <div style={{ width: '100%' }}>
                        <div className="section">
                            <div className="content">
                                <div className="progress progress-4">
                                    <div className="progress-seperate seperate-3" />
                                    <div className="seperate-4" />
                                    <div className="seperate-5" />
                                </div>
                                <h2 className="h2">보험 가입 완료</h2>
                                <DisplayInsuranceDetail
                                    period={period}
                                    insuType={insuType}
                                    age={age}
                                />
                                <div
                                    className="sheet0"
                                    style={{ marginBottom: 30 }}
                                >
                                    <div className="flex">
                                        <div
                                            className="flex"
                                            style={{
                                                width: 70,
                                            }}
                                        >
                                            <b>보험신청</b>
                                        </div>
                                        <div className="flex flex-1 justify-content-flex-end align-items-center">
                                            {moment(contactDate).format(
                                                'YYYY-MM-DD HH:mm',
                                            )}
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <div
                                            className="flex"
                                            style={{
                                                width: 70,
                                            }}
                                        >
                                            <b>보험기간</b>
                                        </div>
                                        <div className="flex flex-1 flex-column justify-content-center  align-items-flex-end">
                                            <div>
                                                {this.state.startDate} 시 부터
                                            </div>
                                            <div>
                                                {this.state.endDate} 시 까지
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-column">
                                        <div className="flex">
                                            <div
                                                className="flex"
                                                style={{
                                                    width: 70,
                                                }}
                                            >
                                                <b>가입자</b>
                                            </div>
                                            <div className="flex flex-1 justify-content-flex-end align-items-center">
                                                {contractor} ({birthday})
                                            </div>
                                        </div>
                                        {this.state.companions &&
                                            this.state.companions.map(
                                                (c, i) => (
                                                    <div
                                                        className="flex"
                                                        key={`c-${i}`}
                                                    >
                                                        <div
                                                            className="flex"
                                                            style={{
                                                                width: 70,
                                                            }}
                                                        >
                                                            <b />
                                                        </div>
                                                        <div className="flex flex-1 justify-content-flex-end align-items-center">
                                                            {c.name} (
                                                            {c.birthday.substring(
                                                                2,
                                                            )}
                                                            )
                                                        </div>
                                                    </div>
                                                ),
                                            )}
                                    </div>
                                    <div className="flex">
                                        <div
                                            className="flex"
                                            style={{
                                                width: 70,
                                            }}
                                        >
                                            <b>휴대폰 번호</b>
                                        </div>
                                        <div className="flex flex-1 justify-content-flex-end align-items-center">
                                            {phoneNumber}
                                        </div>
                                    </div>
                                    {email && (
                                        <div className="flex">
                                            <div
                                                className="flex"
                                                style={{
                                                    width: 70,
                                                }}
                                            >
                                                <b>이메일</b>
                                            </div>
                                            <div className="flex flex-1 justify-content-flex-end align-items-center">
                                                {email}
                                            </div>
                                        </div>
                                    )}

                                    <div className="flex">
                                        <div
                                            className="flex"
                                            style={{
                                                width: 70,
                                            }}
                                        >
                                            <b>보장 플랜</b>
                                        </div>
                                        <div className="flex flex-1 justify-content-flex-end align-items-center">
                                            {insuType === 'standard'
                                                ? '스탠다드'
                                                : '프리미엄'}
                                        </div>
                                    </div>
                                    <div
                                        className="flex"
                                        style={{
                                            marginBottom: 0,
                                        }}
                                    >
                                        <div
                                            className="flex"
                                            style={{
                                                width: 70,
                                            }}
                                        >
                                            <b>결제 보험료</b>
                                        </div>
                                        <div className="flex flex-1 justify-content-flex-end align-items-center">
                                            {formatToLocalString(price)} 원
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DisplayTravelPoint
                            username={username}
                            point={travelPoint}
                        />
                        <DisplayZiktoCash
                            username={username}
                            cash={ziktoCash}
                            style={{ marginTop: 10 }}
                        />
                        <div className="section">
                            <div className="content">
                                <div
                                    style={{
                                        marginTop: 30,
                                        marginBottom: 20,
                                        textAlign: 'center',
                                        fontSize: 12,
                                        color: '#000',
                                    }}
                                >
                                    보험가입심사가 진행중입니다.
                                    <br />
                                    <div className="mb-20">
                                        가입이 완료되면 메일주소로
                                        보험가입증명서가 발급됩니다.
                                    </div>
                                    30분이상 심사대기상태가 지속되면
                                    <br />
                                    고객센터 contact@zikto.com으로 연락바랍니다.
                                </div>
                                <Link
                                    to={`/my-page/insurances/${
                                        this.props.tempData.orderId
                                    }`}
                                >
                                    <button
                                        type="button"
                                        className="btn block basic"
                                        style={{
                                            marginTop: 30,
                                            marginBottom: 20,
                                        }}
                                    >
                                        보험 가입 내역
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                )}
                {isLoaded && hasNoOrder && (
                    <ErrorModal
                        onDismiss={this.goToMyPage}
                        msg={'잘못된 요청입니다.'}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    tempData: state.temp,
});

const mapDispatchToProps = dispatch => ({
    apiRequest: bindActionCreators(apiRequest, dispatch),
    saveTempData: bindActionCreators(tempActions.saveTempData, dispatch),
    removeTempData: bindActionCreators(tempActions.saveTempData, dispatch),
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(ProductConfirm),
);
