import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class Countdown extends PureComponent {
  static defaultProps = {
    second: 0,
    onEnd: () => {},
  };

  static propsTypes = {
    second: PropTypes.number,
    onEnd: PropTypes.func,
  };

  state = { second: this.props.second };

  timer = null;

  componentDidMount() {
    this.start().then(() => {
      this.props.onEnd();
    });
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  start = () => {
    return new Promise((resolve, reject) => {
      this.timer = setInterval(() => {
        this.setState({
          second: this.state.second - 1,
        });
        if (this.state.second <= 0) {
          clearInterval(this.timer);
          resolve(true);
        }
      }, 1000);
    });
  };

  fotmatTime = () => {
    const { second } = this.state;
    const date = new Date(null);
    date.setSeconds(second);
    const timeString = date.toISOString();
    return timeString.substr(14, 5);
  };

  render() {
    return <div className="countdown">{this.fotmatTime()}</div>;
  }
}

export default Countdown;
