import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Nav from 'components/Nav';
import Chat from 'components/Chat';

import { slack } from 'utils/slack-notifications';

class Promi extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isChatModalOpen: false,
        };
    }

    handleOpenChatModal = () => {
        this.setState({
            isChatModalOpen: !this.state.isChatModalOpen,
        });
    };

    componentDidMount() {}

    render() {
        const { isChatModalOpen } = this.state;

        return (
            <div className="promi-page">
                <Nav className="light" />
                <div className="contents">
                    <div className="page-header">
                        <h1>생산물하자보증책임보험</h1>
                        <h2>온라인 간편 가입 절차 안내</h2>
                        <p className="h3">
                            (프로미카서비스센터용)
                            <br />
                            온라인으로 언제 어디서나 보다 간편하게 가입하세요!
                            <br />
                            복잡하고 어려운 절차는 저희에게 맡겨주세요!
                        </p>
                        <div className="btn-area">
                            <p style={{ marginRight: 35 }}>
                                <button
                                    className="btn block white-btn"
                                    onClick={this.handleOpenChatModal}
                                >
                                    가입 시작하기
                                </button>
                            </p>
                            <p>
                                <a
                                    className="btn block white-btn"
                                    href="#section"
                                    // onClick={this.handleOpenChatModal}
                                >
                                    가입 절차 보기
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
                <div id="section" className="section section-color-white">
                    <div className="section-div">
                        <h1>
                            <span>1.</span>
                            <br />
                            연락 가능한 휴대폰 번호와 <br />
                            이메일 주소를 알려주세요.
                        </h1>
                        <p>
                            보험 가입 단계 안내와 청약서, 상품설명서 등의 발급을
                            <br />
                            위해 연락 가능한 휴대폰 번호와 이메일 주소를
                            알려주세요.
                            <br /> 단, 개인 정보 제공 동의가 필요합니다.
                        </p>
                    </div>
                    <div className="section-div-img section-img1" />
                </div>
                <div className="section section-color-beige is-desktop">
                    <div className="section-div-img section-img2" />
                    <div className="section-right section-div">
                        <h1>
                            <span>2.</span>
                            <br />
                            사업자등록증을 업로드 해주세요.
                            <br />
                            주소를 알려주세요.
                        </h1>
                        <p>
                            사업자등록증을 업로드하시면 자동으로 사업자등록증의
                            사업자등록번호, 법인명, <br />
                            사업장 소재지로 보험 가입 신청이 완료됩니다.
                        </p>
                    </div>
                </div>
                <div className="section section-color-beige is-mobile">
                    <div className="section-right section-div">
                        <h1>
                            <span>2.</span>
                            <br />
                            사업자등록증을 업로드 해주세요.
                            <br />
                            주소를 알려주세요.
                        </h1>
                        <p>
                            사업자등록증을 업로드하시면 자동으로 사업자등록증의
                            사업자등록번호, 법인명, <br />
                            사업장 소재지로 보험 가입 신청이 완료됩니다.
                        </p>
                    </div>
                    <div className="section-div-img section-img2" />
                </div>
                <div className="section section-color-black">
                    <div className="section-div">
                        <h1>
                            <span className="stroke-color">3.</span>
                            <br />
                            이메일 주소로 발급된 청약서와
                            <br />
                            상품설명서를 꼼꼼히 확인후 날인본을
                            <br /> 보내주세요.
                        </h1>
                        <p>
                            영업일 기준 2일 내에 심사를 마치고 입력하신 휴대폰
                            번호로 빠르게 보험 가입을 안내드리겠습니다. 문자
                            확인 후 이메일 주소로 저희가 발급해드린 청약서와
                            상품설명서를 꼼꼼히 확인하시고 날인본을 스캔하거나
                            글씨가 잘보이게 촬영하셔서 이메일로 회신해주세요.
                            팩스 회신도 가능합니다. (FAX : 02-123-4567)
                        </p>
                    </div>
                    <div className="section-div-img section-img3" />
                </div>
                <div className="section section-color-beige is-desktop">
                    <div className="section-div-img section-img4" />
                    <div className="section-right section-div">
                        <h1>
                            <span>4.</span>
                            <br />
                            안내드린 계좌로 보험료를
                            <br />
                            입금해주세요.
                        </h1>
                        <p>
                            청약서, 상품설명서와 함께 안내드린 계좌번호로 실
                            납입 보험료를 입금해주세요.
                            <br />
                            (입금이 확인되어야 보험 가입이 정상적으로
                            진행됩니다.)
                        </p>
                    </div>
                </div>
                <div className="section section-color-beige is-mobile">
                    <div className="section-right section-div">
                        <h1>
                            <span>4.</span>
                            <br />
                            안내드린 계좌로 보험료를
                            <br />
                            입금해주세요.
                        </h1>
                        <p>
                            청약서, 상품설명서와 함께 안내드린 계좌번호로 실
                            납입 보험료를 입금해주세요.
                            <br />
                            (입금이 확인되어야 보험 가입이 정상적으로
                            진행됩니다.)
                        </p>
                    </div>
                    <div className="section-div-img section-img4" />
                </div>
                <div className="section section-color-white">
                    <div className="section-div">
                        <h1>
                            <span>5.</span>
                            <br />
                            수고하셨습니다!
                            <br />
                            보험 가입이 완료되었습니다.
                        </h1>
                        <p>
                            보내주신 날인과 입금된 보험료를 확인 후 가입증명서를
                            드립니다.
                            {/* <br />
                            감사합니다! */}
                        </p>
                    </div>
                    <div className="section-div-img section-img5" />
                </div>
                {this.state.isChatModalOpen && (
                    <Chat onDismiss={this.handleOpenChatModal} />
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    api: state.api,
});

export default withRouter(connect(mapStateToProps)(Promi));
