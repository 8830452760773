import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Inner extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        const {
            content,
            buttonText,
            onDismiss,
            style,
            hasActions,
            className,
        } = this.props;

        return (
            <div className={`modal ${className ? className : ''}`}>
                <div className="modal-container" style={style}>
                    <div style={{ width: '100%' }}>
                        <div>{content}</div>
                        {!hasActions && (
                            <div className="modal-btn-area">
                                <button
                                    type="button"
                                    className="btn block basic"
                                    onClick={onDismiss}
                                >
                                    {buttonText}
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

Inner.propTypes = {
    onDismiss: PropTypes.func,
    content: PropTypes.any,
    header: PropTypes.string,
    buttonText: PropTypes.string,
};

Inner.defaultProps = {
    options: [],
    onDismiss: () => {},
    header: 'Modal',
    buttonText: '확인',
};

export default Inner;
