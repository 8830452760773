import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import * as Sentry from '@sentry/browser';
// utils
import { formatHour } from 'utils/format';
import { errorHandler } from 'utils/errorHandler';
import { getItem } from 'utils/handleStorage';
// sub components
import Modal from 'components/Modal';
import DisplayTravelPoint from '../DisplayTravelPoint';
import Bill from '../Bill';
import DisplayInsuranceDetail from 'components/DisplayInsuranceDetail';

class Payment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            isCancelModalOpen: false,
            cancelErrorMsg: '',
            needAuthModalOpen: false,
        };

        window.addEventListener(
            'message',
            event => {
                try {
                    const msg = JSON.parse(event.data);
                    if (msg.seq === 'Z2Token' || msg.seq === 'Z2TempData') {
                        Sentry.captureMessage(`onSendPostMessage ${msg.seq}`);
                    }
                } catch (e) {}
            },
            false,
        );
    }

    componentDidMount() {
        this.loadScripts().then(() => {
            this.setState({
                isLoaded: true,
            });
        });
    }

    componentWillUnmount() {
        Sentry.withScope(scope => {
            scope.setExtra('Z2Token', getItem('Z2Token'));
            scope.setExtra('tempData', getItem('Z2TempData'));
            Sentry.captureMessage('결제 form 화면 unmount 시');
        });
    }

    loadScripts(url) {
        return this.loadScript('https://code.jquery.com/jquery-1.12.4.min.js')
            .then(
                this.loadScript(
                    'https://cdn.iamport.kr/js/iamport.payment-1.1.5.js',
                ),
            )
            .catch(e => {
                errorHandler(
                    e,
                    'Payment LoadScripts',
                    this.props.tempData.userId,
                );
            });
    }

    loadScript(url) {
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.onload = resolve;
            script.onerror = reject;
            script.src = url;
            script.async = true;
            document.body.appendChild(script);
        });
    }

    toggleModal = modalName => {
        return () => {
            this.setState({
                [`is${modalName}Open`]: !this.state[`is${modalName}Open`],
            });
        };
    };

    sendPostMessage = (key, value) => {
        const pkt = {
            command: 'setItem',
            key,
            value,
            seq: key,
        };

        window.frames['zikto'] &&
            window.frames['zikto'].postMessage(JSON.stringify(pkt), '*');
    };

    handlePay = () => {
        const self = this;
        const { email, username, phoneNumber } = self.props;
        const Z2Token = getItem('Z2Token');
        const Z2TempData = getItem('Z2TempData');

        if (!this.props.authLevel)
            return this.setState({ needAuthModalOpen: true });

        this.sendPostMessage('Z2Token', Z2Token);
        this.sendPostMessage('Z2TempData', Z2TempData);

        Sentry.withScope(scope => {
            scope.setExtra('Z2Token', Z2Token);
            scope.setExtra('tempData', Z2TempData);
            Sentry.captureMessage('모바일 결제전');
        });

        window.IMP && window.IMP.init('imp76814888');
        window.IMP &&
            window.IMP.request_pay(
                {
                    // pg: 'html5_inicis', // version 1.1.0부터 지원.
                    pg: 'nice',
                    pay_method: 'card',
                    merchant_uid: 'merchant_' + new Date().getTime(),
                    name: '직토 해외여행보험',
                    amount: self.props.tempData.paidAmount,
                    buyer_email: email,
                    buyer_name: username,
                    buyer_tel: phoneNumber,
                    m_redirect_url: `${process.env.REACT_APP_URL}/purchase/step3`,
                    niceMobileV2 : true 
                },
                function(rsp) {
                    // let msg;
                    if (rsp.success) {
                        // msg = '결제가 완료되었습니다.';
                        // msg += '고유ID : ' + rsp.imp_uid;
                        // msg += '상점 거래ID : ' + rsp.merchant_uid;
                        // msg += '결제 금액 : ' + rsp.paid_amount;
                        // msg += '카드 승인번호 : ' + rsp.apply_num;

                        self.props.onSuccess &&
                            self.props.onSuccess(rsp.imp_uid);
                    } else {
                        self.setState(
                            {
                                cancelErrorMsg: rsp.error_msg,
                            },
                            () => {
                                self.toggleModal('CancelModal')();
                            },
                        );
                    }
                },
            );
    };

    renderCancelModal = () => {
        const msg = this.state.cancelErrorMsg;
        return (
            <div>
                <div className="header">
                    {msg ? msg : '보험 결제 취소 완료'}
                </div>
                <div className="contents" style={{ marginTop: 20 }}>
                    <div>보험료 결제를 다시 진행해주세요.</div>
                </div>
            </div>
        );
    };

    renderNeedAuthModal() {
        return (
            <div>
                <div className="header">휴대폰 인증 번호를 확인해주세요.</div>
            </div>
        );
    }

    render() {
        const {
            startDate,
            startTime,
            endDate,
            endTime,
            birthday,
            username,
            phoneNumber,
            insuType,
            email,
            point,
            estimate,
            ziktoCash,
            names,
            rates,
            age,
            period,
        } = this.props;
        const { isLoaded } = this.state;

        const companionNames = names.split(',').slice(1);
        const companions = companionNames.map((el, i) => ({
            name: el,
            ssn: this.props.tempData.companions[i].yymmdd,
        }));

        return (
            <div className="wrap-area" style={{ justifyContent: 'flex-start' }}>
                {!isLoaded && <div className="loader" />}
                {isLoaded && (
                    <div>
                        <div className="section">
                            <div className="content">
                                <div className="progress progress-3">
                                    <div className="progress-seperate seperate-3" />
                                    <div className="seperate-4" />
                                </div>
                                <h2 className="h2">보험 가입 신청내역</h2>

                                <DisplayInsuranceDetail
                                    insuType={insuType}
                                    age={age}
                                    period={period}
                                />
                                <div
                                    className="sheet0"
                                    style={{ marginBottom: 30, height: '100%' }}
                                >
                                    <div className="flex">
                                        <div
                                            className="flex"
                                            style={{ width: 70 }}
                                        >
                                            <b>보험기간</b>
                                        </div>
                                        <div className="flex flex-1 flex-column justify-content-center  align-items-flex-end">
                                            <div>
                                                {moment(startDate).format(
                                                    'YYYY-MM-DD',
                                                )}{' '}
                                                {formatHour(startTime)}시 부터
                                            </div>
                                            <div>
                                                {moment(endDate).format(
                                                    'YYYY-MM-DD',
                                                )}{' '}
                                                {formatHour(endTime)}시 까지
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-column">
                                        <div className="flex">
                                            <div
                                                className="flex"
                                                style={{ width: 70 }}
                                            >
                                                <b>가입자</b>
                                            </div>
                                            <div className="flex flex-1 justify-content-flex-end align-items-center">
                                                {username} ({birthday})
                                            </div>
                                        </div>
                                        {companions.map((c, i) => (
                                            <div
                                                className="flex"
                                                key={`c-${i}`}
                                            >
                                                <div
                                                    className="flex"
                                                    style={{ width: 70 }}
                                                >
                                                    <b />
                                                </div>
                                                <div className="flex flex-1 justify-content-flex-end align-items-center">
                                                    {c.name} ({c.ssn})
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    {phoneNumber && (
                                        <div className="flex">
                                            <div
                                                className="flex"
                                                style={{ width: 70 }}
                                            >
                                                <b>휴대폰 번호</b>
                                            </div>
                                            <div className="flex flex-1 justify-content-flex-end align-items-center">
                                                {phoneNumber}
                                            </div>
                                        </div>
                                    )}
                                    <div className="flex">
                                        <div
                                            className="flex"
                                            style={{ width: 70 }}
                                        >
                                            <b>이메일</b>
                                        </div>
                                        <div className="flex flex-1 justify-content-flex-end align-items-center">
                                            {email}
                                        </div>
                                    </div>
                                    <div
                                        className="flex"
                                        style={{ marginBottom: 0 }}
                                    >
                                        <div
                                            className="flex"
                                            style={{ width: 70 }}
                                        >
                                            <b>보장 플랜</b>
                                        </div>
                                        <div className="flex flex-1 justify-content-flex-end align-items-center">
                                            {insuType === 'standard'
                                                ? '스탠다드'
                                                : '프리미엄'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {point > 0 && (
                            <DisplayTravelPoint
                                username={username}
                                point={point}
                            />
                        )}

                        <div
                            className="mb-30"
                            style={{ width: '100%', marginTop: 5 }}
                        >
                            <Bill
                                onPay={this.handlePay}
                                type={insuType}
                                travelPoint={point}
                                ziktoCash={ziktoCash}
                                estimate={estimate}
                                names={names}
                                rates={rates}
                                level={this.props.authLevel}
                            />
                        </div>

                        {this.state.isCancelModalOpen && (
                            <Modal
                                content={this.renderCancelModal()}
                                style={{ width: 500, height: 200 }}
                                onDismiss={this.toggleModal('CancelModal')}
                            />
                        )}
                        {this.state.needAuthModalOpen && (
                            <Modal
                                content={this.renderNeedAuthModal()}
                                onDismiss={() => {
                                    this.setState({ needAuthModalOpen: false });
                                    this.props.history.push('/purchase/step1');
                                }}
                            />
                        )}
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    tempData: state.temp,
});

export default withRouter(connect(mapStateToProps)(Payment));
