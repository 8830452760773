import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import auth from './auth';
import api from './api';
import temp from './temp';

export default history =>
    combineReducers({
        auth,
        api,
        temp,
        router: connectRouter(history),
    });
