import React, { Component } from 'react';
import Formsy from 'formsy-react';
import Input from 'components/Input';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import tempActions from 'redux/actions/temp';
import { formatToLocalString } from 'utils/format';
import { setItem } from 'utils/handleStorage';
import { TEMP_DATA_TIMEOUT } from 'constants/timeout';

class Bill extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isPristine: true,
            travelPoint: this.getMaximunTravelPoint(),
            ziktoCash: 0,
            rates: this.props.rates,
        };
        this.companionNameArray = this.props.names.split(',');
    }

    getMaximunTravelPoint = () => {
        return Math.min(
            this.props.travelPoint,
            Math.floor(this.props.estimate * 0.1),
        );
    };

    getMaximumZiktoCash = () => {
        // 카드결제를 할 수 있도록 100을 남기고 할인한다.
        return Math.min(
            this.props.estimate - this.state.travelPoint - 1000,
            this.props.ziktoCash,
        );
    };

    handleSubmit = async data => {
        await this.props.saveTempData({
            paidAmount: this.getTotal(),
            paidTravelPoint: this.state.travelPoint,
            paidZiktoCash: this.state.ziktoCash,
        });

        this.saveTempLocalData();
        this.props.onPay && this.props.onPay();
    };

    saveTempLocalData = () => {
        const {
            users,
            insuType,
            startDate,
            endDate,
            startTime,
            endTime,
            jms,
            email,
            names,
            paidAmount,
            paidZiktoCash,
            paidTravelPoint,
        } = this.props.tempData;

        const tempData = {
            users,
            insuType,
            startDate: moment(startDate).format('YYYYMMDD'),
            endDate: moment(endDate).format('YYYYMMDD'),
            startTime: startTime,
            endTime: endTime,
            jms,
            email,
            names,
            paidAmount: paidAmount,
            paidZiktoCash: paidZiktoCash,
            paidTravelPoint: paidTravelPoint,
            level: this.props.level || 0,
        };

        setItem('Z2TempData', tempData, TEMP_DATA_TIMEOUT);
    };

    handleFormChange = currentValues => {
        this.setState({
            isPristine: false,
        });
    };

    handleTravelPointChange = value => {
        const validValue = isNaN(value)
            ? 0
            : Math.min(Number(value), this.getMaximunTravelPoint());

        this.setState({
            travelPoint: validValue,
        });
    };

    handleZiktoCasheChange = value => {
        const validValue = isNaN(value)
            ? 0
            : Math.min(Number(value), this.getMaximumZiktoCash());
        this.setState({
            ziktoCash: validValue,
        });
    };

    getTotal = () => {
        const { estimate } = this.props;
        const { travelPoint, ziktoCash } = this.state;

        return estimate - travelPoint - ziktoCash;
    };

    getCashToSave = () => {
        return Math.floor(this.getTotal() * 0.1);
    };

    render() {
        const { isPristine, ziktoCash, travelPoint } = this.state;

        return (
            <Formsy
                onValidSubmit={this.handleSubmit}
                onChange={this.handleFormChange}
                className={`form signup-form ${
                    isPristine ? 'is-pristine' : ''
                }`}
                style={{ width: '100%' }}
            >
                <div
                    className="section section-colored"
                    style={{ marginTop: 1 }}
                >
                    <div
                        className="content"
                        style={{ textAlign: 'center', height: '100%' }}
                    >
                        <div className="sheet" style={{ height: '100%' }}>
                            <div
                                className="flex sheet-line flex-column"
                                style={{ paddingTop: 5 }}
                            >
                                <div className="flex">
                                    <div className="flex flex-1 align-items-center">
                                        <b>
                                            [
                                            {this.props.type === 'standard'
                                                ? '스탠다드'
                                                : '프리미엄'}
                                            ] 보험료
                                        </b>
                                    </div>
                                    <div className="flex flex-1 justify-content-flex-end align-items-center">
                                        <span
                                            className="digit"
                                            style={{
                                                fontSize: 14,
                                                paddingRight: 5,
                                            }}
                                        >
                                            {formatToLocalString(
                                                this.props.estimate,
                                            )}
                                        </span>{' '}
                                        원
                                    </div>
                                </div>
                                {this.companionNameArray.map((name, i) => (
                                    <div
                                        className="flex"
                                        style={{
                                            marginTop: 5,
                                            color: '#9c9c9c',
                                        }}
                                        key={`companion-${i}`}
                                    >
                                        <div className="flex flex-1 align-items-center">
                                            {name}님
                                        </div>
                                        <div className="flex flex-1 justify-content-flex-end align-items-center">
                                            {formatToLocalString(
                                                this.state.rates[i],
                                            )}{' '}
                                            원
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="flex sheet-line flex-column">
                                <div className="flex mb-5">
                                    <div className="flex flex-1 align-items-center">
                                        <b>트래블포인트 할인</b>
                                    </div>
                                    <div className="flex flex-1 justify-content-flex-end align-items-center">
                                        <span style={{ marginRight: 3 }}>
                                            -
                                        </span>
                                        <Input
                                            type="number"
                                            name="travelPoint"
                                            className="travel-point-group"
                                            inputClassName="inline"
                                            value={travelPoint}
                                            maxValue={this.getMaximunTravelPoint()}
                                            exportValue={
                                                this.handleTravelPointChange
                                            }
                                        />
                                        <span style={{ marginLeft: 3 }}>
                                            원
                                        </span>
                                    </div>
                                </div>
                                <div className="flex">
                                    <div
                                        className="flex flex-1 align-items-center text-left"
                                        style={{ width: 180 }}
                                    >
                                        <b>
                                            직토캐시 할인 <br />(
                                            {this.props.ziktoCash} Cash 보유)
                                        </b>
                                    </div>
                                    <div className="flex flex-1 justify-content-flex-end align-items-center">
                                        <span style={{ marginRight: 3 }}>
                                            -
                                        </span>
                                        <Input
                                            name="ziktoCash"
                                            type="number"
                                            className="travel-point-group"
                                            inputClassName="inline"
                                            value={ziktoCash}
                                            maxValue={this.getMaximumZiktoCash()}
                                            exportValue={
                                                this.handleZiktoCasheChange
                                            }
                                            style={{ padding: 0 }}
                                        />
                                        <span style={{ marginLeft: 3 }}>
                                            원
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="flex sheet-line">
                                <div className="flex flex-1">
                                    <b style={{ fontSize: 20 }}>
                                        총 납입 보험료
                                    </b>
                                </div>
                                <div className="flex flex-1 justify-content-flex-end align-items-center">
                                    <span
                                        className="digit"
                                        style={{
                                            fontSize: 20,
                                            paddingRight: 5,
                                        }}
                                    >
                                        {formatToLocalString(this.getTotal())}
                                    </span>{' '}
                                    원
                                </div>
                            </div>
                            <div
                                className="flex"
                                style={{ paddingTop: 20, color: '#9c9c9c' }}
                            >
                                <div
                                    className="flex flex-1"
                                    style={{ fontSize: 13 }}
                                >
                                    <b>적립예정 직토캐시</b> (결제 금액의 10%)
                                </div>
                                <div
                                    className="flex justify-content-flex-end align-items-center"
                                    style={{ width: 85 }}
                                >
                                    {formatToLocalString(this.getCashToSave())}{' '}
                                    Cash
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section" style={{ marginTop: 30 }}>
                    <div className="content" style={{ padding: 0 }}>
                        <button type="submit" className="btn block basic">
                            결제하기
                        </button>
                    </div>
                </div>
            </Formsy>
        );
    }
}

const mapStateToProps = state => ({
    tempData: state.temp,
});

const mapDispatchToProps = dispatch => ({
    saveTempData: bindActionCreators(tempActions.saveTempData, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Bill);
