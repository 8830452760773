import React from 'react';
import PropTypes from 'prop-types';

class ExampleCustomInput extends React.Component {
    render() {
        return (
            <button
                type="button"
                style={{
                    // width: 265,
                    flex: 1,
                    textAlign: 'left',
                    fontFamily: 'Spoqa han Sans',
                }}
                className="input block"
                onClick={this.props.onClick}
            >
                {this.props.value ? (
                    this.props.value
                ) : (
                    <span style={{ color: '#9c9c9c' }}>선택하세요</span>
                )}
            </button>
        );
    }
}

ExampleCustomInput.propTypes = {
    onClick: PropTypes.func,
    value: PropTypes.string,
};

export default ExampleCustomInput;
