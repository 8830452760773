import React, { Component } from 'react';
import Formsy, { addValidationRule } from 'formsy-react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as moment from 'moment';
import 'moment/locale/ko';
// constants
import {
    ZIKTO_TERMS_PDF_URL,
    USER_TRAVEL_POINT,
    USER_TRAVEL_POINT_URL,
    EASY_SIGNUP,
    EASY_SIGNUP_URL,
    USER_INFO,
    USER_INFO_URL,
    EMAIL_CHECK,
    EMAIL_CHECK_URL,
} from 'constants/paths';

// redux
import { apiRequest } from 'redux/actions/api/utils';
import authActions from 'redux/actions/auth';
import tempActions from 'redux/actions/temp';
// utils
import { isValidPassword, isUserName, isValidSSN } from 'utils/validate';
import { formatHour } from 'utils/format';
import { errorHandler } from 'utils/errorHandler';
// sub components
import Input from 'components/Input';
import CompanionInput from './CompanionInput';
import PhoneVerification from 'components/PhoneVerification/Number';
import ErrorModal from 'components/ErrorModal';
import Checkbox from 'components/Checkbox';
import Modal from 'components/Modal';
import DisplayTravelPoint from 'pages/Purchase/DisplayTravelPoint';
// custom validations
addValidationRule('isValidPassword', isValidPassword);
addValidationRule('isUserName', isUserName);
addValidationRule('isValidSSN', isValidSSN);

moment.locale('ko');
class Form extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isPristine: true,
            canSubmit: false,
            isDone: false,
            isAgreeTerms: false,
            isWelcomeModalOpen: false,
            phoneAuthModalOpen: false,
            isErrorModalOpen: false,
            isInvalidGenderModalOpen: false,
            fullSSN: undefined,
            needAuthModalOpen: false,
            hasAuth: this.props.auth.level ? true : false,
        };

        this.formRef = React.createRef();
    }

    componentDidMount() {
        this._mounted = true;
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    handleSubmit = async data => {
        if (!this.state.hasAuth)
            return this.setState({ needAuthModalOpen: true });
        if (!this.isValidGender())
            return this.setState({ isInvalidGenderModalOpen: true });

        if (this.props.auth.level < 2 && this.state.isEmailFound) return;
        data.email = data.email ? data.email : '';
        data.username = data.username ? data.username : '';
        this.saveTempData(data);
    };

    saveTempData = data => {
        this.props.save && this.props.save(data);
    };

    isValidGender = () => {
        if (!this.state.fullSSN) return false;
        const genderDigit = this.state.fullSSN.substring(6, 7); // 주민번호 뒷자리 첫번째 수
        // this.props.tempData.gender: 사용자가 선택한 성별 => 남(1), 여(2)
        // 1900년대: 남(1) 여(2)
        // 2000년대: 남(3) 여(4)
        return (
            Number(genderDigit) % 2 === Number(this.props.tempData.gender) % 2
        );
    };

    enableButton = () => {
        // 주민번호 뒷자리 유효성 체크.
        this.setState({
            canSubmit: this.ssnRef.props.isValid() ? true : false,
        });
    };

    disableButton = () => {
        this.setState({ canSubmit: false });
    };

    handleFormChange = currentValues => {
        this.setState({
            isPristine: false,
        });
    };

    checkEmail = () => {
        if (!this.emailRef && this.emailRef.props.isValid()) return;

        this.props
            .apiRequest({
                type: EMAIL_CHECK,
                path: EMAIL_CHECK_URL,
                data: {
                    email: this.emailRef.props.getValue(),
                },
            })
            .then(response => {
                this._mounted &&
                    this.setState({
                        isEmailFound:
                            response && response.code === 200 ? true : false,
                    });
            })
            .catch(e => {});
    };

    // 트래블포인트 가져오기
    fetchTravelPoint = async phoneNumber => {
        try {
            await this.props
                .apiRequest({
                    type: USER_TRAVEL_POINT,
                    path: USER_TRAVEL_POINT_URL,
                    method: 'get',
                })
                .then(response => {
                    this.props.saveTempData({
                        travelPoint: Number(
                            response && response.code === 200
                                ? response.balance
                                : 0,
                        ),
                    });
                    this._mounted &&
                        this.setState({
                            isWelcomeModalOpen: true,
                        });
                })
                .catch(e => {
                    return Promise.reject(e);
                });
        } catch (e) {
            errorHandler(e, USER_TRAVEL_POINT_URL, this.props.tempData.userId);
        }
    };

    agreeTerms = value => {
        this.setState({
            isAgreeTerms: value,
        });
    };

    handlePhoneAuthFail = (phoneNumber, authNumber) => {
        this.setState(
            {
                phoneAuthModalOpen: true,
            },
            () => {
                this.props.saveTempData({ phoneNumber });
                this.handleEasySignup(phoneNumber, authNumber);
            },
        );
    };

    // 전화번호로 회원가입
    handleEasySignup = async (phoneNumber, authCodeNumber) => {
        if (this.props.auth.level) return;
        const params = {
            phoneNumber,
            code: authCodeNumber, // 인증번호
        };
        if (localStorage.getItem('development_secret')) {
            params.env = localStorage.getItem('development_secret');
        }

        try {
            await this.props
                .apiRequest({
                    type: EASY_SIGNUP,
                    path: EASY_SIGNUP_URL,
                    data: params,
                    config: {
                        headers: {
                            Authorization: null,
                        },
                    },
                })
                .then(response => {
                    if (response.code !== 200)
                        return Promise.reject(new Error(response.message));
                    // 성공시
                    this.setState(
                        {
                            hasAuth: true,
                        },
                        () => {
                            this.props.simpleLogin(response.accessToken, 1);
                        },
                    );
                })
                .catch(e => {
                    this.setState({
                        isErrorModalOpen: true,
                        errorMessage: e.message,
                    });
                    return Promise.reject(e);
                });
        } catch (e) {
            errorHandler(e, EASY_SIGNUP_URL, this.props.tempData.userId);
        }
    };

    fetchUserInfo = async () => {
        try {
            await this.props
                .apiRequest({
                    type: USER_INFO,
                    path: USER_INFO_URL,
                })
                .then(response => {
                    this.props.saveTempData({
                        phoneNumber: response.phoneNumber,
                        username: response.realName,
                        email: this.props.auth.level > 1 ? response.email : '',
                    });
                })
                .catch(e => {
                    return Promise.reject(e);
                });
        } catch (e) {
            errorHandler(e, USER_INFO_URL, this.props.tempData.userId);
        }
    };

    handlePhoneAuthSuccess = (phoneNumber, authLevel) => {
        if (authLevel === 2) {
            this.fetchUserInfo();
        }
        if (authLevel) {
            return this.setState(
                {
                    hasAuth: true,
                },
                () => {
                    this.props.saveTempData({ phoneNumber });
                    return this.fetchTravelPoint(phoneNumber);
                },
            );
        }

        this.setState({
            phoneAuthModalOpen: true,
            hasAuth: true,
            isEmailFound: undefined,
        });
    };

    handleCloseConfirmModal = () => {
        this.setState({
            isWelcomeModalOpen: false,
        });
    };

    closeErrorModal = () => {
        this.setState({
            isErrorModalOpen: false,
        });
    };

    handleSSNChange = value => {
        this.setState({
            fullSSN: this.props.tempData.birthday + value,
        });
    };

    renderWelcomeModal = () => {
        const userName =
            this.props.tempData.username ||
            this.props.tempData.phoneNumber.slice(-4);
        const userTravelPoint = this.props.tempData.travelPoint;
        return (
            <div>
                <div className="header">{userName}님, 안녕하세요!</div>
                <div className="contents">
                    {/* {this.props.auth.level} */}
                    <DisplayTravelPoint
                        username={userName}
                        point={userTravelPoint}
                    />
                    {this.props.auth.level === 1 && (
                        <div>
                            <div style={{ marginBottom: 20 }}>
                                나머지 정보를 입력해주세요.
                            </div>
                        </div>
                    )}
                    {this.props.auth.level === 2 && (
                        <div>휴대폰 번호 인증이 성공적으로 완료되었습니다.</div>
                    )}
                </div>
            </div>
        );
    };

    renderPhoneAuthModal() {
        return (
            <div>
                <div className="header">휴대폰 번호 인증 성공</div>
                <div className="contents">
                    <div style={{ marginBottom: 20 }}>
                        휴대폰 번호 로그인을 통해 간편하게
                        <br /> 로그인하실 수 있습니다.
                        <br />
                        <br /> 보험가입을 위해 나머지 정보를 입력해주세요.
                    </div>
                </div>
            </div>
        );
    }

    renderNeedAuthModal() {
        return (
            <div>
                <div className="header">휴대폰 인증 번호를 확인해주세요.</div>
            </div>
        );
    }

    renderInvalidGenderModal() {
        return (
            <div>
                <div className="header">성별을 다시 확인해주세요.</div>
                <div className="contents">
                    <div style={{ marginBottom: 20 }}>
                        선택하신 성별과 주민번호가 다릅니다.
                        <br />
                        이전단계로 이동하시겠습니까?
                    </div>
                </div>
                <div style={{ width: '70%', margin: 'auto', marginTop: 30 }}>
                    <div className="row gutter">
                        <div className="col">
                            <button
                                type="button"
                                className="btn block outline"
                                onClick={() => {
                                    this.setState({
                                        isInvalidGenderModalOpen: false,
                                    });
                                }}
                            >
                                <b>취소</b>
                            </button>
                        </div>
                        <div className="col">
                            <button
                                type="button"
                                className="btn block basic"
                                onClick={() => {
                                    this.setState(
                                        { isInvalidGenderModalOpen: false },
                                        () => {
                                            this.props.history.push(
                                                '/purchase/step1',
                                            );
                                        },
                                    );
                                }}
                            >
                                <b>확인</b>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { canSubmit, isPristine, isAgreeTerms, hasAuth } = this.state;
        const canNotSubmit =
            !hasAuth ||
            !(canSubmit && isAgreeTerms) ||
            (this.props.auth.level < 2 && this.state.isEmailFound);
        const {
            startDate,
            endDate,
            startTime,
            endTime,
            companions,
            username,
            birthday,
            email,
        } = this.props.tempData;

        return (
            <div className="wrap-area justify-start">
                {/* <div> */}
                <div className="progress progress-1" />
                <Formsy
                    style={{ margin: '0 auto', width: 335 }}
                    onValidSubmit={this.handleSubmit}
                    onValid={this.enableButton}
                    onInvalid={this.disableButton}
                    onChange={this.handleFormChange}
                    className={`form signup-form ${
                        isPristine ? 'is-pristine' : ''
                    }`}
                    ref={this.formRef}
                >
                    <h2 className="h2">보험 가입</h2>
                    <div style={{ marginBottom: 30 }}>
                        가입자의 정보를 입력하세요.
                    </div>

                    <div className="form-group inline">
                        <label className="label">보험기간</label>
                        <div className="text-align-right">
                            <div>
                                {moment(startDate).format('YYYY-MM-DD')}{' '}
                                {formatHour(startTime)}시 부터
                            </div>
                            <div>
                                {moment(endDate).format('YYYY-MM-DD')}{' '}
                                {formatHour(endTime)}시 까지
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            position: 'relative',
                            border: '1px solid #9c9c9c',
                            borderWidth: '1px 0',
                            padding: '20px 0',
                        }}
                        className="mb-20 step2"
                    >
                        <PhoneVerification
                            onFail={this.handlePhoneAuthFail}
                            onSuccess={this.handlePhoneAuthSuccess}
                        />
                    </div>

                    <div className="user-form">
                        <Input
                            required
                            name="username"
                            title="이름"
                            autoComplete="off"
                            className="inline"
                            inputClassName="block"
                            placeholder="입력하세요."
                            value={username ? username : undefined}
                            validations="minLength:2,maxLength:20,isUserName"
                            validationErrors={{
                                minLength: '최소 2자 이상 입력하세요.',
                                maxLength: '최대 20자 이하 입력하세요.',
                                isUserName:
                                    '숫자,특수문자,공백없이 입력하세요.',
                            }}
                            disabled={this.props.auth.level > 1 || !hasAuth}
                        />
                    </div>

                    <div className="form-group inline ssn-wrap">
                        <label htmlFor="nn1" className="label">
                            주민번호
                        </label>
                        <div className="block" style={{ width: 265 }}>
                            <div className="input-wrap">
                                <Input
                                    type="number"
                                    required
                                    name="nn1"
                                    title=""
                                    className="inline"
                                    validations="isNumeric,isLength:6"
                                    validationError="주민번호 앞자리를 입력하세요."
                                    value={birthday}
                                    disabled
                                />
                                <div
                                    style={{
                                        width: 30,
                                        height: 40,
                                        lineHeight: '40px',
                                        textAlign: 'center',
                                    }}
                                >
                                    -
                                </div>
                                <Input
                                    required
                                    type="password"
                                    name="nn2"
                                    title=""
                                    autoComplete="off"
                                    placeholder="뒷7자리"
                                    className="inline justify-content-flex-end"
                                    validations="isNumeric,isLength:7"
                                    validationError="주민번호 뒷자리를 입력하세요."
                                    exportValue={this.handleSSNChange}
                                    disabled={!hasAuth}
                                />
                                <div
                                    style={{
                                        position: 'absolute',
                                        bottom: 0,
                                        right: 0,
                                    }}
                                >
                                    <Input
                                        type="hidden"
                                        name="fullSSN"
                                        required
                                        validations="isValidSSN"
                                        validationError="주민번호 뒷자리를 입력하세요."
                                        value={this.state.fullSSN}
                                        innerRef={c => {
                                            this.ssnRef = c;
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mb-30">
                        {companions.map((c, i) => {
                            return (
                                <CompanionInput
                                    key={`companion-${i}`}
                                    index={i}
                                    birthday={c.yymmdd}
                                    name={c.name}
                                    gender={c.gender}
                                    disabled={!hasAuth}
                                />
                            );
                        })}
                    </div>

                    <div
                        className="text-right mb-5"
                        style={{ color: '#9c9c9c', fontSize: 12 }}
                    >
                        이메일로 보험가입증명서가 발송됩니다. <br />
                        가급적 개인메일주소를 입력해주세요.
                    </div>

                    <div className="form-group user-form mb-0">
                        <Input
                            required
                            name="email"
                            title="이메일"
                            autoComplete="off"
                            validations="isEmail"
                            validationError="정확한 이메일 주소를 입력하세요."
                            className="inline mb-20"
                            inputClassName="block"
                            placeholder="이메일 주소를 입력하세요."
                            value={email ? email : undefined}
                            onBlur={this.checkEmail}
                            innerRef={c => {
                                this.emailRef = c;
                            }}
                            disabled={this.props.auth.level > 1 || !hasAuth}
                        />
                    </div>
                    <div
                        style={{
                            textAlign: 'right',
                            fontSize: 10,
                            marginTop: -15,
                            marginBottom: 10,
                            color: '#000',
                            height: 11,
                        }}
                    >
                        {this.state.isEmailFound === true && (
                            <span style={{ color: 'red' }}>
                                이미 사용중인 이메일 주소입니다.
                            </span>
                        )}
                        {this.state.isEmailFound === false && (
                            <span>가입 가능한 이메일 주소입니다.</span>
                        )}
                    </div>

                    <div
                        className="top-line terms-agree"
                        style={{ marginTop: 30 }}
                    >
                        <span className="txt">
                            <a
                                href={ZIKTO_TERMS_PDF_URL}
                                title="직토서비스이용약관"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                서비스 이용약관 동의
                            </a>
                            <i className="fal fa-chevron-right" />
                        </span>

                        <Checkbox
                            name="agreeTerms"
                            value={isAgreeTerms}
                            required
                            className="term-checkbox"
                            onChange={this.agreeTerms}
                            validationError="서비스 이용약관에 동의해주세요."
                            innerRef={c => {
                                this.termsRef = c;
                            }}
                        />
                    </div>

                    <div className="row gutter" style={{ marginBottom: 30 }}>
                        <Link
                            to={'/purchase/step1'}
                            className="col"
                            style={{ display: 'block' }}
                        >
                            <button type="button" className="btn block outline">
                                이전 단계로
                            </button>
                        </Link>
                        <button
                            type="submit"
                            className="col btn block basic"
                            disabled={canNotSubmit}
                        >
                            다음 단계로
                        </button>
                    </div>
                </Formsy>
                {this.state.isWelcomeModalOpen && (
                    <Modal
                        content={this.renderWelcomeModal()}
                        onDismiss={this.handleCloseConfirmModal}
                        // style={{ width: 500, height: 450 }}
                    />
                )}
                {this.state.phoneAuthModalOpen && (
                    <Modal
                        content={this.renderPhoneAuthModal()}
                        onDismiss={() => {
                            this.setState({ phoneAuthModalOpen: false });
                        }}
                    />
                )}
                {this.state.needAuthModalOpen && (
                    <Modal
                        content={this.renderNeedAuthModal()}
                        onDismiss={() => {
                            this.setState({ needAuthModalOpen: false });
                        }}
                    />
                )}
                {this.state.isErrorModalOpen && (
                    <ErrorModal
                        onDismiss={this.closeErrorModal}
                        msg={this.state.errorMessage}
                    />
                )}
                {this.state.isInvalidGenderModalOpen && (
                    <Modal
                        content={this.renderInvalidGenderModal()}
                        hasActions={true}
                        onDismiss={() => {
                            this.setState(
                                { isInvalidGenderModalOpen: false },
                                () => {
                                    this.props.history.push('/purchase/step1');
                                },
                            );
                        }}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    tempData: state.temp,
});

const mapDispatchToProps = dispatch => ({
    apiRequest: bindActionCreators(apiRequest, dispatch),
    login: bindActionCreators(authActions.login, dispatch),
    saveTempData: bindActionCreators(tempActions.saveTempData, dispatch),
    simpleLogin: bindActionCreators(authActions.simpleLogin, dispatch),
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Form),
);
