export const formatPrice = value => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatHour = hour => {
    const isAM = hour < 13;
    const temp = isAM ? hour : hour - 12;
    const twoDigit = String(temp).length === 1 ? '0' + temp : temp;
    return `${isAM ? '오전' : '오후'} ${twoDigit}`;
};

export const formatYYYY_MM_DD = yyyymmddString => {
    if (!yyyymmddString) return '';

    return `${yyyymmddString.substring(0, 4)}-${yyyymmddString.substring(
        4,
        6,
    )}-${yyyymmddString.substring(6, 8)}`;
};

export const padDigits = (number, width) => {
    const nString = number + '';
    return nString.length >= width
        ? nString
        : new Array(width - nString.length + 1).join('0') + nString;
};

export const formatYYYY_MM_DD_HH = yyyymmddhh => {
    if (!yyyymmddhh || yyyymmddhh.length !== 10) return '';
    const hh = yyyymmddhh.substring(8);
    const meridiem = Number(hh) > 12 ? '오후' : '오전';
    const hour = Number(hh) > 12 ? padDigits(Number(hh) - 12, 2) : hh;
    return `${yyyymmddhh.substring(0, 4)}-${yyyymmddhh.substring(
        4,
        6,
    )}-${yyyymmddhh.substring(6, 8)} ${meridiem} ${hour}`;
};

export const formatToLocalString = number => {
    return (isNaN(number) ? 0 : Number(number)).toLocaleString().split('.')[0];
};
