import {
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGOUT,
    UPDATE,
} from '../actions/auth/types';
import { getItem } from 'utils/handleStorage';

let user = getItem('Z2Token');

const initialState = { level: user ? 1 : 0 };

export default function auth(state = initialState, action) {
    switch (action.type) {
        case LOGIN_SUCCESS: // 이메일, 비밀번호 로그인
            return {
                level: action.level || 0,
            };
        case LOGOUT:
        case LOGIN_FAILURE:
            return {
                level: 0,
            };
        case UPDATE:
            return {
                level: action.level,
            };
        default:
            return state;
    }
}
