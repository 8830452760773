import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Sentry from '@sentry/browser';
// redux
import authActions from 'redux/actions/auth';
// utils
import { getItem } from 'utils/handleStorage';
// sub components
import Nav from 'components/Nav';
import ErrorModal from 'components/ErrorModal';
// constants
import { PRODUCT_GUIDE_PDF_URL, AIG_TERMS_PDF_URL } from 'constants/paths';

export default function({
    Component: ComposedComponent,
    isProtected,
    title,
    theme,
    className,
    purchase,
    path,
}) {
    class Container extends Component {
        static contextTypes = {
            router: PropTypes.object,
        };

        componentDidCatch(error, errorInfo) {
            Sentry.withScope(scope => {
                scope.setExtras(errorInfo);
            });
        }

        render() {
            const z2Token = getItem('Z2Token');

            Sentry.withScope(scope => {
                scope.setExtra('isProtected', !!isProtected);
                scope.setExtra('title', title);
                scope.setExtra('path', path || '');
                scope.setExtra('Z2Token', getItem('Z2Token'));
                scope.setExtra('tempData', getItem('Z2TempData'));
                Sentry.captureMessage('Base Container');
            });

            if (isProtected && !z2Token) {
                this.props.authUpdate(0);
                return <Redirect to="/login" />;
            }

            return (
                <div className={`${className} container-fixed`}>
                    <Nav className={theme} />

                    <div className="container">
                        <div className="page-header">
                            <h1>{title}</h1>
                            {purchase === 1 && (
                                <div className="page-guide">
                                    <div className="content">
                                        저렴하고 간편하게! 알차고 실속있는
                                        보장만 담았습니다.
                                        <br />
                                        당신에게 꼭 필요한 플랜을 선택해보세요.
                                    </div>
                                    <span className="container-text">
                                        본 서비스는 AIG 손해보험이 제공합니다.
                                    </span>
                                    <div className="links">
                                        <a
                                            className="pdf-link pdf-info"
                                            href={PRODUCT_GUIDE_PDF_URL}
                                            title="상품안내"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            상품소개
                                        </a>
                                        <a
                                            className="pdf-link pdf-agree"
                                            href={AIG_TERMS_PDF_URL}
                                            title="AIG약관"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            약관 다운로드
                                        </a>
                                    </div>
                                </div>
                            )}
                            {purchase === 2 && (
                                <div className="page-guide">
                                    <div className="content">
                                        저렴하고 간편하게! 알차고 실속있는
                                        보장만 담았습니다.
                                        <br />
                                        당신에게 꼭 필요한 플랜을 선택해보세요.
                                    </div>
                                </div>
                            )}
                            {purchase === 3 && (
                                <div className="page-guide">
                                    <div className="content">
                                        <b>가입이 완료되었습니다!</b>
                                        <div
                                            style={{
                                                marginTop: 20,
                                                fontSize: 12,
                                            }}
                                        >
                                            상세 보험 가입 내역을 확인하시고,{' '}
                                            <br />
                                            편안하고 즐거운 여행 되세요!
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="wrap">
                            <div className="mobile-title">
                                <span>{title}</span>
                            </div>
                            <div className="wrap-area container-wrap-area">
                                <ComposedComponent {...this.props} />
                                <ErrorModal />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }

    const mapStateToProps = state => ({
        auth: state.auth,
    });

    const mapDispatchToProps = dispatch => ({
        logout: bindActionCreators(authActions.logout, dispatch),
        authUpdate: bindActionCreators(authActions.update, dispatch),
    });

    return connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container);
}
