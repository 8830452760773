// @flow
import { API } from 'redux/actions/api';
import AxiosClient from 'utils/AxiosClient';
import { authHeader } from 'utils/authHeader';
import authActions from '../auth';
import { apiStart, apiError, apiEnd } from '.';

type requestParamType = {
    type?: String,
    method?: string,
    path: string,
    data?: ?Object,
    config?: ?Object,
    // onSuccess?: Function,
    // onError?: Function,
};

export const apiRequestUtil = (paramObj: requestParamType) => {
    const {
        type,
        method = 'post',
        path,
        data = {},
        params = {},
        config = {},
        // onSuccess = () => {},
        // onError = () => {},
    } = paramObj;

    return dispatch => {
        dispatch(apiStart(API));

        const withoutAuth =
            config.headers && config.headers.Authorization === null;
        const customHeader = {
            headers: withoutAuth ? {} : authHeader(),
            ...config,
        };

        return AxiosClient[method](
            path,
            method === 'get' ? params : data,
            customHeader,
        )
            .then(response => {
                if (response.error) return Promise.reject(response.error);
                dispatch(apiEnd(API));
                // onSuccess(response, dispatch);
                return Promise.resolve(response);
            })
            .catch(error => {
                Z2.log('error2', type, error.message, error, error.code);
                if (error.code === 403) return dispatch(authActions.logout());
                dispatch(apiError(type, error));
                // onError(error, dispatch);
                // return Promise.reject(error);
                return Promise.reject(error);
            });
    };
};

export const apiRequest = paramObj => {
    return apiRequestUtil({ ...paramObj });
};
