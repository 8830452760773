import React, { Component } from 'react';
import Modal from 'components/Modal';
import Nav from 'components/Nav';
import { withRouter } from 'react-router-dom';

const dummy = {
    personalProduct: [
        {
            name: '해외여행보험',
            desc:
                '당신의 여행지와 목적에 맞춰 보상 플랜이 달라집니다. 이제 당신의 여행 타입에 딱 맞는 보장을 찾으세요.',
        },
        {
            name: '결혼식 날씨보험',
            desc:
                '당신의 가장 소중한 결혼식, 야외 결혼식날 날씨로 인한 손해를 보상해드립니다.',
        },
        {
            name: '여행 날씨보험',
            desc:
                '즐거운 여행 중 날씨 때문에 속상하신적 있으신가요? 여행중 비나 눈이 오면 여행 경비를 보상해드립니다.',
        },
        {
            name: '농작물 날씨보험',
            desc:
                '기후변화로 인해 점점 더 어려워지는 날씨 예측, 날씨로 인한 수확량(수입)의 감소에 대비하세요.',
        },
        {
            name: '자영업 날씨보험',
            desc:
                '날씨로 인한 매출 손실에 대비하세요. 당일 매출의 최대 70%를 보상해드립니다.',
        },
    ],
    personalService: [
        {
            name: '비행기지연 보상서비스',
            desc:
                '예상치 못한 갑작스러운 비행기지연을 대비한 프리미엄 보상서비스를 받아보세요.',
        },

        // insureum website 연결시 open

        // {
        //   name: '날씨 보상서비스 (미세먼지)',
        //   desc:
        //     '세계의 가장 큰 리스크로 부상한 기후변화, 이제 직토를 통해 해답을 찾으세요.',
        // },
        // {
        //   name: '날씨 보상서비스 (폭우)',
        //   desc:
        //     '세계의 가장 큰 리스크로 부상한 기후변화, 이제 직토를 통해 해답을 찾으세요.',
        // },
    ],
};

class Personal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isProductContact: false,
        };
    }

    toggleModal = () => {
        this.setState({
            isProductContact: !this.state.isProductContact,
        });
    };

    renderProductContact() {
        return (
            <div>
                <div className="header">상품 준비중입니다.</div>
                <div className="contents">
                    <div style={{ marginBottom: 20 }}>
                        상품에 대한 설명이나, 보험 견적을 원하시면
                        <br /> 아래로 연락주세요. ( 담당: 김윤배 이사 )
                    </div>
                    <div>
                        <strong>
                            <i className="fal fa-envelope" /> &nbsp;
                            contact@zikto.com
                        </strong>
                        <br />
                        <strong>
                            <i className="fal fa-phone" /> &nbsp; +82 (0)70 4104
                            9458
                        </strong>
                    </div>
                </div>
            </div>
        );
    }
    render() {
        const { isProductContact } = this.state;
        return (
            <div className="personal-product container-fixed">
                <Nav className="dark-light" />
                <div className="mobile-title">
                    <span>개인 보험</span>
                </div>
                <div className="container">
                    <div className="section section1">
                        <h2 className="h2">개인 보험</h2>
                        <div className="card">
                            {dummy.personalProduct.map((el, i) => (
                                <div
                                    onClick={() => {
                                        if (i === 0) {
                                            return this.props.history.push(
                                                '/purchase/step1',
                                            );
                                        }
                                        this.toggleModal();
                                    }}
                                    className="flex-item"
                                    key={`products-${i}`}
                                >
                                    <img
                                        className="card-image"
                                        src={require(`./images/product_${i +
                                            1}.png`)}
                                        alt=""
                                    />
                                    <div className="card-desc-area">
                                        <strong className="card-desc title">
                                            {el.name}
                                        </strong>
                                        <span className="card-desc subtitle">
                                            {el.desc}
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="section section2">
                        <h2 className="h2">개인 보상 서비스</h2>
                        <div className="card">
                            {dummy.personalService.map((el, i) => (
                                <div
                                    onClick={this.toggleModal}
                                    className="flex-item"
                                    key={`products-${i}`}
                                >
                                    <img
                                        className="card-image"
                                        src={require(`./images/service_${i +
                                            1}.png`)}
                                        alt=""
                                    />
                                    <div className="card-desc-area">
                                        <strong className="card-desc title">
                                            {el.name}
                                        </strong>
                                        <span className="card-desc subtitle">
                                            {el.desc}
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {isProductContact && (
                    <Modal
                        content={this.renderProductContact()}
                        onDismiss={this.toggleModal}
                        // style={{ width: 500, height: 280 }}
                    />
                )}
            </div>
        );
    }
}

export default withRouter(Personal);
