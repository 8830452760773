import React from 'react';
import { formatToLocalString } from 'utils/format';

const DisplayTravelPoint = props => (
    <div
        className="section section-colored display-travel-point"
        style={props.style}
    >
        <div className="section" style={props.style}>
            <div className="content" style={{ textAlign: 'center' }}>
                <b>{props.username}님의 트래블포인트</b>
                <div style={{ marginTop: 10 }}>
                    <b className="digit">
                        {formatToLocalString(props.point || 0)}
                    </b>
                    <span className="unit" style={{ marginLeft: 5 }}>
                        Point
                    </span>
                </div>
            </div>
        </div>
    </div>
);

export default DisplayTravelPoint;
