import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import CompanionInputs from './CompanionInputs';
import Item from './Item';

class AddCompanion extends PureComponent {
    static defaultProps = {
        second: 0,
        onEnd: () => {},
    };

    static propsTypes = {
        second: PropTypes.number,
        onEnd: PropTypes.func,
    };

    state = {
        canAdd: false,
        editIndex: -1,
        companions: this.props.companions || [],
    };

    addCompanion = () => {
        this.setState({
            canAdd: true,
        });
    };

    handleClose = () => {
        this.setState({
            canAdd: false,
        });
    };

    closeEdit = () => {
        this.setState({
            editIndex: -1,
        });
    };

    handleAdd = params => {
        const temp = this.state.companions.concat({ ...params });
        this.setState({
            canAdd: false,
            companions: temp,
        });
        this.props.onEdit && this.props.onEdit(temp);
    };

    handleEdit = params => {
        const temp = [...this.state.companions];

        if (params.index == null) return;
        temp[params.index] = {
            gender: params.gender,
            yymmdd: params.yymmdd,
        };

        this.setState({
            editIndex: -1,
            companions: temp,
        });

        this.props.onEdit && this.props.onEdit(temp);
    };

    handleRemove = index => {
        return () => {
            const newCompanions = this.state.companions.filter(
                (el, i) => i !== index,
            );
            this.setState(
                {
                    companions: newCompanions,
                },
                () => {
                    this.props.onEdit && this.props.onEdit(newCompanions);
                },
            );
        };
    };

    handleStartEdit = index => {
        return () => {
            this.setState({
                editIndex: index,
            });
        };
    };

    renderItem = (c, i) => {
        if (this.state.editIndex !== i) {
            return (
                <Item
                    key={`item=${i}`}
                    index={i}
                    gender={c.gender}
                    yymmdd={c.yymmdd}
                    onRemove={this.handleRemove(i)}
                    onEdit={this.handleStartEdit(i)}
                />
            );
        }

        return (
            <CompanionInputs
                key={`item=${i}`}
                index={i}
                onClose={this.closeEdit}
                onEdit={this.handleEdit}
                yymmdd={c.yymmdd}
                gender={c.gender}
                editIndex={i}
            />
        );
    };

    render() {
        const { canAdd, editIndex, companions } = this.state;

        return (
            <div>
                <div className="companion">
                    {canAdd && (
                        <CompanionInputs
                            onClose={this.handleClose}
                            canAdd={canAdd}
                            onAdd={this.handleAdd}
                        />
                    )}
                    {companions &&
                        companions.map((c, i) => {
                            return this.renderItem(c, i);
                        })}
                </div>
                {companions.length < 4 && (
                    <div className="form-group btn">
                        <button
                            type="button"
                            className="btn block outline"
                            onClick={this.addCompanion}
                            disabled={canAdd || editIndex > -1}
                        >
                            <i className="fal fa-plus" /> <b>동반자 추가</b>
                        </button>
                    </div>
                )}
            </div>
        );
    }
}

export default AddCompanion;
