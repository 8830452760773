import * as Sentry from '@sentry/browser';
// import { slack } from './slack-notifications';

export function errorHandler(err, path, userId) {
    Z2.log(`errorHandler:err ${JSON.stringify(err)}`);
    Z2.log(`errorHandler:path ${path}`);
    Z2.log(`errorHandler:userId ${userId}`);
    Sentry.configureScope(scope => {
        scope.setExtra('path', path);
        userId && scope.setExtra('userId', userId);
    });
    Sentry.captureException(err);
    // slack(`/api/balance/isExistPhoneNumber ==> ${e}`);
}
