import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Formsy from 'formsy-react';
// redux
import { apiRequest } from 'redux/actions/api/utils';
// constants
import { USER_TRAVEL_POINT, USER_TRAVEL_POINT_URL } from 'constants/paths';
// sub components
import Modal from 'components/Modal';
// import ErrorModal from 'components/ErrorModal';
import PhoneVerification from 'components/PhoneVerification/Number';
import DisplayTravelPoint from 'pages/Purchase/DisplayTravelPoint';
// utils
import { errorHandler } from 'utils/errorHandler';

class TravelPointCheck extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isPristine: true,
            user: '',
            // modal
            isWelcomeModalOpen: false,
            travelPoint: 0,
        };

        this.formRef = React.createRef();
    }

    componentWillMount() {
        if (this.props.auth.level) this.props.history.replace('/my-page');
    }

    componentDidMount() {
        this._mounted = true;
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    handleFormChange = (currentValues, isChanged) => {
        if (!isChanged) return;
        this.setState({
            isPristine: false,
        });
    };

    moveToSignup = () => {
        // this.props.history.replace('/signup');
    };

    handleSuccessVerify = async phoneNumber => {
        // 회원이 아니면 회원가입 안내 modal 보여준다.
        if (!this.props.auth.level) {
            return this.setState({
                isNotRegisterdOpen: true,
            });
        }
        // 정회원이면 마이 페이지로 안내
        if (this.props.auth.level === 2) {
            return this.props.history.replace('/my-page');
        }

        this.fetchTravelPoint(phoneNumber);
    };

    // 트래블포인트 가져오기
    fetchTravelPoint = async phoneNumber => {
        try {
            await this.props
                .apiRequest({
                    type: USER_TRAVEL_POINT,
                    path: USER_TRAVEL_POINT_URL,
                    method: 'get',
                })
                .then(response => {
                    this._mounted &&
                        this.setState({
                            travelPoint: Number(
                                response && response.code === 200
                                    ? response.balance
                                    : 0,
                            ),
                            isWelcomeModalOpen: true,
                            user: phoneNumber.slice(-4),
                        });
                })
                .catch(e => {
                    return Promise.reject(e);
                });
        } catch (e) {
            errorHandler(e, USER_TRAVEL_POINT_URL, this.props.tempData.userId);
        }
    };

    renderWelcomeModal = () => {
        return (
            <div>
                <div className="header">{this.state.user}님, 안녕하세요!</div>
                <div className="contents">
                    <DisplayTravelPoint
                        username={this.state.user}
                        point={this.state.travelPoint}
                    />
                    <div
                        style={{
                            letterSpacing: -1,
                            lineHeight: '20px',
                            marginBottom: 10,
                        }}
                    >
                        적립하신 트래블포인트를 사용하시거나 <br />
                        직토 보험대리점의 서비스를 이용하시려면
                        <br />
                        회원가입이 필요합니다. <br />
                    </div>
                    <div>회원가입 페이지로 이동합니다.</div>
                </div>
                <div style={{ margin: '30px auto 0', width: '80%' }}>
                    <Link to={'/signup'}>
                        <button type="button" className="btn block basic">
                            회원 가입하기
                        </button>
                    </Link>
                </div>
            </div>
        );
    };

    renderNotRegisterdModal() {
        return (
            <div>
                <div className="header">아직 회원이 아니신가요?</div>
                <div className="contents">
                    등록된 회원정보가 없습니다.
                    <br />
                    직토 보험대리점의 서비스를 이용하시려면
                    <br />
                    회원가입이 필요합니다.
                    <div style={{ marginTop: 20 }}>
                        회원가입 페이지로 이동합니다.
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { isPristine } = this.state;

        return (
            <div style={{ margin: '0 auto' }}>
                <Formsy
                    onChange={this.handleFormChange}
                    className={`form login-form ${
                        isPristine ? 'is-pristine' : ''
                    }`}
                >
                    <h2 className="h2">트래블포인트를 적립하신 적이 있나요?</h2>
                    <div style={{ marginBottom: 30 }}>
                        휴대폰 번호 인증이 필요합니다.
                    </div>
                    <PhoneVerification onSuccess={this.handleSuccessVerify} />
                </Formsy>
                {this.state.isWelcomeModalOpen && (
                    <Modal
                        content={this.renderWelcomeModal()}
                        onDismiss={this.moveToSignup}
                        // style={{ width: 500, height: 450 }}
                        hasActions={true}
                    />
                )}
                {this.state.isNotRegisterdOpen && (
                    <Modal
                        content={this.renderNotRegisterdModal()}
                        // style={{ width: 500, height: 269 }}
                        onDismiss={() => {
                            this.props.history.replace('/signup');
                        }}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    api: state.api,
    auth: state.auth,
    tempData: state.temp,
});

const mapDispatchToProps = dispatch => ({
    apiRequest: bindActionCreators(apiRequest, dispatch),
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(TravelPointCheck),
);
