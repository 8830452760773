// @flow
import * as Sentry from '@sentry/browser';
import {
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGOUT,
    PASSWORD_RESET,
    UPDATE,
} from './types';
import { TOKEN_TIMEOUT } from 'constants/timeout';
import { push } from 'connected-react-router';
import AxiosClient from 'utils/AxiosClient';
import { apiRequestUtil } from 'redux/actions/api/utils';
import { setItem, removeItem } from 'utils/handleStorage';
import { apiError } from 'redux/actions/api';
import { LOGIN_URL } from 'constants/paths';
import tempActions from '../temp';

export default {
    login,
    logout,
    reset,
    simpleLogin,
    update,
};

function reset(paramObj) {
    return apiRequestUtil({
        type: PASSWORD_RESET,
        path: '/password-reset',
        data: paramObj,
    });
}

function login(paramObj) {
    return dispatch => {
        return AxiosClient.post(LOGIN_URL, paramObj, {
            baseURL: process.env.REACT_APP_API_URL,
        })
            .then(response => {
                dispatch(loginSuccess(response.accessToken, 2));
                if (paramObj.redirect) dispatch(push('/my-page'));
                return Promise.resolve(response);
            })
            .catch(error => {
                dispatch(loginFailure());
                dispatch(apiError('LOGIN', error));
                return Promise.reject(error);
            });
    };
}

function simpleLogin(accessToken, level) {
    return dispatch => {
        dispatch(loginSuccess(accessToken, level));
    };
}

export function loginSuccess(accessToken, level) {
    setItem('Z2Token', accessToken, TOKEN_TIMEOUT);
    Sentry.withScope(scope => {
        scope.setExtra('Z2Token', accessToken);
        Sentry.captureMessage(LOGIN_SUCCESS);
    });
    return { type: LOGIN_SUCCESS, level };
}

function loginFailure() {
    Sentry.withScope(scope => {
        Sentry.captureMessage('LOGIN_FAILURE');
    });
    removeItem('Z2Token');
    return { type: LOGIN_FAILURE };
}

function logout() {
    return dispatch => {
        Sentry.withScope(scope => {
            Sentry.captureMessage(LOGOUT);
        });
        removeItem('Z2Token');
        dispatch(tempActions.removeTempData());
        dispatch({ type: LOGOUT });
        dispatch(push('/'));
    };
}

function update(level) {
    return { type: UPDATE, level };
}
