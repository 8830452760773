import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Calendar from 'components/Calendar';
import TimeDropdown from 'components/TimeDropdown';

class StartEndDateInputs extends Component {
    constructor(props) {
        super(props);

        this.thisHour = this.roundUpCurrentHour();

        this.state = {
            startDate: this.props.startDate,
            endDate: this.props.endDate,
            startTime: this.props.startTime || this.thisHour,
            endTime: this.props.endTime,
            startTimeMin: this.props.startTime
                ? this.props.startTime
                : this.thisHour,

            endDateError: '도착일을 선택하세요.',
            endTimeError: '도착시간을 선택하세요.',
            maxTime: null,
        };
    }

    roundUpCurrentHour = () => {
        const p = 60 * 60 * 1000; // milliseconds in an hour
        return new Date(Math.ceil(new Date().getTime() / p) * p).getHours();
    };

    handleSelect = dateType => {
        return date => {
            const invalidResetDate = this.compareStartEndDate(dateType, date);

            const newState = {
                [dateType]: date,
                ...invalidResetDate,
                startTimeMin:
                    dateType === 'startDate' ? null : this.state.startTimeMin,
            };

            this.setState(
                {
                    ...newState,
                },
                () => {
                    this.props.onSelect &&
                        this.props.onSelect({
                            startDate: this.state.startDate,
                            endDate: this.state.endDate,
                            startTime: this.state.startTime,
                            endTime: this.state.endTime,
                            startTimeMin: this.state.startTimeMin,
                        });
                },
            );
        };
    };

    compareStartEndDate = (dateType, date) => {
        const { startDate, endDate } = this.state;

        if (dateType === 'startDate' && endDate) {
            if (date.getTime() > endDate.getTime()) {
                return { endDate: null };
            }
        }

        if (dateType === 'endDate' && startDate) {
            if (date.getTime() < startDate.getTime()) {
                return { startDate: null };
            }
        }

        return {};
    };

    isSameDay = (d1, d2) => {
        return (
            d1.getFullYear() === d2.getFullYear() &&
            d1.getMonth() === d2.getMonth() &&
            d1.getDate() === d2.getDate()
        );
    };

    getHoursForDay = day => {
        return Number(day) * 24;
    };

    getTimeDiff = (startMomentObj, endMomentObj) => {
        return moment.duration(endMomentObj.diff(startMomentObj));
    };

    getMinEndTime = () => {
        const { startDate, endDate, startTime } = this.state;
        if (!startDate || !endDate) return;
        const isSameday = moment(startDate).isSame(moment(endDate), 'day');

        if (isSameday) return startTime;
        return;
    };

    validatePeriod = () => {
        const { startDate, endDate, startTime } = this.state;

        if (!startDate || !endDate || !startTime) return;

        let startDateObj = moment(startDate).toDate();
        let end = moment(endDate);
        startDateObj.setHours(Number(startTime));
        const duration = this.getTimeDiff(moment(startDateObj), moment(end));
        const hours = duration.asHours();
        const hoursFor90Days = this.getHoursForDay(90);
        const hoursFor89Days = this.getHoursForDay(89);
        if (hoursFor89Days < hours) {
            return hoursFor90Days - hours;
        }
        return;
    };

    getMinStartDate = () => {
        const { endDate } = this.state;

        if (endDate) {
            const minStartDate = moment(endDate).subtract(90, 'day');
            const duration = this.getTimeDiff(
                moment().startOf('day'),
                minStartDate,
            );
            return duration.asHours() < 0 ? new Date() : minStartDate.toDate();
        }

        return new Date();
    };

    getDefaultEndTime = () => {
        const { endTime } = this.state;
        const max = this.validatePeriod();
        const min = this.getMinEndTime();
        if (!endTime) return null;
        if (max && endTime > max) return null;
        if (min && endTime < min) return null;
        return endTime;
    };

    render() {
        const {
            startDate,
            endDate,
            startTime,
            endTime,
            startDateError,
        } = this.state;

        return (
            <div className="start-end-date-inputs">
                <div className="start-date-inputs">
                    <div
                        className={`custom-form-group ${
                            !startDate ? 'error' : ''
                        }`}
                    >
                        <Calendar
                            name="startDate"
                            title="출발일"
                            lessThan={moment()
                                .add(30, 'day')
                                .toDate()}
                            moreThan={this.getMinStartDate()}
                            date={startDate}
                            onChange={this.handleSelect('startDate')}
                        />
                        <span className="validation-error">
                            {startDateError}
                        </span>
                    </div>
                    <div className={`custom-form-group`}>
                        <div className="form-group inline form-group-dropdown">
                            <TimeDropdown
                                onSelect={this.handleSelect('startTime')}
                                defaultValue={startTime}
                                moreThan={this.state.startTimeMin}
                                name="start"
                            />
                            <span className="validation-error">
                                {startDateError}
                            </span>
                        </div>
                        <div
                            className={`custom-error ${
                                !(startTime || startDate) ? 'error' : ''
                            }`}
                        >
                            <span className="validation-error date">
                                출발일을 선택하세요.
                            </span>
                        </div>
                    </div>
                </div>
                <div className="end-date-inputs">
                    <div
                        className={`custom-form-group ${
                            !endDate ? 'error' : ''
                        }`}
                    >
                        <Calendar
                            name="endDate"
                            title="도착일"
                            moreThan={startDate}
                            lessThan={moment(startDate)
                                .add(90, 'day')
                                .toDate()}
                            date={endDate}
                            onChange={this.handleSelect('endDate')}
                            className={!endDate ? 'error' : ''}
                        />
                    </div>
                    <div
                        className={`custom-form-group ${
                            !endTime ? 'error' : ''
                        }`}
                    >
                        <div className="form-group inline form-group-dropdown">
                            <TimeDropdown
                                className={!endTime ? 'error' : ''}
                                onSelect={this.handleSelect('endTime')}
                                defaultValue={this.getDefaultEndTime()}
                                lessThan={this.validatePeriod()}
                                moreThan={this.getMinEndTime()}
                                name="end"
                            />
                        </div>
                    </div>
                    <div
                        className={`custom-error ${
                            !(endDate || endTime) ? 'error' : ''
                        }`}
                    >
                        <span className="validation-error date">
                            도착일을 선택하세요.
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

StartEndDateInputs.propTypes = {
    onSelect: PropTypes.func,
    startDate: PropTypes.any,
    startTime: PropTypes.any,
    endDate: PropTypes.any,
    endTime: PropTypes.any,
};

StartEndDateInputs.defaultProps = {
    onSelect: () => {},
    startDate: null,
    startTime: null,
    endDate: null,
    endTime: null,
};

export default StartEndDateInputs;
