import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import ExampleCustomInput from 'components/Calendar/ExampleCustomInput';

// css
import './style.css';

class Calendar extends Component {
    static defaultProps = {
        name: '',
        title: '',
        className: '',
        date: null,
        onChange: () => {},
    };

    static propsTypes = {
        name: PropTypes.string,
        title: PropTypes.string,
        className: PropTypes.string,
        date: PropTypes.any,
        onChange: PropTypes.func,
        lessThan: PropTypes.instanceOf(Date),
        moreThan: PropTypes.instanceOf(Date),
    };

    state = {
        date: this.props.date || null,
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.date !== prevState.date) {
            return {
                date: nextProps.date,
            };
        }

        return null;
    }

    handleChange = date => {
        this.setState({
            date,
        });
        this.props.onChange && this.props.onChange(date);
    };

    render() {
        const { name, title, lessThan, moreThan, className } = this.props;

        return (
            <div className={`form-group inline ${className ? className : ''}`}>
                <label htmlFor={name} className="label">
                    {title}
                </label>
                <div
                    className="block"
                    style={{ flex: 1, position: 'relative' }}
                >
                    <DatePicker
                        customInput={<ExampleCustomInput />}
                        selected={this.state.date}
                        onChange={this.handleChange}
                        className="input block"
                        dateFormat="yyyy-MM-dd"
                        minDate={moreThan}
                        maxDate={
                            lessThan ||
                            moment()
                                .add(12, 'months')
                                .toDate()
                        }
                        showDisabledMonthNavigation
                        placeholderText="선택하세요"
                        // withPortal
                        // inline
                    />
                </div>
            </div>
        );
    }
}

export default Calendar;
