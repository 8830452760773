import {
  API_START,
  API_END,
  ACCESS_DENIED,
  API_ERROR,
  API_RESET,
} from '../actions/api';

const initialState = {
  start: '',
  end: '',
  denied: '',
  error: '',
  apiType: '',
  message: '',
};

export default function api(state = initialState, action) {
  switch (action.type) {
    case API_START:
      return {
        start: action.payload,
      };
    case API_END:
      return {
        end: action.payload,
      };

    case ACCESS_DENIED:
      return {
        url: action.payload.url,
        name: action.payload.name,
      };

    case API_ERROR:
      return {
        // error: action.payload,
        apiType: action.apiType,
        message: action.message,
        code: action.code,
      };
    case API_RESET:
      return {};

    default:
      return state;
  }
}
