export default {
  '00': {
    accident: ['5,000', '1,500', '50'],
    illness: ['1,500'],
    phone: '50',
    etc: ['2,000', '10'],
    etc2: ['2,000', '140', '50', '50', '6'],
  },
  '01': {
    accident: ['1', '4,000', '100'],
    illness: ['3,000'],
    phone: '100',
    etc: ['3,000', '30'],
    etc2: ['3,000', '140', '100', '50', '6'],
  },
  '10': {
    accident: ['5,000', '1,500', '50'],
    illness: ['5,000', '1,500'],
    phone: '50',
    etc: ['2,000', '10'],
    etc2: ['2,000', '140', '50', '50', '6'],
  },
  '11': {
    accident: ['1', '4,000', '100'],
    illness: ['5,000', '3,000'],
    phone: '100',
    etc: ['3,000', '30'],
    etc2: ['3,000', '140', '100', '50', '6'],
  },
};
