import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Modal from 'components/Modal';

class ErrorModal extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isVisible: true,
        };
    }
    renderModalContent = () => {
        return (
            <div>
                <div className="header">
                    {this.props.api.message || this.props.msg}
                </div>
            </div>
        );
    };

    handleDismiss = () => {
        if (this.props.onDismiss) {
            return this.props.onDismiss();
        }

        this.setState({ isVisible: false });

        // this.props.history.push('/');
    };

    render() {
        if (!this.props.api.apiType && !this.props.msg) return null;

        return (
            <Modal
                content={this.renderModalContent()}
                onDismiss={this.handleDismiss}
                className={this.state.isVisible ? '' : 'hidden'}
            />
        );
    }
}

const mapStateToProps = state => ({
    api: state.api,
});

const mapDispatchToProps = dispatch => ({
    // error: bindActionCreators(alertActions.error, dispatch),
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(ErrorModal),
);
