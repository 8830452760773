import React, { Component } from 'react';
import Formsy, { addValidationRule } from 'formsy-react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';

// constants
import {
    INSURANCE_CALC,
    INSURANCE_CALC_URL,
    USER_TRAVEL_POINT,
    USER_TRAVEL_POINT_URL,
    MY_ZIKTO_CASH,
    MY_ZIKTO_CASH_URL,
    USER_INFO,
    USER_INFO_URL,
    PRODUCT_GUIDE_PDF_URL,
    AIG_TERMS_PDF_URL,
} from 'constants/paths';
import { IS_MOBILE } from 'constants/device';
// redux
import { apiRequest } from 'redux/actions/api/utils';
import tempActions from 'redux/actions/temp';
import authActions from 'redux/actions/auth';
// utils
import { isBirthday, isAdult } from 'utils/validate';
import getAge from 'utils/getAge';
import getTravelDays from 'utils/getTravelDays';
import getFullYearString from 'utils/getFullYearString';
import { errorHandler } from 'utils/errorHandler';
import { padDigits } from 'utils/format';
// sub components
import Input from 'components/Input';
import AddCompanion from 'components/AddCompanion';
import SelectGender from 'components/SelectGender';
import DisplayTravelPoint from '../DisplayTravelPoint';
import ProductTypes from './ProductTypes';
import ProductDetail from './ProductDetail';
import StartEndDateInputs from './StartEndDateInputs';

// 주민번호 앞자리 유효성 체크
addValidationRule('isBirthday', isBirthday);
addValidationRule('isAdult', isAdult);

class Step1 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isPristine: true,
            hasResult: false,
        };

        this.formRef = React.createRef();
        this.containerRef = React.createRef();
        this.headerRef = React.createRef();
    }

    componentDidMount() {
        this._mounted = true;
        this.props.auth.level && this.fetchUserInfo();
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    fetchUserInfo = async () => {
        // 저장한 로컬 데이터가 있으면 호출하지 않고, 로컬 데이터를 사용한다.
        // 아래 정보는 최초 페이지 진입시에만 필요하다.
        if (this.props.tempData.birthday) return;
        try {
            await this.props
                .apiRequest({
                    type: USER_INFO,
                    path: USER_INFO_URL,
                })
                .then(response => {
                    // TODO: level1, level2 email 정보 여부로 확인하고 있다. 레벨 확인
                    this.props.saveTempData({
                        email: response.email,
                        gender: response.email ? response.gender : 0,
                        phoneNumber: response.phoneNumber,
                        username: response.email ? response.realName : '',
                        birthday: '',
                        userId: response.id,
                    });
                    this.getMyTravelPoint();
                    this.getMyZiktoCash();
                    this.props.authUpdate(response.email ? 2 : 1);
                })
                .catch(e => {
                    return Promise.reject(e);
                });
        } catch (e) {
            errorHandler(e, USER_INFO_URL, this.props.tempData.userId);
        }
    };

    getGenderCode = gender => {
        return Number(gender) === 2 ? 'F' : 'M';
    };

    getCompanionInfo = () => {
        const { companions } = this.props.tempData;
        return (
            companions &&
            companions.reduce((acc, curr) => {
                return (
                    acc +
                    ',' +
                    getFullYearString(curr.yymmdd) +
                    this.getGenderCode(curr.gender)
                );
            }, '')
        );
    };

    getMyZiktoCash = async () => {
        try {
            await this.props
                .apiRequest({
                    type: MY_ZIKTO_CASH,
                    path: MY_ZIKTO_CASH_URL,
                    method: 'get',
                })
                .then(response => {
                    this.props.saveTempData({
                        ziktoCash: Number(
                            response.code === 200 ? response.balance : 0,
                        ),
                    });
                })
                .catch(e => {
                    return Promise.reject(e);
                });
        } catch (e) {
            errorHandler(
                e,
                MY_ZIKTO_CASH_URL,
                this.props.tempData.userId,
                this.props.tempData.userId,
            );
        }
    };

    getMyTravelPoint = async () => {
        try {
            await this.props
                .apiRequest({
                    type: USER_TRAVEL_POINT,
                    path: USER_TRAVEL_POINT_URL,
                    method: 'get',
                })
                .then(response => {
                    this.props.saveTempData({
                        travelPoint: Number(
                            response.code === 200 ? response.balance : 0,
                        ),
                    });
                })
                .catch(e => {
                    return Promise.reject(e);
                });
        } catch (e) {
            errorHandler(e, USER_TRAVEL_POINT_URL, this.props.tempData.userId);
        }
    };

    getEstimatePrice = rateArray => {
        if (!rateArray || !Array.isArray(rateArray)) return 0;
        return rateArray.reduce((acc, curr) => acc + curr, 0);
    };

    getEstimateTotalPrice = estimate => {
        const { travelPoint } = this.props.tempData;
        // 트래블포인트의 최대 사용 금액은 결제 금액의 10%
        return Math.max(
            estimate - Math.min(travelPoint, Math.floor(estimate * 0.1)),
            0,
        );
    };

    handleSubmit = async data => {
        const {
            gender,
            startDate,
            endDate,
            startTime,
            endTime,
            insuType,
            birthday,
        } = this.props.tempData;

        if (!endDate || !endTime || !birthday || !gender) return;

        const start =
            moment(startDate).format('YYYYMMDD') + padDigits(startTime, 2);
        const end = moment(endDate).format('YYYYMMDD') + padDigits(endTime, 2);
        let users = `${getFullYearString(birthday)}${this.getGenderCode(
            gender,
        )}${this.getCompanionInfo()}`;

        const scrollHeight = IS_MOBILE
            ? (this.formRef.current ? this.formRef.current.offsetHeight : 0) +
              (this.headerRef.current
                  ? this.headerRef.current.offsetHeight
                  : 0) +
              150
            : this.formRef.current
            ? this.formRef.current.offsetHeight
            : 0;

        try {
            await this.props
                .apiRequest({
                    type: INSURANCE_CALC,
                    path: `${INSURANCE_CALC_URL}?users=${users}&type=${insuType}&startDate=${start}&endDate=${end}`,
                    method: 'get',
                })
                .then(response => {
                    const estimate = this.getEstimatePrice(response.rate);

                    this._mounted &&
                        this.setState(
                            {
                                hasResult: true,
                                canSubmit: false,
                            },
                            () => {
                                this.props.saveTempData({
                                    period: getTravelDays({
                                        startDate: moment(startDate).toDate(),
                                        endDate: moment(endDate).toDate(),
                                        startTime,
                                        endTime,
                                    }),
                                    age: getAge(birthday),
                                    users,
                                    estimate,
                                    estimateTotal: this.getEstimateTotalPrice(
                                        estimate,
                                    ),
                                    rates: response.rate,
                                    // insuType:
                                });
                                if (this.containerRef.current) {
                                    this.containerRef.current.scrollTop = scrollHeight;
                                }
                            },
                        );
                })
                .catch(e => {
                    return Promise.reject(e);
                });
        } catch (e) {
            errorHandler(
                e,
                `${INSURANCE_CALC_URL}?users=${users}&type=${insuType}&startDate=${start}&endDate=${end}`,
                this.props.tempData.userId,
            );
        }
    };

    enableButton = () => {
        this.setState({ canSubmit: true });
    };

    disableButton = () => {
        this.setState({ canSubmit: false });
    };

    handleFormChange = currentValues => {
        this._mounted &&
            this.setState({
                hasResult: false,
                isPristine: false,
                // canSubmit: true,
            });
        this.props.saveTempData({ birthday: currentValues.birthday });
    };

    selectGender = gender => {
        this.setState({ canSubmit: true, hasResult: false });
        this.props.saveTempData({ gender });
    };

    selectTime = newTimeState => {
        this.setState({
            canSubmit: true,
            hasResult: false,
        });
        this.props.saveTempData({ ...newTimeState });
    };

    editCompanion = companions => {
        this.setState({
            canSubmit: true,
            hasResult: false,
        });
        this.props.saveTempData({ companions });
    };

    changeInsuType = async tab => {
        if (!this.state.hasResult) return;
        await this.props.saveTempData({
            insuType: tab === '프리미엄' ? 'premium' : 'standard',
        });
        this.handleSubmit();
    };

    validateFormData = () => {
        const { gender, endDate, endTime, birthday } = this.props.tempData;
        const { canSubmit } = this.state;
        const notValidBirthday = !isBirthday(null, birthday);

        return (
            !gender ||
            !canSubmit ||
            endDate == null ||
            !endTime ||
            !birthday ||
            notValidBirthday
        );
    };

    render() {
        const { isPristine, hasResult } = this.state;
        const {
            gender,
            birthday,
            startDate,
            endDate,
            startTime,
            endTime,
            companions,
            phoneNumber,
            travelPoint,
            estimate,
            estimateTotal,
            rates,
            insuType,
            period,
            age,
            username,
        } = this.props.tempData;
        const activeInsuType =
            insuType === 'standard'
                ? '스탠다드'
                : insuType === 'premium'
                ? '프리미엄'
                : '';

        return (
            <div style={{ height: '100%' }}>
                <div
                    className="wrap-area"
                    style={{ justifyContent: 'flex-start', overflowY: 'auto' }}
                    ref={this.containerRef}
                >
                    <div className="mobile-register-top" ref={this.headerRef}>
                        <div className="mobile-register-title">
                            <h3>해외여행보험</h3>
                            <span>
                                저렴하고 간편하게! 알차고 실속있는 보장만
                                담았습니다. 당신에게 꼭 필요한 플랜을
                                선택해보세요.
                            </span>
                            <span>본 서비스는 AIG 손해보험이 제공합니다.</span>
                            <div style={{ marginTop: 20, display: 'flex' }}>
                                <a
                                    style={{ marginRight: 10, flex: 1 }}
                                    className="pdf-agree-mobile"
                                    href={PRODUCT_GUIDE_PDF_URL}
                                    title="상품안내"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    상품소개
                                </a>
                                <a
                                    className="pdf-agree-mobile"
                                    style={{ flex: 1 }}
                                    href={AIG_TERMS_PDF_URL}
                                    title="AIG약관"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    약관 다운로드
                                </a>
                            </div>
                        </div>
                    </div>
                    <div ref={this.formRef} style={{ padding: '0 20px' }}>
                        <Formsy
                            style={{ margin: '0 auto' }}
                            onValidSubmit={this.handleSubmit}
                            onValid={this.enableButton}
                            onInvalid={this.disableButton}
                            onChange={this.handleFormChange}
                            className={`form signup-form ${
                                isPristine ? 'is-pristine' : ''
                            }`}
                        >
                            <h2 className="h2">보험료 간편 계산</h2>
                            <div style={{ marginBottom: 30 }}>
                                공인인증서는 필요 없습니다.
                                <br />
                                이제 보험을 간편하고 저렴하게 이용하세요!
                            </div>

                            <StartEndDateInputs
                                onSelect={this.selectTime}
                                startDate={
                                    startDate ||
                                    moment()
                                        .set({
                                            hour: 0,
                                            minute: 0,
                                            second: 0,
                                            millisecond: 0,
                                        })
                                        .toDate()
                                }
                                endDate={endDate}
                                startTime={startTime}
                                endTime={endTime}
                            />

                            <Input
                                required
                                type="number"
                                name="birthday"
                                autoComplete="off"
                                title={`주민번호 \n앞 6자리`}
                                className="inline"
                                inputClassName="block"
                                placeholder="숫자만 입력하세요."
                                validations="isBirthday,isAdult"
                                validationErrors={{
                                    isBirthday: '주민번호 앞자리를 입력하세요.',
                                    isAdult:
                                        '만 19세부터 만 79세까지 가입가능합니다.',
                                }}
                                value={birthday}
                            />
                            {/* 성별 */}
                            <SelectGender
                                onSelect={this.selectGender}
                                gender={gender}
                            />
                            {/* 동반자 추가 */}
                            <AddCompanion
                                onEdit={this.editCompanion}
                                companions={companions}
                            />

                            <div className="form-group">
                                <button
                                    type="submit"
                                    className="btn block basic"
                                    disabled={this.validateFormData()}
                                >
                                    보험료 계산
                                </button>
                            </div>
                        </Formsy>
                    </div>

                    {!!(this.props.auth.level && travelPoint) && (
                        <DisplayTravelPoint
                            username={
                                username ? username : phoneNumber.slice(-4)
                            }
                            point={travelPoint}
                        />
                    )}

                    <ProductTypes
                        isDisabled={!hasResult}
                        estimate={estimate}
                        point={travelPoint}
                        onTab={this.changeInsuType}
                        estimateTotal={estimateTotal}
                        rates={rates}
                        companions={companions}
                        active={activeInsuType}
                    />
                    {hasResult && (
                        <div
                            className="product-detail-sheet"
                            style={{ marginTop: 30 }}
                        >
                            <ProductDetail
                                type={insuType}
                                age={age}
                                period={period}
                            />
                        </div>
                    )}
                    {hasResult && (
                        <div className="section" style={{ marginBottom: 30 }}>
                            <div className="content">
                                <Link to="/purchase/step2">
                                    <button
                                        type="button"
                                        className="btn block basic"
                                    >
                                        가입 신청하기
                                    </button>
                                </Link>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    tempData: state.temp,
});

const mapDispatchToProps = dispatch => ({
    apiRequest: bindActionCreators(apiRequest, dispatch),
    saveTempData: bindActionCreators(tempActions.saveTempData, dispatch),
    authUpdate: bindActionCreators(authActions.update, dispatch),
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Step1),
);
