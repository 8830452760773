import React from 'react';
import PropTypes from 'prop-types';
import Tabs from 'components/Tabs';
import ProductType from './ProductType';

const ProductTypes = props => (
    <div
        className={`section section-colored product-types ${
            props.isDisabled ? 'is-disabled' : ''
        }`}
        style={{ marginTop: 5 }}
    >
        <div className="content" style={{ textAlign: 'center' }}>
            <Tabs onTab={props.onTab} active={props.active}>
                <div label="스탠다드">
                    <ProductType
                        isDisabled={props.isDisabled}
                        type="스탠다드"
                        estimate={props.estimate}
                        point={props.point}
                        estimateTotal={props.estimateTotal}
                        rates={props.rates}
                        companions={props.companions}
                    />
                </div>
                <div label="프리미엄">
                    <ProductType
                        isDisabled={props.isDisabled}
                        type="프리미엄"
                        estimate={props.estimate}
                        point={props.point}
                        estimateTotal={props.estimateTotal}
                        rates={props.rates}
                        companions={props.companions}
                    />
                </div>
            </Tabs>
        </div>
    </div>
);

export default ProductTypes;

ProductTypes.propTypes = {
    isDisabled: PropTypes.bool,
};

ProductTypes.defaultProps = {
    isDisabled: false,
};
