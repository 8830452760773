import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Formsy, { addValidationRule } from 'formsy-react';

import {
    SIGN_UP,
    SIGN_UP_URL,
    EMAIL_CHECK,
    EMAIL_CHECK_URL,
    USER_INFO,
    USER_INFO_URL,
    ZIKTO_TERMS_PDF_URL,
} from 'constants/paths';
// redux
import { apiRequest } from 'redux/actions/api/utils';
import authActions from 'redux/actions/auth';
// utils
import {
    isValidPassword,
    isUserName,
    hasNoSpace,
    hasNumber,
    hasLetter,
} from 'utils/validate';
import { errorHandler } from 'utils/errorHandler';
// sub components
import Input from 'components/Input';
import Checkbox from 'components/Checkbox';
import Modal from 'components/Modal';
import ErrorModal from 'components/ErrorModal';
import PhoneVerification from 'components/PhoneVerification/Number';
// custom validations
addValidationRule('isValidPassword', isValidPassword);
addValidationRule('isUserName', isUserName);
addValidationRule('hasNoSpace', hasNoSpace);
addValidationRule('hasNumber', hasNumber);
addValidationRule('hasLetter', hasLetter);

class Signup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            phoneNumber: '',
            canSubmit: false,
            joinModalOpen: false,
            phoneAuthModalOpen: false,
            isAgreeTerms: false,
            isPristine: false,
            isEmailFound: undefined,
            hasSignupError: false,
            payloadErrorMsg: '',
            isLoaded: !this.props.auth.level ? true : false,
            isWelcomeModalOpen: false,
            hasAuth: this.props.auth.level ? true : false,
        };

        this.formRef = React.createRef();
    }

    componentDidMount() {
        this._mounted = true;
        this.props.auth.level && this.fetchUserInfo();
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    fetchUserInfo = async () => {
        try {
            await this.props
                .apiRequest({
                    type: USER_INFO,
                    path: USER_INFO_URL,
                })
                .then(response => {
                    this._mounted &&
                        this.setState({
                            phoneNumber: response.phoneNumber,
                            isLoaded: true,
                        });
                    this.props.authUpdate(response.email ? 2 : 1);
                    if (response.email) {
                        this.props.history.replace('/my-page');
                    }
                })
                .catch(e => {
                    return Promise.reject(e);
                });
        } catch (e) {
            errorHandler(e, USER_INFO_URL, this.props.tempData.userId);
        }
    };

    handleSubmit = async data => {
        const { email, password, username, phoneNumber } = data;

        try {
            await this.props
                .apiRequest({
                    type: SIGN_UP,
                    path: SIGN_UP_URL,
                    data: {
                        email,
                        password,
                        phoneNumber,
                        name: username,
                    },
                })
                .then(response => {
                    if (response.code !== 200)
                        return Promise.reject(new Error(response.message));

                    this._mounted &&
                        this.setState(
                            {
                                joinModalOpen: true,
                                hasAuth: true,
                            },
                            () => {
                                this.props.simpleLogin(response.accessToken);
                                this.props.authUpdate(2);
                            },
                        );
                })
                .catch(e => {
                    this._mounted &&
                        this.setState({
                            hasSignupError: true,
                            payloadErrorMsg: e.message,
                        });
                    return Promise.reject(e);
                });
        } catch (e) {
            errorHandler(e, SIGN_UP_URL, this.props.tempData.userId);
        }
    };

    enableButton = () => {
        this.setState({ canSubmit: true });
    };

    disableButton = () => {
        this.setState({ canSubmit: false });
    };

    notifyFormError = data => {
        Z2.log('Form error:', data);
    };

    handleFormChange = currentValues => {
        Z2.log('handleFormChange', currentValues.password);
        // 휴대폰 번호가 유효하면 인증번호 받기 버튼 활성화
        this._mounted &&
            this.setState({
                phoneNumber: currentValues.phoneNumber,
                email: currentValues.email,
                isPristine: false,
                ssn: currentValues.ssn,
                username: currentValues.username,
            });
    };

    handleLogin = async () => {
        try {
            await this.props
                .login({
                    email: this.state.email,
                    password: this.state.password,
                })
                .catch(e => {
                    this._mounted &&
                        this.setState({
                            hasLoginError: true,
                        });
                    return Promise.reject(e);
                });
        } catch (e) {
            errorHandler(e, '/api/balance/login', this.props.tempData.userId);
        }
    };

    toggleModal = modalName => {
        return () => {
            this.setState({
                [`${modalName}ModalOpen`]: !this.state[`${modalName}ModalOpen`],
            });
        };
    };

    agreeTerms = value => {
        this.setState({
            isAgreeTerms: value,
        });
    };

    selectGender = gender => {
        this.setState({ gender });
    };

    resetAPIError = () => {
        this.setState({
            canSubmit: false,
            hasSignupError: false,
        });
    };

    checkEmail = () => {
        if (!this.emailRef.props.isValid()) return;
        this.props
            .apiRequest({
                type: EMAIL_CHECK,
                path: EMAIL_CHECK_URL,
                data: {
                    email: this.state.email,
                },
            })
            .then(response => {
                this._mounted &&
                    this.setState({
                        isEmailFound:
                            response && response.code === 200 ? true : false,
                    });
            })
            .catch(e => {});
    };

    handleSuccessVerify = (phoneNumber, level) => {
        this._mounted &&
            this.setState({
                isWelcomeModalOpen: this.props.auth.level ? true : false,
                phoneAuthModalOpen: !this.props.auth.level ? true : false,
                phoneNumber,
                user: phoneNumber.slice(-4),
                hasAuth: true,
            });
    };

    handlePhoneAuthFail = phoneNumber => {
        this.setState({
            phoneAuthModalOpen: true,
        });
    };

    handleCloseConfirmModal = () => {
        if (this.props.auth.level === 2) {
            return this.props.history.replace('/my-page');
        }
        this.setState({
            isWelcomeModalOpen: false,
        });
    };

    renderWelcomeModal = () => {
        return (
            <div>
                <div className="header">{this.state.user}님, 안녕하세요!</div>
                <div className="contents">
                    {this.props.auth.level === 1 && (
                        <div>
                            <div style={{ marginBottom: 20 }}>
                                나머지 정보를 입력하시면, 더 쉽고 편하게
                                <br />
                                직토의 모든 서비스를 이용하실 수 있습니다.
                            </div>
                        </div>
                    )}
                    {this.props.auth.level === 2 && (
                        <div>이미 등록된 사용자입니다.</div>
                    )}
                </div>
            </div>
        );
    };

    renderJoinModal() {
        return (
            <div>
                <div className="header">회원가입 완료</div>
                <div className="contents">
                    <div style={{ marginBottom: 20 }}>
                        직토 보험대리점의 회원가입이 완료되었습니다!
                        <br />
                        더 쉽고 편하게 직토의 모든 서비스를
                        <br />
                        이용하실 수 있습니다.
                    </div>
                </div>
            </div>
        );
    }

    renderPhoneAuthModal() {
        return (
            <div>
                <div className="header">휴대폰 번호 인증 성공</div>
                <div className="contents">
                    <div style={{ marginBottom: 20 }}>
                        나머지 정보를 입력하시면, 더 쉽고 편하게
                        <br />
                        직토의 모든 서비스를 이용하실 수 있습니다.
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const {
            canSubmit,
            joinModalOpen,
            isAgreeTerms,
            isPristine,
            phoneNumber,
            phoneAuthModalOpen,
            isLoaded,
            hasAuth,
            isEmailFound,
        } = this.state;

        return (
            <div>
                {!isLoaded && <div className="loader" />}
                {isLoaded && (
                    <Formsy
                        onValidSubmit={this.handleSubmit}
                        onValid={this.enableButton}
                        onInvalid={this.disableButton}
                        onInvalidSubmit={this.notifyFormError}
                        onChange={this.handleFormChange}
                        className={`form signup-form form-width ${
                            isPristine ? 'is-pristine' : ''
                        }`}
                        ref={this.formRef}
                        // style={{ padding: '0 20px' }}
                    >
                        <h2 className="h2">반갑습니다!</h2>
                        <div style={{ margin: '30px 0' }}>
                            회원님의 정보를 정확히 입력해주세요.
                        </div>

                        <div
                            className="mb-30"
                            style={{
                                border: '1px solid #9c9c9c',
                                borderWidth: '1px 0',
                                padding: '30px 0',
                            }}
                        >
                            <PhoneVerification
                                phoneNumber={phoneNumber}
                                onSuccess={this.handleSuccessVerify}
                                onFail={this.handlePhoneAuthFail}
                            />
                        </div>
                        <Input
                            required
                            name="username"
                            title="이름"
                            validations="minLength:2,maxLength:20,isUserName"
                            validationErrors={{
                                minLength: '최소 2자 이상 입력하세요.',
                                maxLength: '최대 20자 이하 입력하세요.',
                                isUserName:
                                    '숫자,특수문자,공백없이 입력하세요.',
                            }}
                            className="inline"
                            inputClassName="block"
                            placeholder="입력하세요."
                            value={this.state.username}
                        />

                        <Input
                            required
                            name="email"
                            title="이메일"
                            validations="isEmail"
                            validationError="정확한 이메일 주소를 입력하세요."
                            className="inline"
                            inputClassName="block"
                            placeholder="이메일 주소를 입력하세요."
                            onBlur={this.checkEmail}
                            innerRef={c => {
                                this.emailRef = c;
                            }}
                        />
                        <div
                            style={{
                                textAlign: 'right',
                                fontSize: 10,
                                marginTop: -15,
                                marginBottom: 10,
                                color: '#000',
                                height: 11,
                            }}
                        >
                            {this.state.isEmailFound === true && (
                                <span style={{ color: 'red' }}>
                                    이미 사용중인 이메일 주소입니다.
                                </span>
                            )}
                            {this.state.isEmailFound === false && (
                                <span>가입 가능한 이메일 주소입니다.</span>
                            )}
                        </div>
                        <Input
                            required
                            type="password"
                            name="password"
                            title="비밀번호"
                            validations="minLength:8,maxLength:35,hasNumber,hasLetter,hasNoSpace"
                            validationError="8-35자의 문자,숫자를 조합하여 설정하세요."
                            validationErrors={{
                                hasNoSpace: '공백없이 입력하세요.',
                            }}
                            className="inline"
                            inputClassName="block"
                            placeholder="비밀번호를 입력하세요."
                        />
                        <Input
                            required
                            type="password"
                            name="passwordConfirm"
                            title={`비밀번호 \n확인`}
                            validations="isValidPassword"
                            validationError="비밀번호가 일치하지 않습니다."
                            inputClassName="block"
                            className="inline"
                            placeholder="비밀번호를 다시 입력하세요."
                        />
                        <div className="top-line terms-agree">
                            <span className="txt">
                                <a
                                    href={ZIKTO_TERMS_PDF_URL}
                                    title="직토서비스이용약관"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    서비스 이용약관 동의
                                </a>
                                <i className="fal fa-chevron-right" />
                            </span>

                            <Checkbox
                                name="agreeTerms"
                                value={isAgreeTerms}
                                required
                                className="term-checkbox"
                                onChange={this.agreeTerms}
                                validationError="서비스 이용약관에 동의해주세요."
                                innerRef={c => {
                                    this.termsRef = c;
                                }}
                            />
                        </div>

                        <div className="form-group">
                            <button
                                type="submit"
                                className="btn block basic"
                                disabled={
                                    !hasAuth ||
                                    isEmailFound ||
                                    !(canSubmit && isAgreeTerms)
                                }
                            >
                                회원가입
                            </button>
                        </div>
                    </Formsy>
                )}
                {joinModalOpen && (
                    <Modal
                        content={this.renderJoinModal()}
                        onDismiss={() => this.props.history.replace('/my-page')}
                    />
                )}
                {phoneAuthModalOpen && (
                    <Modal
                        content={this.renderPhoneAuthModal()}
                        onDismiss={() =>
                            this.setState({
                                phoneAuthModalOpen: false,
                                hasAuth: true,
                            })
                        }
                    />
                )}
                {this.state.hasSignupError && (
                    <ErrorModal
                        onDismiss={this.resetAPIError}
                        msg={this.state.payloadErrorMsg}
                    />
                )}
                {this.state.hasLoginError && (
                    <ErrorModal
                        onDismiss={() =>
                            this.setState({ hasLoginError: false })
                        }
                        msg="비밀번호가 잘못되었습니다."
                    />
                )}
                {this.state.isWelcomeModalOpen && (
                    <Modal
                        content={this.renderWelcomeModal()}
                        onDismiss={this.handleCloseConfirmModal}
                        // style={{ width: 500, height: 250 }}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    api: state.api,
    tempData: state.temp,
});

const mapDispatchToProps = dispatch => ({
    apiRequest: bindActionCreators(apiRequest, dispatch),
    simpleLogin: bindActionCreators(authActions.simpleLogin, dispatch),
    login: bindActionCreators(authActions.login, dispatch),
    authUpdate: bindActionCreators(authActions.update, dispatch),
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Signup),
);
