import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import qs from 'qs';
import * as Sentry from '@sentry/browser';
// constants
import { INSURANCE_REGISTER, INSURANCE_REGISTER_URL } from 'constants/paths';
import { INSURANCE_REGISTER_TIMEOUT } from 'constants/timeout';
// redux
import { apiRequest } from 'redux/actions/api/utils';
import authActions from 'redux/actions/auth';
// sub components
import ErrorModal from 'components/ErrorModal';
import Modal from 'components/Modal';
// utils
import { errorHandler } from 'utils/errorHandler';
import { padDigits } from 'utils/format';
import { getItem, setItem } from 'utils/handleStorage';
import { TOKEN_TIMEOUT, TEMP_DATA_TIMEOUT } from 'constants/timeout';

class Step3 extends Component {
    constructor(props) {
        super(props);

        const localData = this.getLocalData();
        this.token = localData ? localData.token : null;
        this.tempData = localData ? localData.tempData : null;
        this.orderDetails = null;

        this.qs = qs.parse(window.location.search, {
            ignoreQueryPrefix: true,
        });
        this.impId = this.qs.imp_uid;
        this.isPaymentSuccess = this.qs.imp_success;
        if (!this.impId || typeof this.isPaymentSuccess === 'undefined') {
            return this.props.history.replace('/login');
        }

        this.state = {
            isLoaded: this.qs.imp_success === 'false' ? true : false,
            hasError: this.qs.imp_success === 'false' ? true : false,
            hasErrorAfterPay: false,
            hasRegistered: false,
        };

        this.attachEvent();
    }

    async componentDidMount() {
        this._mounted = true;

        if (this.token && this.tempData) {
            return this.handleRegister();
        }

        await this.getPostMessage('Z2Token');
        await this.getPostMessage('Z2TempData');
    }

    componentWillUnmount() {
        this._mounted = false;
        const localData = this.getLocalData() || {};

        Sentry.withScope(scope => {
            scope.setExtra('Z2Token', localData.token);
            scope.setExtra('tempData', localData.tempData);
            Sentry.captureMessage('가입완료 화면 unmount 시');
        });
    }

    getLocalData = () => {
        const token = getItem('Z2Token');
        const tempData = getItem('Z2TempData');
        if (!token || !tempData) return;
        return {
            token,
            tempData,
        };
    };

    attachEvent = () => {
        window.addEventListener(
            'message',
            event => {
                try {
                    const msg = JSON.parse(event.data);

                    if (msg.seq !== 'Z2Token' || msg.seq !== 'Z2TempData') {
                        return;
                    }
                    Sentry.captureMessage(`attachEvent ${msg.seq}`);
                    if (!msg || !msg.data || !msg.seq) return;

                    if (msg.seq === 'Z2Token')
                        setItem(msg.seq, msg.data, TOKEN_TIMEOUT);
                    if (msg.seq === 'Z2TempData')
                        setItem(msg.seq, msg.data, TEMP_DATA_TIMEOUT);
                    !this.state.hasRegistered && this.handleRegister();
                } catch (e) {
                    Sentry.captureException(`Get PostMessage Error ${e}`);
                }
            },
            false,
        );
    };

    getPostMessage = key => {
        const pkt = {
            command: 'getItem',
            key,
            value: '',
            seq: key,
        };

        Sentry.captureMessage(`getPostMessage ${key}`);
        window.frames['zikto'] &&
            window.frames['zikto'].postMessage(JSON.stringify(pkt), '*');
    };

    hasData = tempData => {
        return (
            tempData.users &&
            tempData.insuType &&
            tempData.startDate &&
            tempData.endDate &&
            tempData.startTime != null &&
            tempData.endTime != null &&
            tempData.jms &&
            tempData.names &&
            tempData.paidAmount != null &&
            tempData.paidZiktoCash != null &&
            tempData.paidTravelPoint != null
        );
    };

    handleRegister = async () => {
        let parsedTempData;

        if (!this.token || !this.tempData)
            return this.props.history.replace('/login');

        await Sentry.withScope(scope => {
            scope.setExtra('Z2Token', this.token);
            scope.setExtra('tempData', this.tempData);
            Sentry.captureMessage('보험가입전');
        });

        try {
            parsedTempData = JSON.parse(this.tempData);

            if (
                Object.keys(parsedTempData).length < 1 ||
                this.isPaymentSuccess === 'false' ||
                !this.hasData(parsedTempData)
            )
                throw new Error();
        } catch (e) {
            return this.setState({ hasError: true, isLoaded: true });
        }

        const paramObj = {
            users: parsedTempData.users,
            type: parsedTempData.insuType,
            startDate: `${parsedTempData.startDate}${padDigits(
                parsedTempData.startTime,
                2,
            )}`,
            endDate: `${parsedTempData.endDate}${padDigits(
                parsedTempData.endTime,
                2,
            )}`,
            joomin: parsedTempData.jms,
            payment: this.impId,
            names: parsedTempData.names,
            email: parsedTempData.email,
            krw: parsedTempData.paidAmount,
            ziktoCash: parsedTempData.paidZiktoCash,
            travelPoint: parsedTempData.paidTravelPoint,
        };

        try {
            await this.props
                .apiRequest({
                    type: INSURANCE_REGISTER,
                    path: INSURANCE_REGISTER_URL,
                    data: paramObj,
                    config: {
                        timeout: INSURANCE_REGISTER_TIMEOUT,
                    },
                })
                .then(async response => {
                    if (!response || response.code !== 200)
                        return Promise.reject(
                            new Error(
                                response
                                    ? response.message
                                    : '응답이 없습니다.',
                            ),
                        );
                    await this.props.authUpdate(2);
                    this.props.history.replace(
                        `/purchase/confirmed/${response.order.id}`,
                    );
                })
                .catch(e => {
                    this.setState({
                        hasRegisterError: true,
                        hasErrorAfterPay: true,
                    });
                    return Promise.reject(e);
                })
                .finally(() => {
                    this.setState({
                        hasRegistered: true,
                    });
                });
        } catch (e) {
            errorHandler(e, INSURANCE_REGISTER_URL, this.props.tempData.userId);
        }
    };

    goToMyPage = () => {
        this.props.history.replace('/purchase/step2');
    };

    handleConfirmErrorAfterPay = () => {
        this.setState({
            hasErrorAfterPay: false,
        });
    };

    renderErrorAfterPayModal = () => {
        return (
            <div>
                <div className="header">서버오류로 진행이 불가합니다.</div>
                <div className="contents">
                    <div>
                        <div style={{ marginBottom: 20 }}>
                            contact@zikto.com 으로 연락주시면
                            <br />
                            환불처리를 도와드리겠습니다.
                            <br />
                            죄송합니다.
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { isLoaded, hasError } = this.state;

        return (
            <div className="wrap-area">
                {!isLoaded && <div className="loader" />}

                {isLoaded && hasError && (
                    <ErrorModal
                        onDismiss={this.goToMyPage}
                        msg={`인증이 취소되었거나 실패하였습니다. \n다시 시도하여 주십시요.`}
                    />
                )}

                {this.state.hasErrorAfterPay && (
                    <Modal
                        content={this.renderErrorAfterPayModal()}
                        onDismiss={this.handleConfirmErrorAfterPay}
                        style={{ width: 500, height: 250 }}
                    />
                )}

                <div>
                    <iframe
                        src="https://zikto.com/broker.html"
                        name="zikto"
                        onLoad={() => {
                            this.setState({ ifrLoaded: true });
                        }}
                        title="iframe"
                        className={`${this.state.ifrLoaded ? '' : 'hidden'}`}
                        style={{
                            position: 'absolute',
                            width: 1,
                            height: 1,
                            overflow: 'hidden',
                            zIndex: -1,
                        }}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    tempData: state.temp,
});

const mapDispatchToProps = dispatch => ({
    apiRequest: bindActionCreators(apiRequest, dispatch),
    authUpdate: bindActionCreators(authActions.update, dispatch),
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Step3),
);
