import { TEMP_DATA_SAVE, TEMP_DATA_REMOVE } from './types';
import { removeItem } from 'utils/handleStorage';

export default {
    saveTempData,
    removeTempData,
};

function saveTempData(data) {
    return { type: TEMP_DATA_SAVE, data };
}

function removeTempData() {
    removeItem('Z2TempData');
    return { type: TEMP_DATA_REMOVE };
}
