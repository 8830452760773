import React, { Component } from 'react';
import Modal from 'components/Modal';
import ProductDetail from 'pages/Purchase/Step1/ProductDetail';

class DisplayInsuranceDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: false,
        };
    }

    toggleModal = () => {
        this.setState({
            isModalOpen: !this.state.isModalOpen,
        });
    };

    renderInsuDetails = () => {
        const { insuType, age, period } = this.props;
        if (insuType == null || age == null || period == null) return null;
        return <ProductDetail type={insuType} age={age} period={period} />;
    };

    render() {
        return (
            <div>
                <div className="form-group">
                    <button
                        type="button"
                        className="btn block outline"
                        onClick={this.toggleModal}
                    >
                        <b>보장 내용 확인</b>
                    </button>
                </div>
                {this.state.isModalOpen && (
                    <Modal
                        content={this.renderInsuDetails()}
                        // style={{ width: 335, height: 640 }}
                        className="insurance-detail-modal"
                        buttonText="닫기"
                        onDismiss={this.toggleModal}
                    />
                )}
            </div>
        );
    }
}

export default DisplayInsuranceDetail;
