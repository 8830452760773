import { IncomingWebhook } from '@slack/webhook';

const url =
    process.env.SLACK_WEBHOOK_URL ||
    'https://hooks.slack.com/services/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';

const webhook = new IncomingWebhook(url);
const slackChannels = {
    test: '#policy-promy',
    development: '#policy-promy',
    production: '#policy-promy',
};

const __send = payload => {
    return webhook
        .send(payload, (err, res) => {
            if (err) {
                console.log(`Error: ${err}`);
            }
        })
        .catch(console.log);
};

// eslint-disable-next-line import/prefer-default-export
export const slack = (
    text = 'notification!',
    remote_addr = '',
    username = 'policy-bot-promy',
) => {
    try {
        const From = remote_addr ? `from ${remote_addr}` : '';
        const message = `${text} ${From}`;

        const payload = {
            username: username,
            channel: slackChannels[process.env.NODE_ENV],
            text: `<${process.env.NODE_ENV}> ${message}`,
        };
        return __send(payload);
    } catch (e) {
        console.log(e);
    }
};
