import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Formsy, { addValidationRule } from 'formsy-react';
import qs from 'qs';
// constants
import {
    PASSWORD_RESET_CONFIRM,
    PASSWORD_RESET_CONFIRM_URL,
} from 'constants/paths';
// redux
import { apiRequest } from 'redux/actions/api/utils';
// sub components
import Modal from 'components/Modal';
import Nav from 'components/Nav';
import Input from 'components/Input';
import ErrorModal from 'components/ErrorModal';
// utils
import {
    isValidPassword,
    hasNoSpace,
    hasNumber,
    hasLetter,
} from 'utils/validate';
import { errorHandler } from 'utils/errorHandler';
// custom validations
addValidationRule('isValidPassword', isValidPassword);
addValidationRule('hasNoSpace', hasNoSpace);
addValidationRule('hasNumber', hasNumber);
addValidationRule('hasLetter', hasLetter);

class NewPassword extends Component {
    constructor(props) {
        super(props);

        const resetToken = qs.parse(window.location.search, {
            ignoreQueryPrefix: true,
        });

        this.state = {
            canSubmit: false,
            isPristine: true,
            isModalOpen: false,
            resetToken: resetToken.reset_token,
            hasError: false,
            errorMessage: '',
        };

        this.formRef = React.createRef();
    }

    resetForm = () => {
        this.setState({ hasError: false }, () => {
            this.formRef.current.reset();
        });
    };

    goToLogin = () => {
        this.props.history.replace('/login');
    };

    handleSubmit = async data => {
        try {
            await this.props
                .apiRequest({
                    type: PASSWORD_RESET_CONFIRM,
                    path: PASSWORD_RESET_CONFIRM_URL,
                    data: {
                        password: data.password,
                        resetToken: this.state.resetToken,
                        from: 'web',
                    },
                    config: {
                        headers: {
                            Authorization: null,
                        },
                    },
                })
                .then(response => {
                    if (response.code !== 200)
                        return Promise.reject(new Error(response.message));
                    this.toggleModal();
                })
                .catch(e => {
                    this.setState({
                        hasError: true,
                        errorMessage: e.message,
                    });
                    return Promise.reject(e);
                });
        } catch (e) {
            errorHandler(
                e,
                PASSWORD_RESET_CONFIRM_URL,
                this.props.tempData.userId,
            );
        }
    };

    handleFormChange = currentValues => {
        // 휴대폰 번호가 유효하면 인증번호 받기 버튼 활성화
        this.setState({
            isPristine: false,
        });
    };

    enableButton = () => {
        this.setState({ canSubmit: true });
    };

    disableButton = () => {
        this.setState({ canSubmit: false });
    };

    notifyFormError = data => {
        console.log('Form error:', data);
    };

    toggleModal = () => {
        this.setState({
            isModalOpen: !this.state.isModalOpen,
        });
    };

    renderModal = () => {
        return (
            <div>
                <div className="header">비밀번호가 재설정되었습니다.</div>
                <div className="contents">
                    <div>다시 한번 로그인해주세요.</div>
                </div>
            </div>
        );
    };

    render() {
        const { canSubmit, isPristine } = this.state;

        return (
            <div className="account login container-fixed">
                <Nav className="light" />
                <div className="mobile-title">
                    <span>비밀번호 재설정</span>
                </div>
                <div className="container">
                    <div className="page-header">
                        <h1>비밀번호 재설정</h1>
                    </div>
                    <div className="wrap">
                        <Formsy
                            onValidSubmit={this.handleSubmit}
                            onValid={this.enableButton}
                            onInvalid={this.disableButton}
                            onInvalidSubmit={this.notifyFormError}
                            onChange={this.handleFormChange}
                            className={`form login-form ${
                                isPristine ? 'is-pristine' : ''
                            }`}
                            ref={this.formRef}
                        >
                            <h2 className="h2">
                                새로운 비밀번호를 입력하세요.
                            </h2>
                            <Input
                                required
                                type="password"
                                name="password"
                                title="비밀번호"
                                validations="minLength:8,maxLength:35,hasNumber,hasLetter,hasNoSpace"
                                validationError="8-35자의 문자,숫자를 조합하여 설정하세요."
                                validationErrors={{
                                    hasNoSpace: '공백없이 입력하세요.',
                                }}
                                className="inline"
                                inputClassName="block"
                                placeholder="비밀번호를 입력하세요."
                            />
                            <Input
                                required
                                type="password"
                                name="passwordConfirm"
                                title={`비밀번호 \n확인`}
                                validations="isValidPassword"
                                validationError="비밀번호가 일치하지 않습니다."
                                inputClassName="block"
                                className="inline"
                                placeholder="비밀번호를 다시 입력하세요."
                            />

                            <div className="form-group">
                                <button
                                    type="submit"
                                    className="btn block basic"
                                    disabled={!canSubmit}
                                >
                                    비밀번호 재설정
                                </button>
                            </div>
                        </Formsy>
                    </div>
                    {this.state.isModalOpen && (
                        <Modal
                            content={this.renderModal()}
                            onDismiss={this.goToLogin}
                            style={{ width: 500, height: 199 }}
                            buttonText="로그인"
                        />
                    )}
                    {this.state.hasError && (
                        <ErrorModal
                            onDismiss={this.resetForm}
                            msg={this.state.errorMessage}
                        />
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    tempData: state.temp,
});

const mapDispatchToProps = dispatch => ({
    apiRequest: bindActionCreators(apiRequest, dispatch),
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(NewPassword),
);
