import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import Formsy from 'formsy-react';
// redux
import { apiRequest } from 'redux/actions/api/utils';
// sub components
import Modal from 'components/Modal';
import InsuranceDetail from 'components/InsuranceDetail';
import DisplayInsuranceDetail from 'components/DisplayInsuranceDetail';
// constants
import {
    MY_ORDER,
    MY_ORDER_URL,
    USER_INFO,
    USER_INFO_URL,
    INSURANCE_CANCEL,
    INSURANCE_CANCEL_URL,
} from 'constants/paths';
import { INSURANCE_REGISTER_TIMEOUT } from 'constants/timeout';
// utils
import getAge from 'utils/getAge';
import getTravelDays from 'utils/getTravelDays';
import { errorHandler } from 'utils/errorHandler';

class MyPageInsurance extends PureComponent {
    myZikto = null;
    state = {
        isCancelStartModalOpen: false,
        isLoaded: false,
        isCancelModalOpen: false,
        isCancelConfirmModalOpen: false,
        isDetailModalOpen: false,
        isEngCertificateModal: false,
        isKorCertificateModal: false,
        isCancelFailModalOpen: false,
        cancelErrorMsg: '',
        cancelableStatus: '',
        cancelableNo: '',
    };

    async componentDidMount() {
        this._mounted = true;

        this.orderId = this.props.match.params.orderId;

        await Promise.all([this.fetchUserInfo(), this.fetchData()]);
        this.fetchCancelable();
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    fetchUserInfo = async () => {
        try {
            await this.props
                .apiRequest({
                    type: USER_INFO,
                    path: USER_INFO_URL,
                })
                .then(response => {
                    this._mounted &&
                        this.setState({
                            phoneNumber: response.phoneNumber,
                        });
                })
                .catch(e => {
                    return Promise.reject(e);
                });
        } catch (e) {
            errorHandler(e, USER_INFO_URL, this.props.tempData.userId);
        }
    };

    fetchData = async () => {
        try {
            await this.props
                .apiRequest({
                    type: MY_ORDER,
                    path: `${MY_ORDER_URL}/${this.orderId}`,
                    method: 'get',
                })
                .then(response => {
                    if (!response || response.code !== 200) {
                        return Promise.reject(new Error(response.message));
                    }

                    this.orderDetails = response.order;
                    if (response.order.length < 1) return;

                    const order = Object.create(response.order[0]);

                    const startDateObj = moment(
                        order.startDate.substring(0, 8),
                    ).toDate();
                    const endDateObj = moment(
                        order.endDate.substring(0, 8),
                    ).toDate();

                    this.setState({
                        startDate: order.startDate,
                        endDate: order.endDate,
                        contactDate: order.createdAt,
                        name: order.name,
                        amount: order.amount,
                        birthday: order.birthday,
                        joomin: order.joomin,
                        status: order.status,
                        type: order.type,
                        age: getAge(order.birthday.substring(2)),
                        period: getTravelDays({
                            startDate: startDateObj,
                            endDate: endDateObj,
                            startTime: order.startDate.substring(8),
                            endTime: order.endDate.substring(8),
                        }),
                        price: response.order.reduce(
                            (acc, curr) => acc + curr.amount,
                            0,
                        ),
                        companions: response.order.slice(1),
                    });
                })
                .catch(e => {
                    return Promise.reject(e);
                });
        } catch (e) {
            errorHandler(
                e,
                `${MY_ORDER_URL}/${this.orderId}`,
                this.props.tempData.userId,
            );
        }
    };

    fetchCancelable = async () => {
        try {
            await this.props
                .apiRequest({
                    type: MY_ORDER,
                    path: `${MY_ORDER_URL}/${this.orderId}`,
                })
                .then(response => {
                    if (!response || response.code !== 200 || !response.order) {
                        return Promise.reject(
                            new Error(response.message || ''),
                        );
                    }

                    this.setState({
                        isLoaded: this._mounted ? true : false,
                        cancelableStatus:
                            response.order.status &&
                            response.order.status.trim(),
                        cancelableNo: response.order.plno,
                        paymentStatus:
                            response.order.paymentStatus &&
                            response.order.paymentStatus.trim(),
                        status: response.order.status
                            ? response.order.status.trim()
                            : this.state.status,
                    });
                })
                .catch(e => {
                    return Promise.reject(e);
                });
        } catch (e) {
            errorHandler(
                e,
                `${MY_ORDER_URL}/${this.orderId}`,
                this.props.tempData.userId,
            );
        }
    };

    toggleModal = modalName => {
        return () => {
            this.setState({
                [`is${modalName}Open`]: !this.state[`is${modalName}Open`],
            });
        };
    };

    closeModal = modalName => {
        return () => {
            console.log('closeModal', modalName);
            this.setState({
                [`is${modalName}Open`]: false,
            });
        };
    };

    handleCancelStart = () => {
        this.setState({
            isCancelStartModalOpen: true,
        });
    };

    cancelInsurance = async () => {
        try {
            this.setState({
                isCancelStartModalOpen: false,
                isCancelConfirmModalOpen: true,
            });
            await this.props
                .apiRequest({
                    type: INSURANCE_CANCEL,
                    path: `${INSURANCE_CANCEL_URL}/${this.orderId}`,
                    config: {
                        timeout: INSURANCE_REGISTER_TIMEOUT,
                    },
                })
                .then(() => {})
                .catch(e => {
                    return Promise.reject(e);
                });
        } catch (e) {
            errorHandler(e, INSURANCE_CANCEL_URL, this.props.tempData.userId);
        }
    };

    renderCancelConfirmModal = () => {
        return (
            <div>
                <div className="header">보험 가입 해지 처리 중입니다</div>
                <div className="content">
                    <div style={{ marginBottom: 20, textAlign: 'center' }}>
                        해지가 정상적으로 완료되면
                        <br />
                        해지완료안내 문자를 보내드리겠습니다.
                        <br />
                        이용해주셔서 감사합니다.
                    </div>
                </div>
            </div>
        );
    };

    renderCertificate() {
        return (
            <div>
                <div className="header">영문 보험가입증명서 발급</div>
                <div className="contents">
                    <div style={{ marginBottom: 20 }}>
                        영문 보험가입증명서를 발급받으시려면
                        <br /> 회원님의 여권영문이름이 필요합니다.
                    </div>
                    <div
                        className="flex justify-content-center align-items-center"
                        style={{ textAlign: 'left' }}
                    >
                        <Formsy>
                            <div className="user-form flex">
                                <label
                                    style={{
                                        fontWeight: 600,
                                        alignItems: 'center',
                                        display: 'flex',
                                    }}
                                >
                                    여권
                                    <br />
                                    영문이름
                                </label>
                                <input
                                    type="text"
                                    name="firstName"
                                    className="input block"
                                    //onChange={this.handleChange}
                                    placeholder="성"
                                    style={{ width: 80, marginLeft: 11 }}
                                />
                                <input
                                    type="text"
                                    name="firstName"
                                    className="input block"
                                    //onChange={this.handleChange}
                                    placeholder="이름"
                                    style={{ width: 135, marginLeft: 11 }}
                                />
                            </div>
                        </Formsy>
                    </div>
                </div>
            </div>
        );
    }

    renderConfirmModal = () => {
        return (
            <div>
                <div className="header">보험 가입 취소 완료</div>
                <div className="contents" style={{ marginTop: 20 }}>
                    <div>보험 가입이 정상적으로 취소되었습니다.</div>
                </div>
            </div>
        );
    };

    renderCancelFailModal = () => {
        return (
            <div>
                <div className="header">{this.state.cancelErrorMsg}</div>
            </div>
        );
    };

    renderCancelStartModal = () => {
        return (
            <div>
                <div className="header">보험 가입을 해지하시겠습니까?</div>
                <div className="mb-20 text-center">
                    입력하신 정보는 저장되지 않습니다.
                </div>
                <div style={{ width: '70%', margin: 'auto', marginTop: 30 }}>
                    <div className="row gutter">
                        <div className="col">
                            <button
                                type="button"
                                className="btn block outline"
                                onClick={() => {
                                    this.setState({
                                        isCancelStartModalOpen: false,
                                    });
                                }}
                            >
                                <b>닫기</b>
                            </button>
                        </div>
                        <div className="col">
                            <button
                                type="button"
                                className="btn block basic"
                                onClick={this.cancelInsurance}
                            >
                                <b>가입 해지</b>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { isLoaded } = this.state;
        // TODO: MY_ORDER api의 get, post의 응답값에 일관성이 없다.
        // 이 데이터에서 status가 null이면  환불완료가 되어 보험 가입내역이 없다고 보여준다.
        return (
            <div>
                {!isLoaded && <div className="loader" />}

                {isLoaded &&
                    (!this.orderDetails ||
                        this.orderDetails.length < 1 ||
                        !this.state.status) && (
                        <div className="text-center">가입 내역이 없습니다.</div>
                    )}

                {isLoaded &&
                    (this.orderDetails &&
                        this.state.status &&
                        this.orderDetails.length > 0) && (
                        <div style={{ width: '100%' }}>
                            <div className="section">
                                <div className="content">
                                    <div className="progress progress-3" />
                                    <h2 className="h2">
                                        해외여행보험 가입 내역
                                    </h2>
                                    <DisplayInsuranceDetail
                                        insuType={this.state.type}
                                        age={this.state.age}
                                        period={this.state.period}
                                    />
                                    <InsuranceDetail
                                        startDate={this.state.startDate}
                                        endDate={this.state.endDate}
                                        contactDate={this.state.contactDate}
                                        email={this.state.email}
                                        phoneNumber={this.state.phoneNumber}
                                        name={this.state.name}
                                        amount={this.state.amount}
                                        birthday={this.state.birthday}
                                        joomin={this.state.joomin}
                                        status={this.state.status}
                                        paymentStatus={this.state.paymentStatus}
                                        type={this.state.type}
                                        price={this.state.price}
                                        onCancel={this.handleCancel}
                                        onCancelStart={this.handleCancelStart}
                                        companions={this.state.companions}
                                        cancelableStatus={
                                            this.state.cancelableStatus
                                        }
                                        cancelableNo={this.state.cancelableNo}
                                    />
                                    <div
                                        className="footnote sm"
                                        style={{
                                            textAlign: 'center',
                                            color: '#000',
                                        }}
                                    >
                                        보험 개시 전 15분 이후로는 가입해지가
                                        불가합니다.
                                    </div>
                                    {/* <button
                                        type="button"
                                        className="btn block outline"
                                        style={{ marginBottom: 20 }}
                                        onClick={this.toggleModal(
                                            'EngCertificateModal',
                                        )}
                                    >
                                        영문 보험가입증명서 발급
                                    </button>
                                    <button
                                        type="button"
                                        className="btn block outline"
                                        onClick={this.toggleModal(
                                            'KorCertificateModal',
                                        )}
                                    >
                                        국문 보험가입증명서 재발급
                                    </button> */}
                                </div>
                            </div>
                        </div>
                    )}

                {this.state.isCancelConfirmModalOpen && (
                    <Modal
                        content={this.renderCancelConfirmModal()}
                        onDismiss={() => {
                            this.props.history.replace('/my-page');
                        }}
                    />
                )}

                {this.state.isEngCertificateModalOpen && (
                    <Modal
                        content={this.renderCertificate()}
                        onDismiss={this.toggleModal('EngCertificateModal')}
                    />
                )}

                {this.state.isCancelStartModalOpen && (
                    <Modal
                        content={this.renderCancelStartModal()}
                        onDismiss={() => {
                            this.setState({ isCancelStartModalOpen: false });
                        }}
                        hasActions={true}
                    />
                )}

                {this.state.isCancelFailModalOpen && (
                    <Modal
                        content={this.renderCancelFailModal()}
                        onDismiss={() => {
                            this.setState({ isCancelFailModalOpen: false });
                        }}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    tempData: state.temp,
});

const mapDispatchToProps = dispatch => ({
    apiRequest: bindActionCreators(apiRequest, dispatch),
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(MyPageInsurance),
);
