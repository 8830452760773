import React, { PureComponent } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
// css
import './style.css';

class Nav extends PureComponent {
    state = {
        isNavOpen: false,
    };

    toggleNav = () => {
        this.setState({
            isNavOpen: !this.state.isNavOpen,
        });
    };

    render() {
        const { isNavOpen } = this.state;

        const className = `${
            this.props.className ? this.props.className : ''
        } ${isNavOpen ? 'is-nav-open' : ''}`;

        return (
            <div className={className}>
                <Link to="/">
                    <h1 className="h1">
                        <span className="screen-reader">
                            ZIKTO Insurance Agency
                        </span>
                    </h1>
                </Link>

                <div className="home-utils">
                    <Link
                        to={!this.props.auth.level ? '/login' : '/my-page'}
                        className="btn"
                    >
                        <i className="fal fa-user-alt" />
                    </Link>
                    <button
                        type="button"
                        className={`btn ${isNavOpen ? 'is-open' : ''}`}
                        onClick={this.toggleNav}
                    >
                        <i className="i-bar" />
                        <i className="i-bar" />
                        <i className="i-bar" />
                    </button>
                </div>
                <div
                    className={`nav-wrap container-fixed ${
                        isNavOpen ? 'is-open' : ''
                    }`}
                >
                    <section className="navs">
                        <nav className="nav">
                            <a
                                href="/personal-product"
                                className="btn nav-link"
                            >
                                개인 보험
                            </a>
                            <a
                                href="/business-product"
                                className="btn nav-link"
                            >
                                기업 보험
                            </a>
                            {/* <a href="/" className="btn nav-link" style={{ opacity: 0.5 }}>
                공항 환전
              </a> */}
                            <a href="/About" className="btn nav-link">
                                About us
                            </a>
                        </nav>
                    </section>
                    <div className="infos">
                        <section className="info">
                            <div className="info-top">
                                <div className="info-socials">
                                    <a
                                        href="https://medium.com/@insureum_zikto"
                                        className="btn"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <i className="fab fa-medium-m" />
                                    </a>
                                    <a
                                        href="https://www.youtube.com/channel/UCMcCdwW8V3r4uSTY2qxdNIA"
                                        className="btn"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <i className="fab fa-youtube" />
                                    </a>
                                    <a
                                        href="https://www.facebook.com/insureum/"
                                        className="btn"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <i className="fab fa-facebook" />
                                    </a>
                                    <a
                                        href="https://www.instagram.com/insureum_by_zikto"
                                        className="btn"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <i className="fab fa-instagram" />
                                    </a>
                                </div>
                                <div className="info-mail">
                                    <a
                                        style={{ fontWeight: 600 }}
                                        href="mailto:contact@zikto.com"
                                        className="btn"
                                    >
                                        Contact@zikto.com
                                    </a>
                                </div>
                            </div>
                            <div className="info-links">
                                <a
                                    href="https://walk.zikto.com/"
                                    className="btn info-link-zw"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        src={'/imgs/common/zikto_icon.png'}
                                        alt=""
                                    />
                                    <span style={{fontWeight: 400, marginTop: 10}}>
                                        직토
                                    </span>
                                    <span className="screen-reader">
                                        ZIKTO WALK
                                    </span>
                                </a>
                                <a
                                    href="https://challenge.zikto.com/kb/index.html"
                                    className="btn info-link-tc"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        src={'/imgs/common/challenge_icon.png'}
                                        alt=""
                                    />
                                    <span style={{fontWeight: 400, marginTop: 10}}>
                                        더챌린지
                                    </span>
                                    <span className="screen-reader">
                                        THE CHALLENGE
                                    </span>
                                </a>
                                <a
                                    href="https://ulmaya.zikto.com/"
                                    className="btn info-link-um"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        src={'/imgs/common/ulmaya_icon.png'}
                                        alt=""
                                    />
                                    <span style={{fontWeight: 400, marginTop: 10}}>
                                        얼마야
                                    </span>
                                    <span className="screen-reader">
                                        얼마야
                                    </span>
                                </a>
                            </div>
                            <div className="info-copyright">
                                (주)직토 대리점 등록번호: 2019010004
                                <div>
                                    <span>Copyright © 2019</span>
                                    <span>|</span>
                                    <span>Zikto Inc. All rights reserved.</span>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
});

const mapDispatchToProps = dispatch => ({});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Nav),
);
