// import
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// sub Components
import Input from 'components/Input';
import ImageUpload from 'components/ImageUpload';

// redux
import { apiRequest } from 'redux/actions/api/utils';
import authActions from 'redux/actions/auth';

import PropTypes from 'prop-types';
import ChatPhoneVerification from 'components/ChatPhoneVerification/Number';
import Formsy from 'formsy-react';
import { addValidationRule } from 'formsy-react'; // wait

// constants
import {
    FETCH_SMS_CODE,
    FETCH_SMS_CODE_URL,
    VERIFY_SMS_CODE,
    VERIFY_SMS_CODE_URL,
} from 'constants/paths'; // wait
import { errorHandler } from 'utils/errorHandler';
// utils
import { isMobile, isValidEmailFormat } from 'utils/validate'; // wait
import { slack } from 'utils/slack-notifications';

// css
import './style.css';
import Inner from '../Modal/Inner';
import { AutoFocusInside } from 'react-focus-lock';
import { SSL_OP_SSLEAY_080_CLIENT_DH_BUG } from 'constants';

const GREETING_STATE = 0;
const SMS_CONFIRM_STATE = 1;
const SMS_SENDING_STATE = 2;
const EMAIL_ENTER_STATE = 3;
const EMAIL_CONFIRM_STATE = 4;
const PAPER_ENTER_STATE = 5;
const REGISTER_CONFIRM_STATE = 6;
const REVISIT_STATE = 41;

// greeting TEXT
const UserChatText = props => (
    <div className="chat-sent-body">
        <div className="chat-sent">{props.text}</div>
    </div>
);

const UserImageUpload = data => (
    <div className="chat-sent-body">
        <div className="chat-sent">
            <ImageUpload
                email={data.email}
                phoneNumber={data.phoneNumber}
                onComplete={data.handleImageChange}
            />
        </div>
    </div>
);

const UserLoading = props => (
    <div>
        <div className="chat-received-body">
            <p className="chat-zikto">
                <img src={'/imgs/common/zikto-symbol.png'} alt="zikto-symbol" />
            </p>
            <div className="chat-received">
                <div className="chat-loader" />
            </div>
        </div>
    </div>
);

const BotChatText = props => (
    <div>
        <div className="chat-received-body">
            <p className="chat-zikto">
                <img src={'/imgs/common/zikto-symbol.png'} alt="zikto-symbol" />
            </p>
            <div className="chat-received">{props.text}</div>
        </div>
    </div>
);

// Request Phone Number
// Validate Phone Number
const BotReqPhoneNumber = props => (
    <div>
        <div className="chat-received-body">
            <p className="chat-zikto">
                <img src={'/imgs/common/zikto-symbol.png'} alt="zikto-symbol" />
            </p>
            <div className="chat-received">{props.text}</div>
        </div>
    </div>
);

// Request SMS Number
// Validate SMS Number
const BotChatPhoneVerification = props => (
    <div>
        <div className="chat-received-body">
            <p className="chat-zikto">
                <img src={'/imgs/common/zikto-symbol.png'} alt="zikto-symbol" />
            </p>
            <div className="chat-received">
                {props.text}
                <button
                    className="btn block white-btn"
                    onClick={props.reSendAuthCode()}
                    style={{
                        marginTop: 10,
                        color: '#000',
                        border: '1px solid #000',
                    }}
                >
                    인증번호 재전송
                </button>
            </div>
        </div>
    </div>
);

// Request click button
// Yes or No
const BotChatButton = props => (
    <div>
        <div className="chat-received-body">
            <p className="chat-zikto">
                <img src={'/imgs/common/zikto-symbol.png'} alt="zikto-symbol" />
            </p>
            <div className="chat-received">
                <div className="btn-area">
                    <button
                        className="btn block white-btn"
                        style={{
                            marginRight: 10,
                            color: '#000',
                            border: '1px solid #000',
                        }}
                    >
                        {props.agreeBtn}
                    </button>
                    <button
                        className="btn block white-btn"
                        style={{ color: '#000', border: '1px solid #000' }}
                    >
                        {props.disagreeBtn}
                    </button>
                </div>
            </div>
        </div>
    </div>
);

const EmailCheck = props => (
    <div>
        <div className="chat-received-body">
            <p className="chat-zikto">
                <img src={'/imgs/common/zikto-symbol.png'} alt="zikto-symbol" />
            </p>
            <div className="chat-received">
                {props.text}
                <div className="btn-area" style={{ marginTop: 10 }}>
                    <button
                        className="btn block white-btn"
                        style={{
                            marginRight: 10,
                            color: '#000',
                            border: '1px solid #000',
                        }}
                        onClick={props.select(true)}
                    >
                        {props.agreeBtn}
                    </button>
                    <button
                        className="btn block white-btn"
                        style={{
                            color: '#000',
                            border: '1px solid #000',
                        }}
                        onClick={props.select(false)}
                    >
                        {props.disagreeBtn}
                    </button>
                </div>
            </div>
        </div>
    </div>
);

const greetingState = [
    <BotChatText
        text={
            '안녕하세요! 직토 도우미 봇입니다.\n제가 보험 가입을 도와드릴게요.'
        }
    />,
    <BotReqPhoneNumber
        text={
            '휴대폰 번호 인증이 필요합니다.\n연락 가능한 휴대폰 번호를 입력해주세요.'
        }
    />,
];

// Phone Valid State
// valid
const smsConfirmStateValid = (phoneNumber, reSendAuthCode) => {
    return [
        <UserChatText text={phoneNumber} />,
        <BotChatPhoneVerification
            text={`입력하신 번호로 인증번호가 전송되었습니다.\n 받으신 인증번호를 입력해주세요.\n 인증번호를 받지 못하셨다면 재전송 버튼을 눌러주세요.`}
            reSendAuthCode={reSendAuthCode}
        />,
    ];
};

const smsConfirmStateReValid = (phoneNumber, reSendAuthCode) => {
    return [
        <UserChatText text={phoneNumber} />,
        <BotChatPhoneVerification
            text={`인증번호를 다시 전송 하였습니다. 인증번호를 받지 못하셨다면 재전송 버튼을 눌러주세요.`}
            reSendAuthCode={reSendAuthCode}
        />,
    ];
};

// invalid
const smsConfirmStateInvaild = phoneNumber => {
    return [
        <UserChatText text={phoneNumber} />,
        <BotChatText text={'휴대폰 번호를 다시 한 번 확인해주세요.'} />,
    ];
};

// SMS Valid State
// valid
const smsSendingValid = authCodeNumber => {
    return [
        <UserChatText text={authCodeNumber} />,
        <BotChatText text={'휴대폰 번호 인증에 성공했습니다.'} />,
        <BotChatText
            text={
                '귀사의 생산물배상책임보험(프로미센터 전용) 청약서 및 보험료 안내를 위해 이메일 주소를 입력해 주시기 바랍니다.'
            }
        />,
    ];
};

// invalid
const smsSendingInvalid = authCodeNumber => {
    return [
        <UserChatText text={authCodeNumber} />,
        <BotChatText text={'인증번호를 확인해주세요.'} />,
    ];
};

const emailCheckState = (email, select) => {
    return [
        <UserChatText text={email} />,
        <EmailCheck
            text={`입력하신 이메일 주소가\n${email}이 맞습니까?`}
            agreeBtn={'예'}
            disagreeBtn={'아니오'}
            select={select}
        />,
    ];
};

const emailCheckFalse = email => {
    return [
        <UserChatText text={email} />,
        <BotChatText text={'이메일을 다시 입력해주세요.'} />,
    ];
};

const emailCheckTrue = data => {
    console.log(data);
    return [
        <UserChatText text={'예'} />,
        <BotChatText text={'이메일 주소를 확인해주셔서 감사합니다.'} />,
        <BotChatText
            text={
                '귀사의 보험 가입 심사를 위해 기초 서류 등록이 필요합니다. 사업자 등록증 스캔 본 또는 내용이 잘 나온 사진을 업로드 해주세요.'
            }
        />,
        <UserImageUpload
            email={data.email}
            phoneNumber={data.phoneNumber}
            handleImageChange={data.handleImageChange}
        />,
    ];
};

const imageUploadCheck = email => {
    return [
        <BotChatText text={`이미지 업로드에 성공하였습니다.`} />,
        <BotChatText
            text={`서류가 접수되었습니다. 심사 후 입력하신 이메일 주소 ${email}로 익일내에 (영업일 기준) 귀사의 보험료 및 청약서를 안내 드리겠습니다. \n본 보험 관련해서 궁금하신 사항이 있으시면, contact@zikto.com 으로 문의 바랍니다. 감사합니다.`}
        />,
    ];
};

const emailFalse = () => {};

class Chat extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // file: '',
            // imagePreviewUrl: '',
            isLoaded: false,
            currentState: 0,
            phoneNumber:
                this.props.phoneNumber ||
                this.props.tempData.phoneNumber ||
                undefined,
            isPhoneNumberConfirmed:
                this.props.phoneNumber || this.props.tempData.phoneNumber
                    ? true
                    : false,
            authCodeNumber: '',
            email: '',
            confirm: false,
            chattingList: greetingState,
            value: '',
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleStateGreeting5EmailConfirm = this.handleStateGreeting5EmailConfirm.bind(
            this,
        );
        this.handleStateGreeting5EmailFalse = this.handleStateGreeting5EmailFalse.bind(
            this,
        );
        this.handleStateGreeting6ImageUpload = this.handleStateGreeting6ImageUpload.bind(
            this,
        );
    }

    reGetAuthCode = async () => {
        console.log('asd');
        console.log(this.state.currentState);
        const params = {
            phoneNumber: this.state.phoneNumber,
        };

        if (localStorage.getItem('development_secret')) {
            params.env = localStorage.getItem('development_secret');
        }
        try {
            await this.props
                .apiRequest({
                    type: FETCH_SMS_CODE,
                    path: FETCH_SMS_CODE_URL,
                    data: params,
                    config: {
                        headers: {
                            Authorization: null,
                        },
                    },
                })
                .then(response => {
                    if (response.code !== 200)
                        return Promise.reject(new Error(response.message));
                    // 성공시
                    this.setState({
                        authCodeNumber: response.result || '',
                        currentState: SMS_CONFIRM_STATE,
                    });
                    console.log(1);
                })
                .catch(e => {
                    this.setState({
                        isErrorModalOpen: true,
                    });
                    return Promise.reject(e);
                });
        } catch (e) {
            errorHandler(e, FETCH_SMS_CODE_URL, this.props.tempData.userId);
        }
    };

    getAuthCode = async () => {
        console.log(this.state.currentState);
        const params = {
            phoneNumber: this.state.phoneNumber,
        };

        if (localStorage.getItem('development_secret')) {
            params.env = localStorage.getItem('development_secret');
        }
        try {
            await this.props
                .apiRequest({
                    type: FETCH_SMS_CODE,
                    path: FETCH_SMS_CODE_URL,
                    data: params,
                    config: {
                        headers: {
                            Authorization: null,
                        },
                    },
                })
                .then(response => {
                    if (response.code !== 200)
                        return Promise.reject(new Error(response.message));
                    // 성공시
                    this.setState({
                        authCodeNumber: response.result || '',
                        currentState: SMS_CONFIRM_STATE,
                    });
                })
                .catch(e => {
                    this.setState({
                        isErrorModalOpen: true,
                    });
                    return Promise.reject(e);
                });
        } catch (e) {
            errorHandler(e, FETCH_SMS_CODE_URL, this.props.tempData.userId);
        }
    };

    confirmAuthCode = async authCodeNumber => {
        const params = {
            phoneNumber: this.state.phoneNumber, // 전화번호
            code: this.state.authCodeNumber, // 인증번호
        };
        if (localStorage.getItem('development_secret')) {
            params.env = localStorage.getItem('development_secret');
        }

        try {
            const response = await this.props.apiRequest({
                type: VERIFY_SMS_CODE,
                path: VERIFY_SMS_CODE_URL,
                data: params,
                config: {
                    headers: {
                        Authorization: null,
                    },
                },
            });

            if (response.code === 200 && response.isMatch) {
                console.log('ok');
                this.setState({
                    authCodeNumber: this.state.value,
                    currentState: SMS_SENDING_STATE,
                    value: '',
                    // authCodeNumber: '',
                    chattingList: this.state.chattingList.concat(
                        smsSendingValid(authCodeNumber),
                    ),
                });
                this.userTextInputShowAndHide();
            } else {
                console.log('not ok');
                console.log(this.state.value);
                this.setState({
                    value: '',
                    chattingList: this.state.chattingList.concat(
                        smsSendingInvalid(authCodeNumber),
                    ),
                });
            }
        } catch (e) {
            console.log(e);
        }
    };

    isUserLoadingCircleShow = () => {
        this.setState({
            isLoaded: true,
        });
    };
    isUserLoadingCircleHide = () => {
        this.setState({
            isLoaded: false,
        });
    };
    isUserLoadingCircleHideAndShow = () => {
        this.isUserLoadingCircleShow();
        setTimeout(() => {
            this.isUserLoadingCircleHide();
        }, 1000);
    };

    handleClick = e => {
        console.log(e);
    };

    handleChange(event) {
        this.setState({
            value: event.target.value,
            authCodeNumber: event.currentTarget.value.trim(),
        });
    }

    handleStateGreeting2SMSConfirm(phoneNumber) {
        const isValidPhoneNumber = isMobile(null, phoneNumber);
        console.log(this.state);
        if (isValidPhoneNumber) {
            this.setState({
                phoneNumber: isValidPhoneNumber
                    ? phoneNumber
                    : this.state.phoneNumber,
                currentState: SMS_CONFIRM_STATE,
                value: '',
                chattingList: this.state.chattingList.concat(
                    smsConfirmStateValid(phoneNumber, this.reSendAuthCode),
                ),
            });
            this.getAuthCode();
        } else
            this.setState({
                value: '',
                chattingList: this.state.chattingList.concat(
                    smsConfirmStateInvaild(phoneNumber),
                ),
            });
        this.scrollBottom();
    }

    reSendAuthCode = phoneNumber => {
        const rePhoneNumber = this.state.phoneNumber;
        return () => {
            this.handleStateGreeting2SMSReConfirm(rePhoneNumber);
        };
    };

    handleStateGreeting2SMSReConfirm(rePhoneNumber) {
        const isValidPhoneNumber = isMobile(null, rePhoneNumber);
        console.log(this.state);
        if (isValidPhoneNumber) {
            this.setState({
                rePhoneNumber: isValidPhoneNumber
                    ? rePhoneNumber
                    : this.state.phoneNumber,
                currentState: SMS_CONFIRM_STATE,
                value: '',
                chattingList: this.state.chattingList.concat(
                    smsConfirmStateReValid(rePhoneNumber, this.reGetAuthCode),
                ),
            });
            this.getAuthCode();
        } else
            this.setState({
                value: '',
                chattingList: this.state.chattingList.concat(
                    smsConfirmStateInvaild(rePhoneNumber),
                ),
            });
        this.scrollBottom();
    }

    async handleStateGreeting3SMSSending(authCodeNumber) {
        try {
            const r = await this.confirmAuthCode(authCodeNumber);
            console.log(r);
        } catch (e) {
            console.log(e);
        }
        this.scrollBottom();
    }

    handleStateGreeting4EmailCheck(email) {
        const isValidEmail = isValidEmailFormat(null, email);
        console.log(this.state);
        if (isValidEmail) {
            this.setState({
                email: this.state.value,
                currentState: EMAIL_ENTER_STATE,
                value: '',
                chattingList: this.state.chattingList.concat(
                    emailCheckState(email, this.select),
                ),
            });
        } else {
            this.setState({
                email: this.state.value,
                currentState: SMS_SENDING_STATE,
                value: '',
                chattingList: this.state.chattingList.concat(
                    emailCheckFalse(email),
                ),
            });
        }
        this.scrollBottom();
    }

    handleStateGreeting5EmailConfirm() {
        this.setState({
            confirm: this.state.value,
            currentState: EMAIL_CONFIRM_STATE,
            value: '',
            chattingList: this.state.chattingList.concat(
                emailCheckTrue({
                    email: this.state.email,
                    phoneNumber: this.state.phoneNumber,
                    handleImageChange: this.handleStateGreeting6ImageUpload,
                }),
            ),
        });
        this.scrollBottom();
    }
    handleStateGreeting5EmailFalse() {
        // if (confirm === '') return;
        // let email = null;
        console.log(this.state);
        this.setState({
            confirm: this.state.value,
            currentState: SMS_SENDING_STATE,
            value: '',
            chattingList: this.state.chattingList.concat(emailCheckFalse()),
        });
        this.scrollBottom();
    }

    handleStateGreeting6ImageUpload() {
        this.setState({
            email: this.state.value,
            currentState: PAPER_ENTER_STATE,
            value: '',
            chattingList: this.state.chattingList.concat(
                imageUploadCheck(this.state.email),
            ),
        });
        this.scrollBottom();
    }

    scrollBottom() {
        let message = document.getElementById('chat-body');
        setTimeout(() => {
            message.scrollTop = message.scrollHeight;
        }, 0);
    }

    handleSubmit(event) {
        event.preventDefault();
        this.isUserLoadingCircleHideAndShow();
        if (this.state.currentState === GREETING_STATE) {
            // REGEX Check
            // If not OK

            // IF Ok
            return setTimeout(() => {
                this.handleStateGreeting2SMSConfirm(this.state.value);
            }, 1000);
        } else if (this.state.currentState === SMS_CONFIRM_STATE) {
            // If not OK

            // IF Ok
            return setTimeout(() => {
                this.handleStateGreeting3SMSSending(this.state.value);
            }, 1000);
        } else if (this.state.currentState === SMS_SENDING_STATE) {
            // If not OK

            // IF Ok
            return setTimeout(() => {
                this.handleStateGreeting4EmailCheck(this.state.value);
            }, 1000);
        } else if (this.state.currentState === EMAIL_ENTER_STATE) {
            // If not OK

            // IF Ok
            return setTimeout(() => {
                this.handleStateGreeting5EmailConfirm(this.state.value);
            }, 1000);
        } else if (this.state.currentState === EMAIL_CONFIRM_STATE) {
            // If not OK

            // IF Ok
            return setTimeout(() => {
                this.handleStateGreeting6ImageUpload(this.state.email);
            }, 1000);
        }
    }

    botMessage() {}

    userTextInputShowAndHide = () => {
        this.setState({
            userPhoneVerifyInput: false,
            userTextInput: true,
        });
    };

    componentDidMount() {}
    componentWillMount() {}

    select = confirm => {
        return () => {
            if (confirm === true) {
                this.handleStateGreeting5EmailConfirm();
            } else if (confirm === false) {
                this.handleStateGreeting5EmailFalse();
            }
        };
    };

    render() {
        const {
            isLoaded,
            currentState,
            phoneNumber,
            isPhoneNumberConfirmed,
            authCodeNumber,
            email,
            confirm,
        } = this.state;

        const { onDismiss } = this.props;
        return (
            <div className="chat-group">
                <div className="chat-container">
                    <div className="header">
                        직토 도우미 봇
                        <i className="fal fa-times" onClick={onDismiss} />
                    </div>
                    <img src={this.state.file} />
                    <div className="chat-body" id="chat-body">
                        {this.state.chattingList}
                        {this.state.isLoaded && <UserLoading />}
                    </div>
                    <div className="chat-send-input">
                        <form
                            className="chat-send-input-btn"
                            onSubmit={this.handleSubmit}
                        >
                            <input
                                type="text"
                                value={this.state.value}
                                onChange={this.handleChange}
                                style={{ flex: 1, marginRight: 10 }}
                                disabled={currentState === 3}
                            />
                            <input
                                type="submit"
                                value="전송"
                                disabled={currentState === 3}
                            />
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

Chat.propTypes = {
    onDismiss: PropTypes.func,
};

Chat.defaultProps = {
    onDismiss: () => {},
};

const mapDispatchToProps = dispatch => ({
    apiRequest: bindActionCreators(apiRequest, dispatch),
    simpleLogin: bindActionCreators(authActions.simpleLogin, dispatch),
});

const mapStateToProps = state => ({
    auth: state.auth,
    tempData: state.temp,
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Chat),
);
