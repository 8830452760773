import { getItem } from 'utils/handleStorage';
export function authHeader() {
    let authToken = getItem('Z2Token');

    if (authToken) {
        return { Authorization: 'Bearer ' + authToken };
    } else {
        return {};
    }
}
