import React, { PureComponent } from 'react';
import { addValidationRule } from 'formsy-react';
import PropTypes from 'prop-types';
// sub components
import Input from 'components/Input';
// utils
import { isUserName, isValidSSN } from 'utils/validate';

addValidationRule('isUserName', isUserName);
addValidationRule('isValidSSN', isValidSSN);

class CompanionInput extends PureComponent {
    static defaultProps = {
        index: 0,
    };

    static propsTypes = {
        index: PropTypes.number,
    };

    constructor(props) {
        super(props);

        this.state = {
            fullSSN: undefined,
        };
    }

    handleSSNChange = value => {
        this.setState({
            fullSSN: this.props.birthday + value,
        });
    };

    render() {
        const { index } = this.props;
        return (
            <div
                style={{
                    border: '1px solid #9c9c9c',
                    borderWidth: '1px 0',
                    padding: '30px 0',
                    marginTop: '-1px',
                }}
            >
                <div className="guideline" style={{ marginBottom: 30 }}>
                    <b>동반자{index != null ? index + 1 : ''}</b>의 정보를
                    입력하세요.
                </div>
                <Input
                    required
                    name={`companionNM-${index}`}
                    title="이름"
                    className="inline"
                    inputClassName="block"
                    placeholder="입력하세요."
                    disabled={this.props.disabled}
                    value={this.props.name || undefined}
                    validations="minLength:2,maxLength:20,isUserName"
                    validationErrors={{
                        minLength: '최소 2자 이상 입력하세요.',
                        maxLength: '최대 20자 이하 입력하세요.',
                        isUserName: '숫자,특수문자,공백없이 입력하세요.',
                    }}
                />
                <div className="form-group ssn-wrap inline mb-0">
                    <label htmlFor="nn1" className="label">
                        주민번호
                    </label>
                    {/* <div className="block" style={{ width: 265 }}> */}
                    <div className="block" style={{ flex: 1 }}>
                        <div className="input-wrap">
                            <Input
                                required
                                name={`companionNN1-${index}`}
                                title=""
                                className="inline"
                                validations="isNumeric,isLength:6"
                                validationError="주민번호 앞자리를 입력하세요."
                                value={this.props.birthday}
                                disabled
                            />
                            <div
                                style={{
                                    width: 30,
                                    height: 40,
                                    lineHeight: '40px',
                                    textAlign: 'center',
                                }}
                            >
                                -
                            </div>
                            <Input
                                required
                                type="password"
                                name={`companionNN2-${index}`}
                                title=""
                                placeholder="뒷7자리"
                                className="inline justify-content-flex-end"
                                validations="isNumeric,isLength:7"
                                validationError="주민번호 뒷자리를 입력하세요."
                                exportValue={this.handleSSNChange}
                                disabled={this.props.disabled}
                            />
                            <div
                                style={{
                                    position: 'absolute',
                                    bottom: 0,
                                    right: 0,
                                }}
                            >
                                <Input
                                    type="hidden"
                                    name="fullSSN"
                                    required
                                    validations="isValidSSN"
                                    validationError="주민번호 뒷자리를 입력하세요."
                                    value={this.state.fullSSN}
                                    innerRef={c => {
                                        this.ssnRef = c;
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CompanionInput;
