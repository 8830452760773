import React from 'react';
import { formatToLocalString } from 'utils/format';

const DisplayZiktoCash = props => (
    <div className="section section-colored" style={props.style}>
        <div className="content" style={{ textAlign: 'center' }}>
            <b>{props.username}님의 직토캐시</b>
            <div style={{ marginTop: 10 }}>
                <b className="digit">
                    {props.cash ? formatToLocalString(props.cash) : 0}
                </b>
                <span className="unit" style={{ marginLeft: 5 }}>
                    Cash
                </span>
            </div>
        </div>
    </div>
);

export default DisplayZiktoCash;
