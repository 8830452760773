import moment from 'moment';
// 출발일,도착일. 시간을 24시간으로 나누어서 올림. 1달은 30일로 계산
const getTravelDays = ({ startDate, endDate, startTime, endTime }) => {
    if (!startDate || !endDate) return;
    const copiedStartDate = moment(startDate).toDate();
    const copiedEndDate = moment(endDate).toDate();
    const start = copiedStartDate.setHours(startTime, 0, 0, 0);
    const end = copiedEndDate.setHours(endTime, 0, 0, 0);
    var duration = moment.duration(moment(end).diff(moment(start)));
    var hours = duration.asHours();
    return hours < 24 ? 1 : Math.ceil(duration.asDays());
};

export default getTravelDays;
