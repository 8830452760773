import React from 'react';

const Item = props => (
    <div className="companion-item">
        <div>
            <b>동반자{props.index + 1}</b>
        </div>
        <div>
            <b>{props.yymmdd}</b>
        </div>
        <div>
            <b>{props.gender === 1 ? '남성' : '여성'}</b>
        </div>
        <div className="btns">
            <button type="button" className="btn" onClick={props.onEdit}>
                <i className="fal fa-pen" />
            </button>
            <button type="button" className="btn" onClick={props.onRemove}>
                <i className="fal fa-times" />
            </button>
        </div>
    </div>
);

export default Item;
