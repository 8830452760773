import React, { Component } from 'react';
import Modal from 'components/Modal';
import Nav from 'components/Nav';

const dummy = {
    businessProduct: [
        {
            name: '생산물하자보증책임보험',
            desc: `(프로미카서비스센터용)복잡하고 어려운 절차는 저희에게 맡겨주세요!`,
        },
        {
            name: '골프장 날씨보험(레저업)',
            desc:
                '날씨 대비에 민감한 골프장(레저업), 매출 감소로 인한 손실 보장을 받아보세요.',
        },
        {
            name: '에너지 날씨보험',
            desc:
                '태양광 / 도시가스 / 난방유 / 풍력발전 / 수력발전 매출 감소로 인한 손실 보장을 받아보세요.',
        },
        {
            name: '생산업체 날씨보험',
            desc:
                '날시에 민감한 제품이 이상기후 때문에 걱정이신가요? 날씨 보험을 통해 매출 손실에 대비하세요.',
        },
        {
            name: '여행사 날씨보험',
            desc:
                '이상기후로 인해 관광객 감소가 걱정이신가요? 매출감소로 인한 손실 보장을 받아보세요.',
        },
        {
            name: '행사취소보험',
            desc:
                '콘서트, 대회 등 각종 야외행사시 기상의 악화로 인해 행사를 취소/포기했을 경우 행사비용을 담보해드립니다.',
        },
        {
            name: '지방자치단체 날씨보험',
            desc:
                '일정 수준 이상의 자연재해(미세먼지, 폭설 등)에 대비하여 보상을 받아보세요.',
        },
        {
            name: '건설 날씨보험',
            desc:
                '이상기후로 인해 공사 일정 지연, 자재 유실 등의 피해가 발생하여 증가한 공사비용을 보상해드립니다.',
        },
        {
            name: '영화제작 날씨보험',
            desc:
                '이상기후로 인해 영화제작 일정 지연 등의 피해가 발생하여 증가한 영화제작 비용을 보상해드립니다.',
        },
        {
            name: '상금 보험',
            desc:
                '프로모션으로 보험을 이용하여 저렴한 비용으로 최대의 홍보효과를 거둘 수 있습니다.',
        },
    ],
};
class Business extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isProductContact: false,
        };
    }

    toggleModal = () => {
        this.setState({
            isProductContact: !this.state.isProductContact,
        });
    };

    renderProductContact() {
        return (
            <div>
                <div className="header">상품 준비중입니다.</div>
                <div className="contents">
                    <div style={{ marginBottom: 20 }}>
                        상품에 대한 설명이나, 보험 견적을 원하시면 <br />
                        아래로 연락주세요. ( 담당: 김윤배 이사 )
                    </div>
                    <div>
                        <strong>
                            {' '}
                            <i className="fal fa-envelope" /> &nbsp;
                            contact@zikto.com{' '}
                        </strong>
                        <br />
                        <strong>
                            {' '}
                            <i className="fal fa-phone" /> &nbsp; +82 (0)70 4104
                            9458{' '}
                        </strong>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { isProductContact } = this.state;

        return (
            <div className="business-product container-fixed">
                <Nav className="dark-light" />
                <div className="mobile-title">
                    <span>기업 보험</span>
                </div>
                <div className="container">
                    <div className="section section1">
                        <h2 className="h2">기업 보험</h2>
                        <div className="card">
                            {dummy.businessProduct.map((el, i) => (
                                <div
                                    onClick={() => {
                                        if (i === 0) {
                                            return this.props.history.push(
                                                '/promy',
                                            );
                                        }
                                        this.toggleModal();
                                    }}
                                    className="flex-item"
                                    key={`products-${i}`}
                                >
                                    <img
                                        className="card-image"
                                        src={require(`./images/business_product_${i}.png`)}
                                        alt=""
                                    />
                                    <div className="card-desc-area">
                                        <strong className="card-desc title">
                                            {el.name}
                                        </strong>
                                        <span className="card-desc subtitle">
                                            {el.desc}
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {isProductContact && (
                    <Modal
                        content={this.renderProductContact()}
                        onDismiss={this.toggleModal}
                    />
                )}
            </div>
        );
    }
}

export default Business;
