// import
import React, { Component } from 'react';

import './style.css';
import Axios from 'axios';

class ImageUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: '',
            imagePreviewUrl: '',
        };
    }

    async handleImageChange(e) {
        e.preventDefault();
        let data = new FormData();
        const file = e.target.files[0];
        data.append('email', this.props.email);
        data.append('phoneNumber', this.props.phoneNumber);
        data.append('license', file);

        try {
            await Axios.post(
                '/api/insurance/product/promy/contractRequest',
                data,
            );
        } catch (e) {
            console.log(e);
        }

        let reader = new FileReader();

        reader.onloadend = () => {
            this.setState({
                file,
                imagePreviewUrl: reader.result,
            });
        };

        reader.readAsDataURL(file);

        // Call Done
        // this.props.uploadComplete();
        this.props.onComplete();
    }

    render() {
        let { imagePreviewUrl } = this.state;
        let $imagePreview = null;
        if (imagePreviewUrl) {
            $imagePreview = <img className="preview" src={imagePreviewUrl} />;
        }

        return (
            <div className="upload-btn-wrapper">
                <button className="image-btn">
                    이미지 업로드 또는 사진촬영
                </button>
                <input
                    type="file"
                    id="file-chooser"
                    onChange={this.handleImageChange.bind(this)}
                />
                {$imagePreview}
            </div>
        );
    }
}

export default ImageUpload;
