import { TEMP_DATA_SAVE, TEMP_DATA_REMOVE } from '../actions/temp/types';

const initialState = {
    startDate: null,
    endDate: null,
    startTime: 0,
    endTime: 0,
    gender: 0,
    companions: [],
    birthday: '',
    insuType: 'standard',
    period: 0,
    age: 0,
    estimate: 0,
    estimateTotal: 0,
    users: '',
    ziktoCash: 0,
    travelPoint: 0,
    rates: [],
    phoneNumber: '',
    username: '',
    email: '',
    price: 0,
    orderId: 0,
    contactDate: null,
    userId: 0,
    paidAmount: 0,
    paidTravelPoint: 0,
    paidZiktoCash: 0,
};

export default function temp(state = initialState, action) {
    switch (action.type) {
        case TEMP_DATA_SAVE:
            return {
                ...state,
                ...action.data,
            };

        case TEMP_DATA_REMOVE:
            return { ...initialState };

        default:
            return state;
    }
}
