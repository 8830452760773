import React from 'react';
import PropTypes from 'prop-types';
import { formatToLocalString } from 'utils/format';

const ProductType = props => {
    const { estimate, point, estimateTotal } = props;
    const noData = !estimate;

    return (
        <div className="sheet">
            <div
                className="flex sheet-line flex-column"
                style={{ paddingTop: 5 }}
            >
                {(!props.companions || props.companions.length === 0) && (
                    <div className="flex">
                        <div
                            style={{ alignItems: 'center' }}
                            className="flex flex-1"
                        >
                            <b>{noData ? '' : `[${props.type}]`} 예상 보험료</b>
                        </div>
                        <div className="flex flex-1 justify-content-flex-end align-items-center">
                            <span
                                className="digit"
                                style={{ fontSize: 20, paddingRight: 5 }}
                            >
                                {noData
                                    ? '--'
                                    : formatToLocalString(Number(estimate))}
                            </span>{' '}
                            원
                        </div>
                    </div>
                )}

                {props.companions && props.companions.length > 0 && (
                    <div>
                        <div className="flex mb-10">
                            <div className="flex flex-1">
                                <b>
                                    {noData ? '' : `[${props.type}]`} 예상
                                    보험료
                                </b>
                            </div>
                            <div className="flex flex-1 justify-content-flex-end align-items-center">
                                <span
                                    className="digit"
                                    style={{ fontSize: 20, paddingRight: 5 }}
                                >
                                    {noData
                                        ? '--'
                                        : formatToLocalString(
                                              props.rates.reduce(
                                                  (acc, curr) => acc + curr,
                                                  0,
                                              ),
                                          )}
                                </span>{' '}
                                원
                            </div>
                        </div>
                        <div className="flex" style={{ color: '#9c9c9c' }}>
                            <div className="flex flex-1">대표자</div>
                            <div className="flex flex-1 justify-content-flex-end align-items-center">
                                <span
                                    className="digit"
                                    style={{
                                        fontSize: 14,
                                        paddingRight: 5,
                                    }}
                                >
                                    {noData
                                        ? '--'
                                        : formatToLocalString(
                                              Number(props.rates[0]),
                                          )}
                                </span>{' '}
                                원
                            </div>
                        </div>
                        {props.companions.map((c, i) => (
                            <div
                                className="flex"
                                style={{ color: '#9c9c9c' }}
                                key={`comp-${i}`}
                            >
                                <div className="flex flex-1">
                                    동반자 {i + 1}
                                </div>
                                <div className="flex flex-1 justify-content-flex-end align-items-center">
                                    <span
                                        className="digit"
                                        style={{
                                            fontSize: 14,
                                            paddingRight: 5,
                                        }}
                                    >
                                        {noData
                                            ? '--'
                                            : formatToLocalString(
                                                  Number(
                                                      isNaN(props.rates[i + 1])
                                                          ? 0
                                                          : props.rates[i + 1],
                                                  ),
                                              )}
                                    </span>{' '}
                                    원
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div className="flex sheet-line">
                <div className="flex flex-1">
                    <b>트래블포인트 최대 할인</b>
                </div>
                <div className="flex flex-1 justify-content-flex-end align-items-center">
                    <span
                        className="digit"
                        style={{ fontSize: 14, paddingRight: 5 }}
                    >
                        {noData
                            ? '--'
                            : '-' +
                              formatToLocalString(
                                  Math.min(
                                      Number(point),
                                      Math.floor(estimate * 0.1),
                                  ),
                              )}
                    </span>{' '}
                    원
                </div>
            </div>
            <div
                className="flex sheet-line align-items-center"
                style={{ paddingBottom: 0, borderBottom: 0 }}
            >
                <div className="flex flex-1">
                    <b style={{ fontSize: 20 }}>총 예상 보험료</b>
                </div>
                <div className="flex flex-1 justify-content-flex-end align-items-center">
                    <span
                        className="digit"
                        style={{ fontSize: 30, paddingRight: 5 }}
                    >
                        {noData
                            ? '--'
                            : formatToLocalString(Number(estimateTotal))}
                    </span>{' '}
                    원
                </div>
            </div>
        </div>
    );
};

export default ProductType;

ProductType.propTypes = {
    isDisabled: PropTypes.bool,
    estimateTotal: PropTypes.number,
    estimate: PropTypes.number,
    point: PropTypes.number,
};

ProductType.defaultProps = {
    isDisabled: false,
};
