import React from 'react';
import Container from 'container';
import { Route } from 'react-router-dom';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import ProductConfirm from './Step3/Confirm';

const Purchase = ({ match }) => {
    return (
        <div>
            <Route
                exact
                path={`${match.url}/step1`}
                component={Container({
                    Component: Step1,
                    title: '해외여행보험',
                    theme: 'dark-light',
                    className: 'product',
                    purchase: 1,
                    path: `${match.url}/step1`,
                })}
            />
            <Route
                exact
                path={`${match.url}/step2`}
                component={Container({
                    Component: Step2,
                    title: '해외여행보험',
                    theme: 'dark-light',
                    className: 'product container-rel',
                    purchase: 2,
                    path: `${match.url}/step2`,
                })}
            />
            <Route
                path={`${match.url}/step3`}
                component={Container({
                    Component: Step3,
                    title: '해외여행보험',
                    theme: 'dark-light',
                    className: 'product',
                    // isProtected: true,
                    purchase: 3,
                    path: `${match.url}/step3`,
                })}
            />
            <Route
                path={`${match.path}/confirmed/:orderId`}
                component={Container({
                    Component: ProductConfirm,
                    title: '해외여행보험',
                    theme: 'dark-light',
                    className: 'product',
                    isProtected: true,
                    purchase: 3,
                    path: `${match.url}/confirmed/:orderId`,
                })}
            />
        </div>
    );
};

export default Purchase;
