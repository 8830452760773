import moment from 'moment';
// 만 나이 구하기: birthdayString => 'yymmdd'
const getAge = birthdayString => {
    if (!birthdayString) return 0;
    const getBirthDayStringFromSSN = () => {
        const isMillennials = parseInt(birthdayString.substring(0, 1), 10) < 3;
        const birthYear =
            (isMillennials ? 2000 : 1900) +
            parseInt(birthdayString.substring(0, 2), 10);

        return `${birthYear}/${birthdayString.substring(
            2,
            4,
        )}/${birthdayString.substring(4, 6)}`;
    };
    const birthDay = new Date(getBirthDayStringFromSSN());
    var years = moment().diff(moment(birthDay, 'YYYYMMDD'), 'years');
    return years;
};

export default getAge;
