import React, { Component } from 'react';
import { addValidationRule } from 'formsy-react';
import Input from 'components/Input';
import SelectGender from '../SelectGender';
// utils
import { isBirthday, isValidAge } from 'utils/validate';
// 주민번호 앞자리 유효성 체크
addValidationRule('isBirthday', isBirthday);
addValidationRule('isValidAge', isValidAge);
class CompanionInputs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            gender: this.props.gender,
            yymmdd: this.props.yymmdd,
        };
    }

    handleSelect = value => {
        return () => {
            this.setState({
                gender: value,
            });
        };
    };

    selectGender = gender => {
        this.setState({ gender });
    };

    closeAdd = () => {
        this.props.onClose && this.props.onClose();
    };

    closeEdit = () => {
        this.props.onClose && this.props.onClose();
    };

    validateSSN = value => {
        return /^\d{6}$/.test(value);
    };

    validateAge = value => {
        return isValidAge(null, value);
    };

    handleAdd = () => {
        const { gender, yymmdd } = this.state;
        this.props.onAdd && this.props.onAdd({ gender, yymmdd });
    };

    handleChange = value => {
        this.setState({ yymmdd: value });
    };

    handleEdit = value => {
        const { gender, yymmdd } = this.state;

        this.props.onEdit &&
            this.props.onEdit({ index: this.props.index, gender, yymmdd });
    };

    render() {
        const { gender, yymmdd } = this.state;
        const { canAdd, editIndex, index } = this.props;
        const canEdit = editIndex > -1;

        return (
            <div className="add-companion" style={{ marginBottom: 30 }}>
                <div className="add-companion-wrap">
                    {(canAdd || editIndex > -1) && (
                        <div className="guideline">
                            <b>동반자{index != null ? index + 1 : ''}</b>의
                            정보를 입력하세요.
                        </div>
                    )}
                    <button
                        type="button"
                        className="btn companion-close"
                        onClick={canAdd ? this.closeAdd : this.closeEdit}
                    >
                        <i className="fal fa-times" />
                    </button>
                    <div className="user-form">
                        <Input
                            required
                            type="number"
                            name="companionSSN"
                            title={`주민번호 \n앞 6자리`}
                            className="inline"
                            inputClassName="block"
                            placeholder="입력하세요."
                            validations="isBirthday,isValidAge"
                            validationErrors={{
                                isBirthday: '주민번호 앞자리를 입력하세요.',
                                isValidAge:
                                    '만 1세부터 만 79세까지 가입가능합니다.',
                            }}
                            value={yymmdd}
                            exportValue={this.handleChange}
                            disabled={!(canAdd || canEdit)}
                        />
                    </div>

                    {/* 성별 */}
                    <SelectGender
                        onSelect={this.selectGender}
                        gender={gender}
                        disabled={!(canAdd || canEdit)}
                    />
                    {(canAdd || canEdit) && (
                        <button
                            type="button"
                            className="btn add-confirm outline block"
                            onClick={canAdd ? this.handleAdd : this.handleEdit}
                            disabled={
                                !(
                                    gender &&
                                    this.validateSSN(yymmdd) &&
                                    this.validateAge(yymmdd)
                                )
                            }
                        >
                            <b>입력 완료</b>
                        </button>
                    )}
                </div>
            </div>
        );
    }
}

export default CompanionInputs;
