import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'components/Dropdown';

const options = [...Array(24).keys()].map((el, i) => {
    const val = el + 1;
    const am = val < 13;
    const format = val => (val < 10 ? `0${val}` : val);
    return {
        name: `${am ? '오전' : '오후'} ${
            am ? format(val) : format(val - 12)
        }시`,
        value: val,
    };
});

const filterOptions = props => {
    // TODO: 리팩토링
    const { lessThan, moreThan } = props;
    // if (!defaultValue) return options;

    if (lessThan) {
        return options.filter((opt, i) => opt.value <= lessThan);
    }

    if (moreThan) {
        return options.filter((opt, i) => opt.value >= moreThan);
    }

    return options;
};

const TimeDropdown = props => {
    const selectedOption = options.find(
        option => option.value === Number(props.defaultValue),
    );
    const filteredOptions = filterOptions(props);

    return (
        <Dropdown
            options={filteredOptions}
            onSelect={props.onSelect}
            className={props.className}
            defaultValue={props.defaultValue}
            selected={selectedOption ? selectedOption : null}
        />
    );
};

export default TimeDropdown;

TimeDropdown.propTypes = {
    onSelect: PropTypes.func,
    className: PropTypes.string,
    selected: PropTypes.object,
    defaultValue: PropTypes.number,
    moreThan: PropTypes.any,
    lessThan: PropTypes.any,
};

TimeDropdown.defaultProps = {
    onSelect: () => {},
    className: '',
    selected: null,
    moreThan: null,
    lessThan: null,
};
