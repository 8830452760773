import React, { PureComponent } from 'react';
import { addValidationRule } from 'formsy-react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// sub components
import Modal from 'components/Modal';
import Countdown from './Countdown';
import Input from 'components/Input';
import ErrorModal from 'components/ErrorModal';
// redux
import { apiRequest } from 'redux/actions/api/utils';
import authActions from 'redux/actions/auth';
// constants
import {
    FETCH_SMS_CODE,
    FETCH_SMS_CODE_URL,
    EASY_LOGIN,
    EASY_LOGIN_URL,
} from 'constants/paths';
import { IS_MOBILE_DEVICE } from 'constants/device';
// utils
import { isMobile } from 'utils/validate';
import { errorHandler } from 'utils/errorHandler';
// css
import './style.css';

const defaultErrorMsg = '인증번호를 확인해주세요.';
const inValidErrorMsg = '인증번호를 확인 후 다시 입력하세요.';
const BUTTON_TEXT = ['인증번호 받기', '인증번호 확인', '확인 완료'];

// custom validations
addValidationRule('isMobile', isMobile);

class PhoneVerificationNumber extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            authCodeNumber: '',
            phoneNumber:
                this.props.phoneNumber ||
                this.props.tempData.phoneNumber ||
                undefined,
            smsVerifyErrorMessage: '',
            errorMessage: '문제가 발생했습니다. 다시 시도하세요.',
            isExpired: false,
            isInvalidAuthCode: false,
            isPhoneNumberConfirmed:
                this.props.phoneNumber || this.props.tempData.phoneNumber
                    ? true
                    : false,

            inputState: 0, // 0: inactive, 1: active
            buttonState: 0, // 0: inactive, 1: active
            buttonType: 0, // 0: 인증번호 받기, 1: 인증번호 확인, 2: 확인 완료
            isCountdownOpen: false,
            isExistingPhoneNumber: 0,

            // modal
            isAuthModalOpen: false,
            isErrorModalOpen: false,
            isNotRegisterdOpen: false,
            phoneAuthModalOpen: false,
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (
            !!nextProps.phoneNumber &&
            nextProps.phoneNumber !== prevState.phoneNumber
        ) {
            return {
                phoneNumber: nextProps.phoneNumber,
                isPhoneNumberConfirmed: nextProps.auth.level ? true : false,
            };
        }

        return null;
    }

    handlePhoneNumberChange = phoneNumber => {
        const isValidPhoneNumber = isMobile(null, phoneNumber);
        this.setState(
            {
                phoneNumber: isValidPhoneNumber
                    ? phoneNumber
                    : this.state.phoneNumber,
                buttonState: isValidPhoneNumber ? 1 : 0,
                buttonType: 0,
                inputState: 0,
                authCodeNumber: '',
                isCountdownOpen: false,
                isExpired: false,
            },
            () => {},
        );
    };

    toggleModal = modalKey => {
        return () => {
            this.setState({
                [modalKey]: !this.state[modalKey],
            });
        };
    };

    // 인증번호 받기
    getAuthCode = async () => {
        const params = {
            phoneNumber: this.state.phoneNumber,
        };
        if (localStorage.getItem('development_secret')) {
            params.env = localStorage.getItem('development_secret');
        }

        try {
            await this.props
                .apiRequest({
                    type: FETCH_SMS_CODE,
                    path: FETCH_SMS_CODE_URL,
                    data: params,
                    config: {
                        headers: {
                            Authorization: null,
                        },
                    },
                })
                .then(response => {
                    if (response.code !== 200)
                        return Promise.reject(new Error(response.message));
                    // 성공시
                    this.setState({
                        authCodeNumber: response.result || '',
                        isAuthModalOpen: true,
                        isExpired: false,
                        isPhoneNumberConfirmed: true,
                        // buttonState: 1,
                    });
                })
                .catch(e => {
                    this.setState({
                        isErrorModalOpen: true,
                    });
                    return Promise.reject(e);
                });
        } catch (e) {
            errorHandler(e, FETCH_SMS_CODE_URL, this.props.tempData.userId);
        }
    };

    // 전화번호 로그인
    handleEasyLogin = async () => {
        const params = {
            phoneNumber: this.state.phoneNumber, // 전화번호
            code: this.state.authCodeNumber, // 인증번호
        };
        if (localStorage.getItem('development_secret')) {
            params.env = localStorage.getItem('development_secret');
        }

        try {
            await this.props
                .apiRequest({
                    type: EASY_LOGIN,
                    path: EASY_LOGIN_URL,
                    data: params,
                    config: {
                        headers: {
                            Authorization: null,
                        },
                    },
                })
                .then(async response => {
                    // 인증번호 일치하지 않을 때, 에러메시지 보여주고 다시 입력하게한다.
                    if (response.code === 400) {
                        return this.handleAuthConfirmError();
                    }

                    // 회원이 아니거나 서버 에러. 가입하기 유도한다.
                    // code가 404일 경우(회원이 아닐때)는 에러 보고를 하지 말고, 유저에게 상태를 알리기만 한다.
                    if (response.code === 404) {
                        return Promise.reject();
                    }
                    if (response.code !== 200)
                        return Promise.reject(
                            new Error(`${response.code} Error`),
                        );

                    // 성공시 전화번호 로그인하고, auth 레벨 설정.
                    (await response.accessToken) &&
                        this.props.simpleLogin(
                            response.accessToken,
                            response.level,
                        );

                    this.props.onSuccess &&
                        this.props.onSuccess(
                            this.state.phoneNumber,
                            response.level,
                        );
                })
                .catch(e => {
                    this.setState(
                        {
                            inputState: 0,
                            buttonState: 0,
                            buttonType: 2,
                            isCountdownOpen: false,
                            smsVerifyErrorMessage: '',
                            isExpired: false,
                            isInvalidAuthCode: false,
                        },
                        () => {
                            if (!this.props.onFail)
                                return this.handleLoginFail(); // Default: 회원/보험가입 modal
                            this.props.onFail(
                                this.state.phoneNumber,
                                this.state.authCodeNumber,
                            );
                        },
                    );
                    return Promise.reject(e);
                });
        } catch (e) {
            if (typeof e !== 'undefined')
                errorHandler(e, EASY_LOGIN_URL, this.props.tempData.userId);
        }
    };

    // 인증번호 확인
    confirmAuthCode = () => {
        // 휴대폰 로그인
        this.handleEasyLogin();
        // this.setState({
        //     phoneAuthModalOpen: true,
        // });
    };

    handleLoginFail = () => {
        this.setState({
            isNotRegisterdOpen: true,
        });
    };

    handleFetchFailed = () => {
        this.setState({
            buttonState: 0,
        });
        this.phoneNumberRef.props.resetValue();
    };

    handleAuthConfirmError = () => {
        this.setState({
            authCodeNumber: '',
            smsVerifyErrorMessage: inValidErrorMsg,
            isInvalidAuthCode: true,
            inputState: 1,
            buttonState: 0,
            buttonType: 1,
        });
    };

    // 인증번호 입력시
    handleChange = e => {
        this.setState({
            authCodeNumber: e.currentTarget.value.trim(),
            smsVerifyErrorMessage: defaultErrorMsg,
            inputState: 1,
            buttonState: 1,
            buttonType: 1,
        });
    };

    // 인증번호 전송안내 Modal 닫을 때
    closeAuthModal = () => {
        this.toggleModal('isAuthModalOpen')();
        this.setState({
            inputState: 1,
            buttonState: 0,
            buttonType: 1,
            isCountdownOpen: true,
        });
    };

    closeErrorModal = () => {
        this.setState({
            isErrorModalOpen: false,
        });
    };

    handleConfirmAuth = () => {
        this.setState(
            {
                phoneAuthModalOpen: false,
            },
            () => {
                this.handleEasyLogin();
            },
        );
    };

    renderAuthModal() {
        return (
            <div>
                <div className="header">인증번호가 전송되었습니다.</div>
                <div className="contents">
                    <div style={{ marginBottom: 20 }}>
                        입력하신 번호로 인증번호가 전송되었습니다.
                        <br />
                        받으신 인증번호를 입력해주세요.
                    </div>
                    <div>
                        인증번호를 받지 못하셨다면 <br />
                        휴대폰 번호를 확인 후 다시 입력해주세요.
                    </div>
                </div>
            </div>
        );
    }

    resetAuthCode = () => {
        this.setState({
            inputState: 0,
            buttonState: 1,
            buttonType: 0,
            authCodeNumber: '',
            isExpired: true,
            smsVerifyErrorMessage: '인증번호 유효시간이 만료되었습니다.',
            isCountdownOpen: false,
        });
    };

    renderNotRegisterdModal() {
        return (
            <div>
                <div className="header">아직 회원이 아니신가요?</div>
                <div className="contents" style={{ marginBottom: 30 }}>
                    등록된 회원정보가 없습니다.
                    <br />
                    직토 보험대리점의 서비스를 이용하시려면
                    <br />
                    회원가입 또는 보험가입을 하셔야 합니다.
                </div>
                <div style={{ margin: '30px auto 0', width: '80%' }}>
                    <Link to={'/signup'}>
                        <button type="button" className="btn block basic">
                            회원 가입하기
                        </button>
                    </Link>
                </div>
            </div>
        );
    }

    renderPhoneAuthModal() {
        return (
            <div>
                <div className="header">휴대폰 번호 인증 성공</div>
                <div className="contents">
                    <div style={{ marginBottom: 20 }}>
                        나머지 정보를 입력하시면, 더 쉽고 편하게
                        <br />
                        직토의 모든 서비스를 이용하실 수 있습니다.
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const {
            authCodeNumber,
            smsVerifyErrorMessage,
            isAuthModalOpen,
            isExpired,
            isInvalidAuthCode,
            phoneNumber,

            inputState,
            buttonState,
            buttonType,
            isCountdownOpen,
            isPhoneNumberConfirmed,
            // phoneAuthModalOpen,
        } = this.state;

        const codeAuthFailed = isInvalidAuthCode; // 코드 검증 실패

        return (
            <div className="phone-number-verification aaa">
                <Input
                    required
                    type="number"
                    name="phoneNumber"
                    className="mb-0"
                    // title="휴대폰 번호"
                    inputClassName="block phone-input-border"
                    placeholder="숫자만 입력하세요."
                    validations="isMobile"
                    validationError="정확한 휴대폰 번호를 숫자만 입력하세요."
                    innerRef={c => {
                        this.phoneNumberRef = c;
                    }}
                    value={phoneNumber}
                    exportValue={this.handlePhoneNumberChange}
                    disabled={isPhoneNumberConfirmed}
                />
                {!this.props.auth.level && (
                    <div className="phone-confirm" style={{ paddingTop: 20 }}>
                        <div className="phone-confirm-input">
                            <div
                                className={`form-group ${
                                    isExpired || isInvalidAuthCode
                                        ? 'error'
                                        : ''
                                }`}
                            >
                                {/* <label
                                    htmlFor="authCodeNumber"
                                    className="label"
                                >
                                    인증번호
                                </label> */}
                                <input
                                    type={IS_MOBILE_DEVICE ? 'number' : 'text'}
                                    id="authCodeNumber"
                                    name="authCodeNumber"
                                    className="input block"
                                    onChange={this.handleChange}
                                    placeholder="인증번호를 입력하세요."
                                    disabled={inputState === 0}
                                    value={authCodeNumber}
                                />
                            </div>
                        </div>
                        <div className="phone-confirm-button">
                            <button
                                type="button"
                                className="btn block basic"
                                disabled={buttonState === 0}
                                onClick={
                                    buttonType === 0
                                        ? this.getAuthCode
                                        : this.confirmAuthCode
                                }
                            >
                                {BUTTON_TEXT[buttonType]}
                            </button>
                        </div>
                        {(isExpired ||
                            isInvalidAuthCode ||
                            (phoneNumber && buttonType === 1)) && (
                            <span
                                className={`validation-error ${
                                    codeAuthFailed ? 'is-invalid' : ''
                                }`}
                            >
                                {smsVerifyErrorMessage}
                            </span>
                        )}
                        {isCountdownOpen && (
                            <Countdown
                                second={180}
                                onEnd={this.resetAuthCode}
                            />
                        )}
                    </div>
                )}

                {isAuthModalOpen && (
                    <Modal
                        content={this.renderAuthModal()}
                        onDismiss={this.closeAuthModal}
                        style={{ width: 500, height: 280 }}
                    />
                )}
                {this.state.isErrorModalOpen && (
                    <ErrorModal
                        onDismiss={this.closeErrorModal}
                        msg={this.state.errorMessage}
                    />
                )}
                {this.state.isNotRegisterdOpen && (
                    <Modal
                        content={this.renderNotRegisterdModal()}
                        // style={{ width: 500, height: 269 }}
                        onDismiss={() => {
                            this.props.history.replace('/signup');
                        }}
                        hasActions={true}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    tempData: state.temp,
});

const mapDispatchToProps = dispatch => ({
    apiRequest: bindActionCreators(apiRequest, dispatch),
    simpleLogin: bindActionCreators(authActions.simpleLogin, dispatch),
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(PhoneVerificationNumber),
);
