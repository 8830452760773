import React, { Component } from 'react';
import Formsy from 'formsy-react';
import Checkbox from 'components/Checkbox';

// sub component
import Modal from 'components/Modal';

class Confirm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAgree1: false,
            isAgree2: false,
            answer: null,
            isLiabilityModal1: false,
            isLiabilityModal2: false,
            isAgreeYes: false,
        };
    }

    select = value => {
        return () => {
            this.setState({
                answer: value,
            });
            if (value == 'y') {
                this.agreeYes();
            }
        };
    };

    handleSelect = name => {
        return value => {
            this.setState({
                [name]: value,
            });
        };
    };

    handleConfirm = () => {
        this.props.onConfirm && this.props.onConfirm();
    };

    prevPage = () => {
        window.history.back();
    };

    liabilityModal1 = () => {
        this.setState({
            isLiabilityModal1: !this.state.isLiabilityModal1,
        });
    };

    liabilityModal2 = () => {
        this.setState({
            isLiabilityModal2: !this.state.isLiabilityModal2,
        });
    };
    agreeYes = () => {
        this.setState({
            isAgreeYes: !this.state.isAgreeYes,
        });
    };

    renderLiabilityModal1() {
        return (
            <div>
                <div className="header" style={{ letterSpacing: -1 }}>
                    여행자 제한/금지지역 확인
                </div>
                <div className="contents">
                    <div
                        style={{
                            marginBottom: 20,
                            width: 295,
                            boxSizing: 'border-box',
                            letterSpacing: -1,
                            lineHeight: '20px',
                            margin: '0 auto',
                            textAlign: 'left',
                        }}
                    >
                        외교통상부가 지정한 지역별 여행경보 신호등 중
                        적색경보(철수권고),흑색경보(여행금지),특별여행주의보(철수권고)특별여행경보(즉시대피)
                        지역은 보험가입이 제한됩니다. 외교통상부의 해외안전여행
                        사이트를 방문하셔서 여행하는 국가가 안전한 지역인지
                        확인하셨습니까?
                        <br />
                        <a
                            href="http://www.0404.go.kr/m/dev/main.do"
                            title="0404"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                                width: '100%',
                                textAlign: 'center',
                                display: 'inline-block',
                                padding: '5px 0',
                            }}
                        >
                            <b>▷ www.0404.go.kr</b>
                        </a>
                        <br />본 계약은 쿠바, 이란, 수단, 시리아, 북한,
                        크림반도내에서, 이 중 한곳으로 또는 이 중 한곳을
                        경유하여 여행할 예정인 경우 또는 여행하는 도중에
                        직/간접적으로 발생한 분실, 부상, 손해나 법적 배상책임에
                        대해서는 보장하지 않습니다.{' '}
                        <span
                            style={{
                                display: 'inline-block',
                                width: '100%',
                                textAlign: 'center',
                            }}
                        >
                            확인하셨습니까?
                        </span>
                    </div>
                </div>
            </div>
        );
    }

    renderLiabilityModal2() {
        return (
            <div>
                <div className="header" style={{ letterSpacing: -1 }}>
                    보험가입 시 유의 사항
                </div>
                <div className="contents">
                    <div
                        style={{
                            marginBottom: 20,
                            width: 295,
                            boxSizing: 'border-box',
                            letterSpacing: -1,
                            lineHeight: '20px',
                            margin: '0 auto',
                            textAlign: 'left',
                        }}
                    >
                        · 본 계약은 대한민국에 거주하는 개인에 한해 보장되며,
                        대한민국에 거주하지 않을 경우에는 본 계약의 효력은
                        발생하지 않습니다.
                        <br /> · 이 해외여행보험은 만 1세부터 79세까지 가입이
                        가능합니다.
                        <br />· 해외여행보험에서 최대로 가입할 수 있는
                        보험기간은 90일입니다.
                        <br />· 보험가입 이전에 이미 발생한 질병이력으로 인한
                        치료는 보상하지 아니합니다.
                        <br />· 직업, 직무 또는 동호회 활동목적으로 전문등반,
                        글라이더조정, 스카이다이빙, 스쿠버다이빙, 행글라이딩,
                        수상보트, 패러글라이딩 등의 위험한 운동이나 모터보트,
                        자동차, 오토바이에 의한 경주 등으로 인한 손해는 보상하지
                        아니합니다.
                    </div>
                </div>
            </div>
        );
    }

    renderAgreeYes() {
        return (
            <div>
                <div className="header" style={{ letterSpacing: -1 }}>
                    보험 가입 대상이 아닙니다.
                </div>
                <div className="contents">
                    <div
                        style={{
                            marginBottom: 20,
                            boxSizing: 'border-box',
                            letterSpacing: -1,
                            lineHeight: '20px',
                            margin: '20px auto 0',
                            textAlign: 'center',
                        }}
                    >
                        '예'를 선택하시면 보험 가입 신청이 불가능합니다.
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const {
            isAgree1,
            isAgree2,
            answer,
            isLiabilityModal1,
            isLiabilityModal2,
            isAgreeYes,
        } = this.state;
        const isDisabled = !(isAgree1 && isAgree2 && answer === 'n');

        return (
            <div className="section">
                <div className="content">
                    <div className="progress progress-2">
                        <div className="progress-seperate seperate-2" />
                    </div>
                    <div>
                        <b>가입 전 알릴 의무를 확인해주세요.</b>
                    </div>
                    <div style={{ marginTop: 20, marginBottom: 20 }}>
                        현재 해외에 체류 또는 거주 중이시거나 해외 영주권자,
                        시민권자, 외국인이시면서 본국으로 출국하십니까?
                    </div>
                    <div
                        style={{
                            marginBottom: 20,
                            fontSize: 12,
                            color: '#9c9c9c',
                        }}
                    >
                        '예'를 선택하시면 보험 가입 신청이 불가능합니다.
                    </div>
                    <div className="flex answer-wrap">
                        <button
                            type="button"
                            className={`btn outline flex-1 ${
                                answer === 'y' ? 'basic-clicked' : ''
                            }`}
                            style={{ marginRight: 5 }}
                            onClick={this.select('y')}
                        >
                            예
                        </button>
                        <button
                            type="button"
                            className={`btn outline flex-1 ${
                                answer === 'n' ? 'basic-clicked' : ''
                            }`}
                            style={{ marginLeft: 5 }}
                            onClick={this.select('n')}
                        >
                            아니오
                        </button>
                    </div>
                    <Formsy>
                        <div
                            className="top-line terms-agree"
                            style={{ border: 0, marginTop: 0, marginBottom: 0 }}
                        >
                            <span
                                onClick={this.liabilityModal1}
                                className="txt"
                                style={{ cursor: 'pointer' }}
                            >
                                여행자 제한/금지 지역 확인
                                <i className="fal fa-chevron-right" />
                            </span>

                            <Checkbox
                                name="agreeTerms1"
                                value={isAgree1}
                                required
                                className="term-checkbox"
                                validationError="여행자 제한/금지 지역을 확인해주세요."
                                onChange={this.handleSelect('isAgree1')}
                                innerRef={c => {
                                    this.termsRef = c;
                                }}
                            />
                        </div>
                        <div
                            className="top-line terms-agree"
                            style={{ border: 0, marginTop: 0, paddingTop: 30 }}
                        >
                            <span
                                onClick={this.liabilityModal2}
                                className="txt"
                                style={{ cursor: 'pointer' }}
                            >
                                보험가입시 유의 사항
                                <i className="fal fa-chevron-right" />
                            </span>

                            <Checkbox
                                name="agreeTerms2"
                                value={isAgree2}
                                required
                                className="term-checkbox"
                                validationError="보험가입시 유의 사항을 확인해주세요."
                                onChange={this.handleSelect('isAgree2')}
                                innerRef={c => {
                                    this.termsRef2 = c;
                                }}
                            />
                        </div>
                        <div style={{ fontSize: 12, color: '#9c9c9c' }}>
                            위 질문은 보험가입에 매우 중요한 사실이며, 사실과
                            답변이 다를 경우 계약유지 또는 보험금 지급에 영향을
                            미칠 수 있으므로 사실대로 선택해주세요.
                        </div>

                        <div className="section" style={{ marginTop: 30 }}>
                            <div>
                                <button
                                    type="button"
                                    className="btn block basic"
                                    disabled={isDisabled}
                                    onClick={this.handleConfirm}
                                >
                                    가입 신청 완료
                                </button>
                            </div>
                        </div>
                    </Formsy>
                </div>
                {isLiabilityModal1 && (
                    <Modal
                        content={this.renderLiabilityModal1()}
                        onDismiss={this.liabilityModal1}
                        // style={{ width: 500, height: 400 }}
                    />
                )}
                {isLiabilityModal2 && (
                    <Modal
                        content={this.renderLiabilityModal2()}
                        onDismiss={this.liabilityModal2}
                        // style={{ width: 500, height: 479 }}
                    />
                )}
                {isAgreeYes && (
                    <Modal
                        content={this.renderAgreeYes()}
                        onDismiss={this.agreeYes}
                        // style={{ width: 500, height: 479 }}
                    />
                )}
            </div>
        );
    }
}

export default Confirm;
