import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Dropdown extends Component {
  constructor(props) {
    super(props);

    const defaultIndex =
      this.props.defaultValue == null
        ? 0
        : this.props.options.map(option => option.value).indexOf(this.props.defaultValue);

    this.state = {
      listVisible: false,
      selected: this.props.selected,
      selectedIndex: defaultIndex,
    };
    this.containerRef = React.createRef();
    this.itemRef = React.createRef();
  }

  handleShow = () => {
    this.setState({
      listVisible: true,
    });

    document.addEventListener('click', this.handleHide);
  };

  handleHide = () => {
    this.setState({
      listVisible: false,
    });

    document.removeEventListener('click', this.handleHide);
  };

  handleSelect = (item, index) => {
    return () => {
      this.setState({
        selected: item,
        selectedIndex: index,
      });
      this.props.onSelect && this.props.onSelect(item.value);
    };
  };

  renderListItems = () => {
    const items = [];
    const { options, defaultValue } = this.props;

    for (var i = 0; i < options.length; i++) {
      const item = options[i];
      const hasDefaultValue = defaultValue && defaultValue === item.value;

      items.push(
        <div
          key={`option-${i}`}
          className={`item ${hasDefaultValue ? 'is-active' : ''}`}
          onClick={this.handleSelect(item, i)}
          ref={this.itemRef}
        >
          {item.name}
        </div>,
      );
    }
    return items;
  };

  getSelectedOptionName = () => {
    const { selected } = this.state;
    const { options, placeholder } = this.props;
    if (!selected) return;
    const selectedOption = options.find(option => option.value === selected.value);
    return selectedOption ? selectedOption.name : placeholder;
  };

  render() {
    const { placeholder, className } = this.props;
    const { listVisible, selected, selectedIndex } = this.state;
    const itemHeight = this.itemRef.current ? this.itemRef.current.offsetHeight : 0;

    const containerClassName = `dropdown-container${listVisible ? ' show' : ''} ${className || ''}`;
    const displayClassName = `dropdown-display${listVisible ? ' clicked' : ''}`;

    if (this.containerRef.current) {
      this.containerRef.current.scrollTop = selectedIndex * itemHeight;
    }

    return (
      <div className={containerClassName} style={this.props.style ? this.props.style : null}>
        <div className={displayClassName} onClick={this.handleShow}>
          <span>{selected == null ? placeholder : this.getSelectedOptionName()}</span>
          <i className="far fa-chevron-down" />
        </div>
        <div className="dropdown-list">
          <div ref={this.containerRef}>{this.renderListItems()}</div>
        </div>
      </div>
    );
  }
}

Dropdown.propTypes = {
  options: PropTypes.array,
  onSelect: PropTypes.func,
  exclude: PropTypes.func,
  selected: PropTypes.any,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  defaultValue: PropTypes.any,
};

Dropdown.defaultProps = {
  options: [],
  onSelect: () => {},
  exclude: null,
  selected: null,
  placeholder: '선택하세요.',
  className: '',
  defaultValue: null,
};

export default Dropdown;
