import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as moment from 'moment';
import 'moment/locale/ko';
import * as Sentry from '@sentry/browser';
// constants
import {
    INSURANCE_REGISTER,
    INSURANCE_REGISTER_URL,
    SIGN_UP,
    SIGN_UP_URL,
    MY_ZIKTO_CASH,
    MY_ZIKTO_CASH_URL,
    USER_INFO,
    USER_INFO_URL,
} from 'constants/paths';
import { INSURANCE_REGISTER_TIMEOUT } from 'constants/timeout';
// redux
import { apiRequest } from 'redux/actions/api/utils';
import authActions from 'redux/actions/auth';
import tempActions from 'redux/actions/temp';
// sub components
import Form from './Form';
import Confirm from './Confirm';
import Payment from './Payment';
import ErrorModal from 'components/ErrorModal';
import Modal from 'components/Modal';
// utils
import { errorHandler } from 'utils/errorHandler';
import { padDigits } from 'utils/format';
import { getItem, setItem, removeItem } from 'utils/handleStorage';
import { TOKEN_TIMEOUT } from 'constants/timeout';

moment.locale('ko');

class Step2 extends Component {
    constructor(props) {
        super(props);

        if (!this.hasData())
            return this.props.history.replace('/purchase/step1');

        this.state = {
            isPristine: true,
            canSubmit: false,
            isFormSubmitted: false,
            isConfirmed: false,
            hasSignupError: false,
            hasRegisterError: false,
            hasErrorAfterPay: false,
            ifrLoaded: false,
        };
    }

    componentDidMount() {
        this._mounted = true;
    }

    componentWillUnmount() {
        this._mounted = false;

        Sentry.withScope(scope => {
            scope.setExtra('Z2Token', getItem('Z2Token'));
            scope.setExtra('tempData', getItem('Z2TempData'));
            Sentry.captureMessage('STEP2 unmount 시');
        });
    }

    hasData = () => {
        // TODO: 데이터 전체 확인
        const { startDate, endDate } = this.props.tempData;
        return !(startDate == null || endDate == null);
    };

    getMemberNames = data => {
        if (!data) return '';
        const result = Object.keys(data)
            .filter(key => /companionNM-/.test(key))
            .map(el => data[el]);
        result.unshift(data.username);
        return result.join(',');
    };

    getJMs = data => {
        if (!data) return '';
        const result = Object.keys(data)
            .filter(key => /companionNN2-/.test(key))
            .map(el => data[el]);
        result.unshift(data.nn2);
        return result.join(',');
    };

    resetAPIError = () => {
        this.setState({
            hasSignupError: false,
            isFormSubmitted: false,
            isConfirmed: false,
        });
    };

    handleSave = data => {
        this.setState(
            {
                isFormSubmitted: true,
            },
            () => {
                this.props.saveTempData({
                    username: data.username || this.props.tempData.username,
                    // phoneNumber: data.phoneNumber,
                    email: data.email || this.props.tempData.email,
                    names: this.getMemberNames(data),
                    jms: this.getJMs(data),
                });
            },
        );
    };

    // 가입신청완료
    getReadyForPayment = () => {
        this.setState({
            isConfirmed: true,
        });
    };

    // 보험가입
    handleRegister = async imp_uid => {
        const {
            users,
            insuType,
            startDate,
            endDate,
            startTime,
            endTime,
            jms,
            names,
            paidAmount,
            paidTravelPoint,
            paidZiktoCash,
            email,
            username,
        } = this.props.tempData;
        const paramObj = {
            users,
            type: insuType,
            startDate: `${moment(startDate).format('YYYYMMDD')}${padDigits(
                startTime,
                2,
            )}`,
            endDate: `${moment(endDate).format('YYYYMMDD')}${padDigits(
                endTime,
                2,
            )}`,
            // endDate: `${moment(endDate).format('YYYYMMDD')}`,
            joomin: jms,
            payment: imp_uid,
            names: names,
            krw: paidAmount,
            ziktoCash: paidZiktoCash,
            travelPoint: paidTravelPoint,
            email,
            name: username,
        };

        Sentry.withScope(scope => {
            scope.setExtra('Z2Token', getItem('Z2Token'));
            scope.setExtra('tempData', getItem('Z2TempData'));
            Sentry.captureMessage('웹 결제전');
        });

        try {
            await this.props
                .apiRequest({
                    type: INSURANCE_REGISTER,
                    path: INSURANCE_REGISTER_URL,
                    data: paramObj,
                    config: {
                        timeout: INSURANCE_REGISTER_TIMEOUT,
                    },
                })
                .then(async response => {
                    if (response.code !== 200)
                        return Promise.reject(new Error(response.message));

                    await this.props.authUpdate(2);
                    removeItem('Z2TempData');
                    this.props.history.replace(
                        `/purchase/confirmed/${response.order.id}`,
                    );
                })
                .catch(e => {
                    this._mounted &&
                        this.setState({
                            hasRegisterError: true,
                            hasErrorAfterPay: true,
                            impId: imp_uid,
                        });

                    return Promise.reject(e);
                });
        } catch (e) {
            errorHandler(e, INSURANCE_REGISTER_URL, this.props.tempData.userId);
        }
    };

    // TODO: 로그인 결과로 포인트와 캐시를 받으면 편리하겠다.
    getMyZiktoCash = async () => {
        try {
            await this.props
                .apiRequest({
                    type: MY_ZIKTO_CASH,
                    path: MY_ZIKTO_CASH_URL,
                    method: 'get',
                })
                .then(response => {
                    const ziktoCash = Number(
                        response.code === 200 ? response.balance : 0,
                    );

                    this.props.saveTempData({
                        ziktoCash,
                    });
                })
                .catch(e => {
                    return Promise.reject(e);
                });
        } catch (e) {
            errorHandler(e, MY_ZIKTO_CASH_URL, this.props.tempData.userId);
        }
    };

    handleSignup = async data => {
        const { username, phoneNumber, email, password } = data;
        const { birthday, gender } = this.state;

        const paramObj = {
            realName: username,
            name: username,
            phoneNumber: phoneNumber,
            email,
            password,
            birth: birthday,
            gender,
        };

        try {
            await this.props
                .apiRequest({
                    type: SIGN_UP,
                    path: SIGN_UP_URL,
                    data: paramObj,
                })
                .then(async response => {
                    await setItem(
                        'Z2Token',
                        response.accessToken,
                        TOKEN_TIMEOUT,
                    );

                    Sentry.withScope(scope => {
                        scope.setExtra('Z2Token', getItem('Z2Token'));
                        Sentry.captureMessage(SIGN_UP);
                    });
                    // this.props.authUpdate(2);
                    this.fetchUserInfo();
                })
                .catch(e => {
                    this._mounted &&
                        this.setState({
                            hasSignupError: true,
                        });
                    return Promise.reject(e);
                });
        } catch (e) {
            errorHandler(e, SIGN_UP_URL, this.props.tempData.userId);
        }
    };

    fetchUserInfo = async () => {
        try {
            await this.props
                .apiRequest({
                    type: USER_INFO,
                    path: USER_INFO_URL,
                })
                .then(response => {
                    Z2.log('response', response);

                    this.props.saveTempData({
                        username: response.name,
                        email: response.email,
                        userId: response.id,
                    });
                    this.props.authUpdate(2);
                })
                .catch(e => {
                    return Promise.reject(e);
                });
        } catch (e) {
            errorHandler(e, USER_INFO_URL, this.props.tempData.userId);
        }
    };

    removeTempData = () => {
        removeItem('Z2TempData');
    };

    handleConfirmErrorAfterPay = () => {
        const tempData = JSON.parse(getItem('Z2TempData'));
        Z2.log('handleConfirmErrorAfterPay', tempData);
        // hasAfterPay
        this.setState(
            {
                hasErrorAfterPay: false,
            },
            this.removeTempData(),
        );
    };

    renderErrorAfterPayModal = () => {
        return (
            <div>
                <div className="header">서버오류로 진행이 불가합니다.</div>
                <div className="contents">
                    <div>
                        <div style={{ marginBottom: 20 }}>
                            contact@zikto.com 으로 연락주시면
                            <br />
                            환불처리를 도와드리겠습니다.
                            <br />
                            죄송합니다.
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    render() {
        if (!this.hasData()) return null;

        const { isFormSubmitted, isConfirmed } = this.state;
        const {
            startDate,
            endDate,
            startTime,
            endTime,
            username,
            companions,
            email,
            phoneNumber,
            estimate,
            estimateTotal,
            names,
            jms,
            insuType,
            birthday,
            gender,
            travelPoint,
            ziktoCash,
            rates,
            age,
            period,
        } = this.props.tempData;

        return (
            <div>
                {!isFormSubmitted && !isConfirmed && (
                    <Form
                        username={username}
                        startDate={startDate}
                        endDate={endDate}
                        startTime={startTime}
                        endTime={endTime}
                        companions={companions}
                        email={email}
                        phoneNumber={phoneNumber}
                        estimate={estimate}
                        estimateTotal={estimateTotal}
                        names={names}
                        jms={jms}
                        insuType={insuType}
                        birthday={birthday}
                        save={this.handleSave}
                        gender={gender}
                        onSignup={this.handleSignup}
                    />
                )}
                {isFormSubmitted && !isConfirmed && (
                    <Confirm onConfirm={this.getReadyForPayment} />
                )}

                {isFormSubmitted && isConfirmed && (
                    <Payment
                        username={username}
                        startDate={startDate}
                        endDate={endDate}
                        startTime={startTime}
                        endTime={endTime}
                        birthday={birthday}
                        gender={gender}
                        phoneNumber={phoneNumber}
                        insuType={insuType}
                        email={email}
                        point={travelPoint}
                        estimate={estimate}
                        ziktoCash={ziktoCash}
                        names={names}
                        onSuccess={this.handleRegister}
                        rates={rates}
                        age={age}
                        period={period}
                        authLevel={this.props.auth.level}
                    />
                )}

                {(this.state.hasSignupError || this.state.hasRegisterError) && (
                    <ErrorModal onDismiss={this.resetAPIError} />
                )}
                {this.state.hasErrorAfterPay && (
                    <Modal
                        content={this.renderErrorAfterPayModal()}
                        onDismiss={this.handleConfirmErrorAfterPay}
                        style={{ width: 500, height: 250 }}
                    />
                )}

                <div>
                    <iframe
                        src="https://zikto.com/broker.html"
                        name="zikto"
                        onLoad={() => {
                            this.setState({ ifrLoaded: true });
                        }}
                        title="iframe"
                        className={`${this.state.ifrLoaded ? '' : 'hidden'}`}
                        style={{
                            position: 'absolute',
                            width: 1,
                            height: 1,
                            overflow: 'hidden',
                            zIndex: -1,
                        }}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    tempData: state.temp,
});

const mapDispatchToProps = dispatch => ({
    apiRequest: bindActionCreators(apiRequest, dispatch),
    saveTempData: bindActionCreators(tempActions.saveTempData, dispatch),
    authUpdate: bindActionCreators(authActions.update, dispatch),
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Step2),
);
