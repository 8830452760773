import React, { Component } from 'react';
import { propTypes, withFormsy } from 'formsy-react';

// css
import './style.css';

class Checkbox extends Component {
  state = { value: true };

  changeValue = event => {
    this.props.setValue(event.target.checked);
    this.props.onChange && this.props.onChange(event.target.checked);
  };

  render() {
    const { isPristine, required, getValue, name, title, validationError } = this.props;

    const value = getValue();
    const hasError = !isPristine() && required && !value;

    return (
      <div className={`checkbox-wrap ${value ? 'is-checked' : ''} ${hasError ? 'error' : ''}`}>
        <label htmlFor={name} className="label">
          {title}
          <i className="fal fa-check" />
        </label>
        <input
          onChange={this.changeValue}
          name={name}
          id={name}
          type={'checkbox'}
          checked={value}
          className="checkbox"
          data-checked={value}
        />
        {hasError && <span className="validation-error">{validationError}</span>}
      </div>
    );
  }
}

Checkbox.propTypes = {
  ...propTypes,
};

export default withFormsy(Checkbox);
