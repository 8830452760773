import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
// sub components
import History from '../History';
import DisplayTravelPoint from 'pages/Purchase/DisplayTravelPoint';
// constants
import {
    USER_INFO,
    USER_INFO_URL,
    USER_TRAVEL_POINT,
    USER_TRAVEL_POINT_URL,
    TRAVEL_POINT_HISTORY,
    TRAVEL_POINT_HISTORY_URL,
} from 'constants/paths';
// redux
import { apiRequest } from 'redux/actions/api/utils';
import authActions from 'redux/actions/auth';
import { errorHandler } from 'utils/errorHandler';
import tempActions from 'redux/actions/temp';

class TravelPoint extends Component {
    state = { isLoaded: false, travelPointHistory: [] };

    componentDidMount() {
        this._mounted = true;

        Promise.all([
            this.fetchMyTravelPoint(),
            this.fetchMyTravelHistory(),
            this.getMyInfo(),
        ])
            .then(() => {
                this._mounted &&
                    this.setState({
                        isLoaded: true,
                    });
            })
            .catch(e => {});
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    fetchMyTravelPoint = async () => {
        try {
            await this.props
                .apiRequest({
                    type: USER_TRAVEL_POINT,
                    path: USER_TRAVEL_POINT_URL,
                    method: 'get',
                })
                .then(response => {
                    this._mounted &&
                        this.setState({
                            travelPoint: Number(
                                response && response.code === 200
                                    ? response.balance
                                    : 0,
                            ),
                        });
                })
                .catch(e => {
                    return Promise.reject(e);
                });
        } catch (e) {
            errorHandler(e, USER_TRAVEL_POINT_URL, this.props.tempData.userId);
        }
    };

    fetchMyTravelHistory = async () => {
        try {
            await this.props
                .apiRequest({
                    type: TRAVEL_POINT_HISTORY,
                    path: TRAVEL_POINT_HISTORY_URL,
                    method: 'get',
                })
                .then(response => {
                    if (!response || (response.code && response.code !== 200))
                        return Promise.reject();
                    this._mounted &&
                        this.setState({
                            travelPointHistory: response,
                        });
                })
                .catch(e => {
                    return Promise.reject(e);
                });
        } catch (e) {
            errorHandler(
                e,
                TRAVEL_POINT_HISTORY_URL,
                this.props.tempData.userId,
            );
        }
    };

    getMyInfo = () => {
        if (!this._mounted) return;
        return this.props
            .apiRequest({
                type: USER_INFO,
                path: USER_INFO_URL,
            })
            .then(response => {
                Z2.log('response', response);
                this.myInfo = response;
                this._mounted &&
                    this.setState({
                        username: response.realName,
                        email: response.email,
                        ssn: response.birth.replace(/-/g, '').slice(2),
                        gender: response.gender,
                        phoneNumber: response.phoneNumber,
                    });
                this.props.authUpdate(response.email ? 2 : 1);
                this.props.saveTempData({ userId: response.id });
            })
            .catch(e => {});
    };

    render() {
        const { isLoaded } = this.state;

        return (
            <div>
                {!isLoaded && <div className="loader" />}
                {isLoaded && (
                    <div style={{ width: '100%' }}>
                        <DisplayTravelPoint
                            username={this.state.username}
                            point={this.state.travelPoint}
                        />

                        <section
                            className="section section1"
                            style={{ margin: '30px 0' }}
                        >
                            <div className="content">
                                <div
                                    style={{
                                        fontSize: 20,
                                        marginBottom: 20,
                                    }}
                                >
                                    <b>트래블포인트(Travel Point)?</b>
                                </div>
                                <div>
                                    해외여행보험 가입시 결제 금액의 최대 10%
                                    까지 현금처럼 <br />
                                    사용이 가능한 포인트입니다.
                                </div>
                                {/* <div style={{ marginTop: 30 }}>
                                <Link
                                    to={
                                        '/travel-point/member-save'
                                    }
                                >
                                    <button
                                        type="button"
                                        className="btn block outline"
                                    >
                                        <b>포인트 적립</b>
                                    </button>
                                </Link>
                            </div> */}
                            </div>
                        </section>

                        <section className="section section3">
                            <div className="content">
                                <div className="my-zikto-history">
                                    <h3 style={{ fontSize: 20 }}>
                                        트래블포인트 이용내역
                                    </h3>
                                    <div className="tab-wrap">
                                        <div>
                                            <History
                                                data={
                                                    this.state
                                                        .travelPointHistory
                                                }
                                            />
                                        </div>
                                        <div
                                            className="total"
                                            style={{
                                                top: -40,
                                                right: 0,
                                            }}
                                        >
                                            총{' '}
                                            {
                                                this.state.travelPointHistory
                                                    .length
                                            }
                                            건
                                        </div>
                                    </div>
                                </div>

                                {/* <div
                                className="btn block"
                                style={{
                                    textAlign: 'center',
                                    paddingBottom: 20,
                                    paddingTop: 10,
                                    marginBottom: 30,
                                    fontSize: 20,
                                }}
                            >
                                <i className="fal fa-chevron-down" />
                            </div> */}
                            </div>
                        </section>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    tempData: state.temp,
});

const mapDispatchToProps = dispatch => ({
    apiRequest: bindActionCreators(apiRequest, dispatch),
    logout: bindActionCreators(authActions.login, dispatch),
    saveTempData: bindActionCreators(tempActions.saveTempData, dispatch),
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(TravelPoint),
);
