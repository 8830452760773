import React from 'react';
import Nav from 'components/Nav';
import { IS_MOBILE } from 'constants/device';

const About = () => (
    <div className="about-page container-fixed">
        <Nav className="light" />
        <div className="container">
            <div className="wrap">
                <div className="page-header">
                    <h1>About us</h1>
                </div>
                {/* <div className="wrap-area"> */}
                <section
                    className="section section1"
                    style={{ marginBottom: 20 }}
                >
                    <div className="content">
                        <div className="title">직토</div>
                        <div className="sub-title">
                            빠르게 변화하는 다양한 리스크 <br />보험업계에 절실한{' '}
                            <strong>디지털 혁신</strong>
                            <br />
                            보험사들은 얼마나 준비하고 있나요?
                        </div>
                    </div>
                </section>
                <section
                    className="section section2"
                    style={{ marginBottom: 20 }}
                >
                    <div className="content">
                        <div className="about-desc">
                            <span style={{ marginBottom: 20 }}>
                                기존 방식과 전혀 다른 방식으로 진행되어야 하는 전산화 과정
                                보험사의 기존 조직에서 수행하기는 매우 어렵습니다.
                            </span>
                            <span style={{ marginBottom: 20 }}>
                                직토는 블록체인 기술을 바탕으로 보험사, 보험자,
                                설계자 모두에게 이로운 플랫폼을 만들고 싶다는
                                생각에서 출발했습니다. 
                            </span>
                            <span>
                                설계, 언더라이팅, 판매, 관리 등 직토와 함께라면 빠르게 
                                앞서나갈 수 있습니다.
                            </span>
                        </div>
                    </div>
                </section>
                <section className="section section3">
                    <div className="content">
                        <div className="">
                            <strong>보험에 직언하다, 직토</strong>
                            <p className="zikto-logo">
                                <img src={'/imgs/common/logo.png'} alt="" />
                            </p>
                        </div>
                    </div>
                </section>
                {/* </div> */}
            </div>
        </div>
    </div>
);

export default About;
