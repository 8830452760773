import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// male: 1, female: 2
class SelectGender extends PureComponent {
    static defaultProps = {
        onSelect: () => {},
        className: '',
        gender: 0,
    };

    static propsTypes = {
        onSelect: PropTypes.func,
        className: PropTypes.string,
        gender: PropTypes.number,
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.gender !== prevState.gender) {
            return {
                gender: nextProps.gender,
            };
        }
        return null;
    }

    constructor(props) {
        super(props);

        this.state = {
            gender: this.props.gender,
        };
    }

    select = gender => {
        return () => {
            this.setState({ gender });
            this.props.onSelect && this.props.onSelect(gender);
        };
    };

    render() {
        const { gender } = this.state;
        const { className, disabled } = this.props;

        return (
            <div
                className={`form-group inline btn ${
                    className ? className : ''
                }`}
            >
                <label className="label">성별</label>
                <div className="block gender-block">
                    <div className="input-wrap">
                        <button
                            type="button"
                            className={`btn inline-button input-button ${
                                gender === 1 ? 'basic-clicked' : ''
                            }`}
                            style={{ marginRight: 5 }}
                            onClick={this.select(1)}
                            disabled={disabled}
                        >
                            남성
                        </button>
                        <button
                            type="button"
                            className={`btn inline-button input-button ${
                                gender === 2 ? 'basic-clicked' : ''
                            }`}
                            style={{ marginLeft: 5 }}
                            onClick={this.select(2)}
                            disabled={disabled}
                        >
                            여성
                        </button>
                        <span className="validation-error">
                            성별을 선택하세요.
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

export default SelectGender;
