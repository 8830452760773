import React from 'react';
import PropTypes from 'prop-types';
import guaranteeContents from './guaranteeContents';

const getType = (isMinor, isStandard) => {
    const ageType = isMinor ? '0' : '1';
    const insType = isStandard ? '0' : '1';
    return ageType + insType;
};

const ProductDetail = props => {
    const isMinor = props.age < 16;
    const isStandard = props.type === 'standard';
    const contents = guaranteeContents[getType(isMinor, isStandard)];

    return (
        <div className="section">
            <div className="content">
                <div className="sheet0" style={{ marginBottom: 30 }}>
                    <div className="header-title">
                        <b>[{isStandard ? '스탠다드' : '프리미엄'}]</b> 보험나이{' '}
                        {props.age}
                        세, 여행기간 {props.period}일
                    </div>
                    <div className="box first-box">
                        <div className="header">상해 보장</div>
                        <div className="flex">
                            <div className="flex" style={{ width: 170 }}>
                                {isMinor ? (
                                    <span>후유장해</span>
                                ) : (
                                    <span>사망/후유장해</span>
                                )}
                            </div>
                            <div className="flex flex-1 flex-column justify-content-center  align-items-flex-end">
                                {contents.accident[0]}{' '}
                                {isStandard ? '만원' : '억원'}
                            </div>
                        </div>
                        <div className="flex">
                            <div className="flex" style={{ width: 170 }}>
                                해외 의료비
                            </div>
                            <div className="flex flex-1 flex-column justify-content-center  align-items-flex-end">
                                {contents.accident[1]} 만원
                            </div>
                        </div>
                        <div className="flex">
                            <div className="flex" style={{ width: 170 }}>
                                소득보상금
                            </div>
                            <div className="flex flex-1 flex-column justify-content-center  align-items-flex-end">
                                {contents.accident[2]} 만원
                            </div>
                        </div>
                    </div>
                    <div className="box">
                        <div className="header">질병 보장</div>
                        {!isMinor && (
                            <div className="flex">
                                <div className="flex" style={{ width: 170 }}>
                                    사망 및 80%이상 후유장애
                                </div>
                                <div className="flex flex-1 justify-content-flex-end align-items-center">
                                    {contents.illness[1]} 만원
                                </div>
                            </div>
                        )}

                        <div className="flex">
                            <div className="flex" style={{ width: 170 }}>
                                해외의료비
                            </div>
                            <div className="flex flex-1 justify-content-flex-end align-items-center">
                                {contents.illness[0]} 만원
                            </div>
                        </div>
                    </div>
                    <div className="box">
                        <div className="flex">
                            <div className="flex" style={{ width: 170 }}>
                                <b>휴대품 손해</b>
                            </div>
                            <div className="flex flex-1 justify-content-flex-end align-items-center">
                                {contents.phone} 만원
                            </div>
                        </div>
                    </div>
                    <div className="box">
                        <div className="flex">
                            <div className="flex" style={{ width: 170 }}>
                                배상책임
                            </div>
                            <div className="flex flex-1 justify-content-flex-end align-items-center">
                                {contents.etc[0]} 만원
                            </div>
                        </div>
                        <div className="flex">
                            <div className="flex" style={{ width: 170 }}>
                                식중독 입원비
                            </div>
                            <div className="flex flex-1 justify-content-flex-end align-items-center">
                                {contents.etc[1]} 만원
                            </div>
                        </div>
                    </div>
                    <div className="box" style={{ marginBottom: 0 }}>
                        <div className="flex">
                            <div className="flex" style={{ width: 170 }}>
                                중대사고 구조송환비용
                            </div>
                            <div className="flex flex-1 justify-content-flex-end align-items-center">
                                {contents.etc2[0]} 만원
                            </div>
                        </div>
                        <div className="flex">
                            <div className="flex" style={{ width: 170 }}>
                                항공기납치
                            </div>
                            <div className="flex flex-1 justify-content-flex-end align-items-center">
                                {contents.etc2[1]} 만원
                            </div>
                        </div>
                        <div className="flex">
                            <div className="flex" style={{ width: 170 }}>
                                중단사고발생 추가비용
                            </div>
                            <div className="flex flex-1 justify-content-flex-end align-items-center">
                                {contents.etc2[2]} 만원
                            </div>
                        </div>
                        <div className="flex">
                            <div className="flex" style={{ width: 170 }}>
                                항공기 및 수화물 지연보상
                            </div>
                            <div className="flex flex-1 justify-content-flex-end align-items-center">
                                {contents.etc2[3]} 만원
                            </div>
                        </div>
                        <div className="flex">
                            <div className="flex" style={{ width: 170 }}>
                                여권분실 및 재발급비용
                            </div>
                            <div className="flex flex-1 justify-content-flex-end align-items-center">
                                {contents.etc2[4]} 만원
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

ProductDetail.propTypes = {
    type: PropTypes.string,
    age: PropTypes.number,
    period: PropTypes.number,
};

ProductDetail.defaultProps = {
    type: 'standard',
    age: 16,
    period: 30,
};

export default ProductDetail;
