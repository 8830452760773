import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import History from './History';
import DisplayZiktoCash from 'pages/Purchase/DisplayZiktoCash';
// constants
import {
    USER_INFO,
    USER_INFO_URL,
    MY_ZIKTO_CASH,
    MY_ZIKTO_CASH_URL,
    MY_ZIKTO_CASH_HISTORY,
    MY_ZIKTO_CASH_HISTORY_URL,
} from 'constants/paths';
// redux
import { apiRequest } from 'redux/actions/api/utils';
// utils
import { errorHandler } from 'utils/errorHandler';

class MyZiktoCash extends Component {
    state = { isLoaded: false, cashHistory: [] };

    componentDidMount() {
        this._mounted = true;

        Promise.all([
            this.fetchMyZiktoCash(),
            this.fetchZiktoCashHistory(),
            this.getMyInfo(),
        ])
            .then(() => {
                this._mounted &&
                    this.setState({
                        isLoaded: true,
                    });
            })
            .catch(e => {});
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    fetchMyZiktoCash = async () => {
        try {
            await this.props
                .apiRequest({
                    type: MY_ZIKTO_CASH,
                    path: MY_ZIKTO_CASH_URL,
                    method: 'get',
                })
                .then(response => {
                    this._mounted &&
                        this.setState({
                            ziktoCash: Number(
                                response.code === 200 ? response.balance : 0,
                            ),
                        });
                })
                .catch(e => {
                    return Promise.reject(e);
                });
        } catch (e) {
            errorHandler(e, MY_ZIKTO_CASH_URL, this.props.tempData.userId);
        }
    };

    fetchZiktoCashHistory = async () => {
        try {
            await this.props
                .apiRequest({
                    type: MY_ZIKTO_CASH_HISTORY,
                    path: MY_ZIKTO_CASH_HISTORY_URL,
                    method: 'get',
                })
                .then(response => {
                    if (!response)
                        return Promise.reject(new Error(response.message));
                    this._mounted &&
                        this.setState({
                            cashHistory: response,
                        });
                })
                .catch(e => {
                    return Promise.reject(e);
                });
        } catch (e) {
            errorHandler(
                e,
                MY_ZIKTO_CASH_HISTORY_URL,
                this.props.tempData.userId,
            );
        }
    };

    getMyInfo = () => {
        if (!this._mounted) return;
        return this.props
            .apiRequest({
                type: USER_INFO,
                path: USER_INFO_URL,
            })
            .then(response => {
                Z2.log('response', response);
                this.myInfo = response;
                this._mounted &&
                    this.setState({
                        username: response.realName,
                        email: response.email,
                        ssn: response.birth.replace(/-/g, '').slice(2),
                        gender: response.gender,
                        phoneNumber: response.phoneNumber,
                    });
                this.props.authUpdate(response.email ? 2 : 1);
            })
            .catch(e => {});
    };

    render() {
        const { isLoaded } = this.state;

        return (
            <div>
                {!isLoaded && <div className="loader" />}
                {isLoaded && (
                    <div style={{ width: '100%' }}>
                        <DisplayZiktoCash
                            username={this.state.username}
                            cash={this.state.ziktoCash}
                        />
                        <section
                            className="section section1"
                            style={{ margin: '30px 0' }}
                        >
                            <div className="content">
                                <div
                                    style={{
                                        fontSize: 20,
                                        marginBottom: 20,
                                    }}
                                >
                                    <b>직토캐시(Zikto Cash)?</b>
                                </div>
                                <div>
                                    직토의 모든 서비스에서 현금처럼 사용 가능한
                                    포인트입니다.
                                </div>
                            </div>
                        </section>

                        <section className="section section3">
                            <div className="content">
                                <div className="my-zikto-history">
                                    <h3 style={{ fontSize: 20 }}>
                                        직토캐시 이용내역
                                    </h3>
                                    <div className="tab-wrap">
                                        <div label="1주일">
                                            <History
                                                data={this.state.cashHistory}
                                            />
                                        </div>
                                        <div
                                            className="total"
                                            style={{
                                                top: -40,
                                                right: 0,
                                            }}
                                        >
                                            총 {this.state.cashHistory.length}건
                                        </div>
                                    </div>
                                </div>

                                {/* <div
                                    className="btn block"
                                    style={{
                                        textAlign: 'center',
                                        paddingBottom: 20,
                                        paddingTop: 10,
                                        marginBottom: 30,
                                        fontSize: 20,
                                    }}
                                >
                                    <i className="fal fa-chevron-down" />
                                </div> */}
                            </div>
                        </section>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    tempData: state.temp,
});

const mapDispatchToProps = dispatch => ({
    apiRequest: bindActionCreators(apiRequest, dispatch),
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(MyZiktoCash),
);
