import React from 'react';
import { Route } from 'react-router-dom';
import Container from 'container';
// import Protected from 'pages/Protected';
import MyTravelPoint from 'pages/MyPage/TravelPoint';
// import TravelPointAIGSave from './TravelPointSave/AIG';
// import TravelPointMemberSave from './TravelPointSave/Member';
// import FlightDelay from './FlightDelay';
// import AboutFlightDelay from './FlightDelay/About';
// import FlightDelayRegister from './FlightDelayRegister';
// import FlightDelayRegister2 from './FlightDelayRegister/Step2';

const Product = ({ match }) => {
    return (
        <div>
            <Route
                exact
                path={`${match.url}`}
                component={Container({
                    Component: MyTravelPoint,
                    isProtected: true,
                    title: '트래블포인트',
                    theme: 'dark-light',
                    className: 'my-page my-zikto',
                })}
            />
            {/* <Route
                exact
                path={`${match.url}/save`}
                component={TravelPointAIGSave}
            /> */}
            {/* <Route
                exact
                path={`${match.url}/member-save`}
                component={Protected(TravelPointMemberSave)}
            /> */}
            {/* <Route
                path={`${match.url}/about-flight-delay`}
                component={AboutFlightDelay}
            />
            <Route path={`${match.url}/flight-delay`} component={FlightDelay} />
            <Route
                path={`${match.url}/flight-delay-register`}
                component={FlightDelayRegister}
            />
            <Route
                path={`${match.url}/flight-delay-register2`}
                component={FlightDelayRegister2}
            /> */}
        </div>
    );
};

export default Product;
