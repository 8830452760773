import React from 'react';
import { Switch, Route } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { history } from 'redux/store';
import Container from 'container';
// constants
import { SENTRY_DSN } from 'constants/paths';
// sub component
import Home from 'pages/Home';
import About from 'pages/About';
import Login from 'pages/Account/Login';
import TravelPointCheck from 'pages/Account/TravelPointCheck';
import MobilePhoneLogin from 'pages/Account/MobilePhoneLogin';
import ResetPassword from 'pages/Account/Reset';
import NewPassword from 'pages/Account/NewPassword';
import Signup from 'pages/Account/Signup';
import MyPage from 'pages/MyPage';

import MyZiktoCash from 'pages/MyPage/ZitoCash';
import TravelPoint from 'pages/TravelPoint';
import Purchase from 'pages/Purchase';
import Personal from 'pages/Personal';
import Business from 'pages/Business';
import NotFound from 'pages/NotFound';
import IE from 'pages/Ie';
import Promy from 'pages/Promy';
import TravelPointSave from 'pages/TravelPoint/TravelPointSave/AIG';

if (process.env.REACT_APP_ENV !== 'test') {
    Sentry.init({
        dsn: SENTRY_DSN,
        environment: process.env.REACT_APP_ENV,
    });
}

function App() {
    return (
        <Switch history={history}>
            <Route exact path="/" component={Home} />
            <Route exact path="/about" component={About} />
            <Route
                path="/login"
                component={Container({
                    Component: Login,
                    title: '로그인',
                    theme: 'light',
                    className: 'account login container-rel',
                })}
            />
            <Route
                path="/mobile-phone-login"
                component={Container({
                    Component: MobilePhoneLogin,
                    title: '로그인',
                    theme: 'light',
                    className: 'account login',
                })}
            />
            <Route
                path="/my-travel-point"
                component={Container({
                    Component: TravelPointCheck,
                    title: '트래블포인트 확인',
                    theme: 'light',
                    className: 'account login',
                })}
            />
            <Route path="/password-reset" component={ResetPassword} />
            <Route path="/new-password" component={NewPassword} />
            <Route
                path="/signup"
                component={Container({
                    Component: Signup,
                    title: '간편 회원가입',
                    theme: 'light',
                    className: 'account signup container-rel',
                })}
            />
            <Route
                path="/my-zikto-cash"
                component={Container({
                    Component: MyZiktoCash,
                    isProtected: true,
                    title: '직토캐시',
                    theme: 'dark-light',
                    className: 'my-page my-zikto',
                })}
            />
            <Route path="/purchase" component={Purchase} />
            <Route path="/my-page" component={MyPage} />
            <Route path="/about" component={About} />
            <Route path="/personal-product" component={Personal} />
            <Route path="/business-product" component={Business} />
            <Route path="/travel-point/save" component={TravelPointSave} />
            <Route path="/travel-point" component={TravelPoint} />
            <Route path="/promy" component={Promy} />
            <Route path="/ie" component={IE} />
            <Route path="*" component={NotFound} />
        </Switch>
    );
}

export default App;
