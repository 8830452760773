// 개인 인증번호 전송
export const PHONE_AUTH = 'PHONE_AUTH';
export const PHONE_AUTH_URL = '/api/phone/auth/request';
// 개인 인증번호 확인
export const PHONE_AUTH_CONFIRM = 'HONE_AUTH_CONFIRM';
export const PHONE_AUTH_CONFIRM_URL = '/api/phone/auth/confirm';
// 회원가입
export const SIGN_UP = 'SIGN_UP';
export const SIGN_UP_URL = '/api/balance/webjoin';
// 회원정보 가져오기: 회원정보 설정화면
export const USER_INFO = 'USER_INFO';
export const USER_INFO_URL = '/api/balance/authorized/userInfo';
// 회원정보 가져오기
// export const USER_INFO = 'USER_INFO';
// export const USER_INFO_URL = '/api/balance/userInfo';
// 멤버쉽 레벨 가져오기
export const MEMBER_LEVEL = 'MEMBER_LEVEL';
export const MEMBER_LEVEL_URL = '/api/balance/membershipLevel';
// 회원정보 설정
export const USER_INFO_SET = 'USER_INFO_SET';
export const USER_INFO_SET_URL = '/api/balance/update';
// 휴대폰 인증번호 받기
export const FETCH_SMS_CODE = 'FETCH_SMS_CODE';
export const FETCH_SMS_CODE_URL = '/api/balance/smscode';
// 휴대폰 인증번호 확인
export const VERIFY_SMS_CODE = 'VERIFY_SMS_CODE';
export const VERIFY_SMS_CODE_URL = '/api/balance/verifysms';
// 휴대폰 번호 존재여부 확인
export const IS_EXIST_PHONE_NUMBER = 'IS_EXIST_PHONE_NUMBER';
export const IS_EXIST_PHONE_NUMBER_URL = '/api/balance/isExistPhoneNumber';
// 로그인
export const LOGIN = 'LOGIN';
export const LOGIN_URL = '/api/balance/login';
// 전화번호 로그인
export const EASY_LOGIN = 'EASY_LOGIN';
export const EASY_LOGIN_URL = '/api/balance/easyLogin';
// 전화번호로 회원가입
export const EASY_SIGNUP = 'EASY_SIGNUP';
export const EASY_SIGNUP_URL = '/api/balance/easySignUp';
// 보험료 간편계산
export const INSURANCE_CALC = 'INSURANCE_CALC';
export const INSURANCE_CALC_URL = '/api/insurance/product/travelAIG/quote';
// 해외여행보험 가입
export const INSURANCE_REGISTER = 'INSURANCE_REGISTER';
export const INSURANCE_REGISTER_URL =
    '/api/insurance/product/travelAIG/register';
// 해외여행보험 가입취소
export const INSURANCE_CANCEL = 'INSURANCE_CANCEL';
export const INSURANCE_CANCEL_URL = '/api/insurance/product/travelAIG/cancel';
// 나의 트래블포인트 확인: 멤버
export const USER_TRAVEL_POINT = 'USER_TRAVEL_POINT';
export const USER_TRAVEL_POINT_URL = '/api/travelPoint/user/balance';
// 나의 트래블포인트 확인: 비멤버
export const MY_TRAVEL_POINT = 'MY_TRAVEL_POINT';
export const MY_TRAVEL_POINT_URL = '/api/travelPoint/balance';
// 나의 직토캐시 확인
export const MY_ZIKTO_CASH = 'MY_ZIKTO_CASH';
export const MY_ZIKTO_CASH_URL = '/api/ziktocash/balance';
// 내 주문 확인
export const MY_ORDER = 'MY_ORDER';
export const MY_ORDER_URL = '/api/user/order';
// 내 주문 리스트 확인
export const MY_ORDERS = 'MY_ORDERS';
export const MY_ORDERS_URL = '/api/user/order';
// 내 모든 보험
export const MY_ALL_INSURANCES = 'MY_ALL_INSURANCES';
export const MY_ALL_INSURANCES_URL = '/api/user/product';
// 비밀번호 리셋 메일 발송
export const PASSWORD_RESET_REQUEST = 'PASSWORD_RESET_REQUEST';
export const PASSWORD_RESET_REQUEST_URL = '/api/password/reset';
// 비밀번호 재설정
export const PASSWORD_RESET_CONFIRM = 'PASSWORD_RESET_CONFIRM';
export const PASSWORD_RESET_CONFIRM_URL = '/api/password';
// 이메일 중복확인
export const EMAIL_CHECK = 'EMAIL_CHECK';
export const EMAIL_CHECK_URL = '/api/balance/isExistEmail';
// 트래블포인트 적립: 로그인한 사용자
export const TRAVEL_POINT_MEMBER_SAVE = 'TRAVEL_POINT_MEMBER_SAVE';
export const TRAVEL_POINT_MEMBER_SAVE_URL = '/api/travelPoint/member/saveup';
// 트래블포인트 적립: AIG 코드 사용자. 비로그인 사용자.
export const TRAVEL_POINT_SAVE = 'TRAVEL_POINT_SAVE';
export const TRAVEL_POINT_SAVE_URL = '/api/travelPoint/saveup';
// 트래블포인트 이용내역
export const TRAVEL_POINT_HISTORY = 'TRAVEL_POINT_HISTORY';
export const TRAVEL_POINT_HISTORY_URL = '/api/travelPoint/transaction';
// 나의 직토캐시 이용내역
export const MY_ZIKTO_CASH_HISTORY = 'MY_ZIKTO_CASH_HISTORY';
export const MY_ZIKTO_CASH_HISTORY_URL = '/api/ziktocash/transaction';
// 서비스 이용 약관 파일 URL
export const ZIKTO_TERMS_PDF_URL =
    'https://s3.ap-northeast-2.amazonaws.com/insureum/Zikto_service_access_terms.pdf';
// 상품소개 URL
export const PRODUCT_GUIDE_PDF_URL =
    'https://s3.ap-northeast-2.amazonaws.com/insureum/product_guide.pdf';
// AIG약관 URL
export const AIG_TERMS_PDF_URL =
    'https://s3.ap-northeast-2.amazonaws.com/insureum/AIG_clause.pdf';
// SENTRY dsn
export const SENTRY_DSN =
    'https://60a855fb30204eab9112ebc6f9a5fdf8@sentry.io/1461680';
