import axios from 'axios';
import { DEFAULT_TIMEOUT } from 'constants/timeout';

axios.defaults.baseURL = process.env.REACT_APP_API_URL || '';
axios.defaults.timeout = DEFAULT_TIMEOUT;
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
axios.defaults.headers.post['Content-Type'] = 'application/json';

axios.interceptors.request.use(
    requestConfig => requestConfig,
    requestError => {
        return Promise.reject(requestError);
    },
);
const defaultUncaughtedError = {
    code: 530,
    message: '죄송합니다. 서버에 오류가 있습니다.',
};
axios.interceptors.response.use(
    response => response,
    error => {
        error.message =
            error.response && error.response.data
                ? error.response.data.message
                : defaultUncaughtedError.message;
        error.code =
            error.response && error.response.data
                ? error.response.data.status || error.response.data.code
                : defaultUncaughtedError.code;

        return Promise.reject(error);
    },
);

export default {
    // Provide request methods with the default base_url
    get(url, data = {}, conf = {}) {
        return axios
            .get(url, { ...axios.defaults, ...conf })
            .then(response => Promise.resolve(response.data));
    },

    post(url, data = {}, conf = {}) {
        return axios
            .post(url, data, { ...axios.defaults, ...conf })
            .then(response => Promise.resolve(response.data));
    },
};
