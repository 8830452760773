import React, { PureComponent } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
// constants
import {
    USER_INFO,
    USER_INFO_URL,
    USER_TRAVEL_POINT,
    USER_TRAVEL_POINT_URL,
    MY_ZIKTO_CASH,
    MY_ZIKTO_CASH_URL,
    MY_ORDERS,
    MY_ORDERS_URL,
} from 'constants/paths';
import { IS_MOBILE_DEVICE } from 'constants/device';
// redux
import authActions from 'redux/actions/auth';
import { apiRequest } from 'redux/actions/api/utils';
import tempActions from 'redux/actions/temp';
// utils
import { formatToLocalString } from 'utils/format';
import { errorHandler } from 'utils/errorHandler';
// sub components
import DisplayTravelPoint from 'pages/Purchase/DisplayTravelPoint';
import DisplayZiktoCash from 'pages/Purchase/DisplayZiktoCash';
class MyPageInfo extends PureComponent {
    constructor() {
        super();

        this.state = {
            isLoaded: false,
        };
    }

    componentDidMount() {
        this._mounted = true;
        this.myOrders = [];

        Promise.all([
            this.getMyInfo(),
            this.getMyTravelPoint(),
            this.getMyZiktoCash(),
            this.fetchMyInsurances(),
        ])
            .then(() => {
                this._mounted &&
                    this.setState({
                        isLoaded: true,
                    });
            })
            .catch(e => {});
    }

    componentWillUnmount() {
        this._mounted = false;
    }
    fetchMyInsurances = async () => {
        if (!this._mounted) return;
        try {
            await this.props
                .apiRequest({
                    type: MY_ORDERS,
                    path: MY_ORDERS_URL,
                    method: 'get',
                })
                .then(response => {
                    if (!response) return;
                    if (response.code && response.code !== 200)
                        return Promise.reject(new Error(response.message));
                    this.myOrders = response.order;
                })
                .catch(e => {
                    return Promise.reject(e);
                });
        } catch (e) {
            errorHandler(e, MY_ORDERS_URL, this.props.tempData.userId);
        }
    };

    getMyTravelPoint = async () => {
        if (!this._mounted) return;
        try {
            await this.props
                .apiRequest({
                    type: MY_ZIKTO_CASH,
                    path: MY_ZIKTO_CASH_URL,
                    method: 'get',
                })
                .then(response => {
                    this._mounted &&
                        this.setState({
                            ziktoCash: Number(
                                response.code === 200 ? response.balance : 0,
                            ),
                        });
                })
                .catch(e => {
                    return Promise.reject(e);
                });
        } catch (e) {
            errorHandler(e, MY_ZIKTO_CASH_URL, this.props.tempData.userId);
        }
    };

    getMyZiktoCash = async () => {
        if (!this._mounted) return;
        try {
            await this.props
                .apiRequest({
                    type: USER_TRAVEL_POINT,
                    path: USER_TRAVEL_POINT_URL,
                    method: 'get',
                })
                .then(response => {
                    this._mounted &&
                        this.setState({
                            travelPoint: Number(
                                response.code === 200 ? response.balance : 0,
                            ),
                        });
                })
                .catch(e => {
                    return Promise.reject(e);
                });
        } catch (e) {
            errorHandler(e, USER_TRAVEL_POINT_URL, this.props.tempData.userId);
        }
    };

    getMyInfo = async () => {
        if (!this._mounted) return;
        try {
            await this.props
                .apiRequest({
                    type: USER_INFO,
                    path: USER_INFO_URL,
                })
                .then(response => {
                    if (!response) return;

                    this._mounted &&
                        this.setState({
                            username: response.realName,
                            email: response.email,
                            ssn: response.birth.replace(/-/g, '').slice(2),
                            gender: response.gender,
                            phoneNumber: response.phoneNumber,
                        });
                    this.props.authUpdate(response.email ? 2 : 1);
                    this.props.saveTempData({ userId: response.id });
                })
                .catch(e => {
                    return Promise.reject(e);
                });
        } catch (e) {
            errorHandler(e, USER_INFO_URL, this.props.tempData.userId);
        }
    };

    oncloseErrorModal = () => {};

    handleLogout = () => {
        this.props.logout();
    };

    handleSelectInsurance = paramObj => {
        return () => {
            this.props.history.push(`/my-page/insurances/${paramObj.id}`);
        };
    };

    render() {
        const { isLoaded } = this.state;

        return (
            <div>
                {!isLoaded && <div className="loader" />}
                {isLoaded && (
                    <div style={{ width: '100%' }}>
                        <section
                            className="section section1"
                            style={{ marginBottom: 30 }}
                        >
                            <div
                                className="content"
                                style={{ position: 'relative' }}
                            >
                                <div
                                    className="user-name"
                                    style={{ marginBottom: 20 }}
                                >
                                    <p>안녕하세요.</p>
                                    <b>{this.state.username}</b> 님
                                    {this.state.email && (
                                        <span>({this.state.email})</span>
                                    )}
                                </div>
                                <div className="row gutter">
                                    {/* <Link
                                        to={'/my-page/settings'}
                                        className="col"
                                        style={{ flex: 'none' }}
                                    >
                                        <button
                                            type="submit"
                                            className="btn block outline"
                                        >
                                            <b>회원 정보 수정</b>
                                        </button>
                                    </Link> */}
                                    <button
                                        type="button"
                                        className="btn block outline"
                                        onClick={this.handleLogout}
                                    >
                                        <b>로그아웃</b>
                                    </button>
                                </div>
                            </div>
                        </section>

                        <Link to="/travel-point" className="link-point-cash">
                            <DisplayTravelPoint
                                username={this.state.username}
                                point={this.state.travelPoint}
                                hasLink={true}
                            />
                            <i className="icon fal fa-chevron-right" />
                        </Link>
                        <Link to="/my-zikto-cash" className="link-point-cash">
                            <DisplayZiktoCash
                                username={this.state.username}
                                cash={this.state.ziktoCash}
                                style={{
                                    marginTop: 5,
                                    marginBottom: 30,
                                }}
                                hasLink={true}
                            />
                            <i className="icon fal fa-chevron-right" />
                        </Link>

                        {this.myOrders.length > 0 && (
                            <section className="section section3">
                                <div className="content">
                                    <div
                                        className="flex h3 align-content-center"
                                        style={{ marginBottom: 20 }}
                                    >
                                        <h3 style={{ fontSize: 20 }}>
                                            나의 계약내역
                                        </h3>{' '}
                                        (총{this.myOrders.length}건)
                                    </div>

                                    <div
                                        className="orders-wrap"
                                        style={{
                                            maxHeight: IS_MOBILE_DEVICE
                                                ? 'none'
                                                : window.innerHeight / 2.5,
                                        }}
                                    >
                                        {this.myOrders.map((ins, i) => (
                                            <div
                                                className="card flex-column"
                                                key={`products-${i}`}
                                                onClick={this.handleSelectInsurance(
                                                    ins,
                                                )}
                                            >
                                                <div className="flex row mb-10">
                                                    <div
                                                        className="col"
                                                        style={{
                                                            flex: 0.5,
                                                        }}
                                                    >
                                                        <strong>
                                                            해외여행보험
                                                        </strong>
                                                    </div>
                                                    <div className="col text-right">
                                                        <div>
                                                            {moment(
                                                                ins.createdAt,
                                                            ).format(
                                                                'YYYY-MM-DD',
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <span className="sm">
                                                            {ins.title}
                                                        </span>
                                                    </div>
                                                    <div className="col text-right">
                                                        [{ins.paymentStatus}]{' '}
                                                        {formatToLocalString(
                                                            ins.krwPaid,
                                                        )}
                                                        원
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    {/* <div
                                        className="btn block"
                                        style={{
                                            textAlign: 'center',
                                            borderBottom:
                                                '1px solid #9c9c9c',
                                            paddingBottom: 20,
                                            paddingTop: 10,
                                            marginBottom: 30,
                                            fontSize: 20,
                                        }}
                                    >
                                        <i className="fal fa-chevron-down" />
                                    </div> */}
                                </div>
                            </section>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    tempData: state.temp,
});

const mapDispatchToProps = dispatch => ({
    logout: bindActionCreators(authActions.logout, dispatch),
    apiRequest: bindActionCreators(apiRequest, dispatch),
    authUpdate: bindActionCreators(authActions.update, dispatch),
    saveTempData: bindActionCreators(tempActions.saveTempData, dispatch),
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(MyPageInfo),
);
