import React from 'react';
import moment from 'moment';

const History = props => {
    const { data } = props;
    return (
        <div>
            {data.map((el, i) => (
                <div className="item" key={`history-${i}`}>
                    <div className="flex">
                        <div className="flex-1">
                            <div>
                                <b>{el.title}</b>
                            </div>
                            <div>
                                <span>
                                    {moment(el.createdAt).format(
                                        'YYYY-MM-DD hh:mm',
                                    )}
                                </span>
                            </div>
                        </div>
                        <div className="item-r">
                            <div>
                                <b>{el.amount} Point</b>
                            </div>
                            <div>
                                <span>{el.status}</span>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default History;
