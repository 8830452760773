import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';
import Formsy from 'formsy-react';
// sub components
import Modal from 'components/Modal';
// import ErrorModal from 'components/ErrorModal';
import PhoneVerification from 'components/PhoneVerification/Number';
import DisplayTravelPoint from 'pages/Purchase/DisplayTravelPoint';

class TravelPointLogin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isPristine: true,
            user: '',
            // modal
            isWelcomeModalOpen: false,
            travelPoint: 0,
        };

        this.formRef = React.createRef();
    }

    componentWillMount() {
        if (this.props.auth.level) this.props.history.replace('/my-page');
    }

    handleFormChange = (currentValues, isChanged) => {
        if (!isChanged) return;
        this.setState({
            isPristine: false,
        });
    };

    moveToHome = () => {
        this.props.history.replace('/');
    };

    handleSuccessVerify = () => {
        if (this.props.auth.level) {
            return this.props.history.replace('/my-page');
        }

        this.setState({
            isNotRegisterdOpen: true,
        });
    };

    renderWelcomeModal = () => {
        return (
            <div>
                <div className="header">{this.state.user}님, 안녕하세요!</div>
                <div className="contents">
                    <DisplayTravelPoint
                        username={this.state.user}
                        point={this.state.travelPoint}
                    />
                </div>
            </div>
        );
    };

    renderNotRegisterdModal() {
        return (
            <div>
                <div className="header">아직 회원이 아니신가요?</div>
                <div className="contents">
                    등록된 회원정보가 없습니다.
                    <br />
                    직토 보험대리점의 서비스를 이용하시려면
                    <br />
                    회원가입을 진행해주세요.
                </div>
            </div>
        );
    }

    render() {
        const { isPristine } = this.state;

        return (
            <div style={{ margin: '0 auto' }}>
                <Formsy
                    onChange={this.handleFormChange}
                    className={`form login-form ${
                        isPristine ? 'is-pristine' : ''
                    }`}
                >
                    <h2 className="h2">직토에 오신 것을 환영합니다.</h2>
                    <div style={{ marginBottom: 30 }}>
                        휴대폰 번호 인증이 필요합니다.
                    </div>
                    <PhoneVerification
                        onSuccess={this.handleSuccessVerify}
                        onConfirm={this.handleConfirm}
                    />
                </Formsy>
                {this.state.isWelcomeModalOpen && (
                    <Modal
                        content={this.renderWelcomeModal()}
                        onDismiss={this.moveToHome}
                        // style={{ width: 500, height: 450 }}
                    />
                )}

                {this.state.isNotRegisterdOpen && (
                    <Modal
                        content={this.renderNotRegisterdModal()}
                        // style={{ width: 500, height: 269 }}
                        onDismiss={() => {
                            this.props.history.replace('/signup');
                        }}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    api: state.api,
});

export default withRouter(connect(mapStateToProps)(TravelPointLogin));
